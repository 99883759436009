import { convertStrToDate } from "./convertStrToDate";

const sortOption = (correctData, filterSortType) => {
  if (correctData?.length) {
    // console.log(typeSelect, 'insideSortMethod')
    switch (filterSortType.name) {
      case "recomended":
        return correctData.sort((a, b) => {
          return b.recomended - a.recomended;
        });
      case "newest":
        return correctData.sort((a, b) => {
          const aEnd = convertStrToDate(a.startDuringTime);
          const bEnd = convertStrToDate(b.startDuringTime);
          return bEnd - aEnd;
        });
      case "oldest":
        return correctData.sort((a, b) => {
          const aEnd = convertStrToDate(a.startDuringTime);
          const bEnd = convertStrToDate(b.startDuringTime);
          return bEnd < aEnd ? 1 : -1;
        });

      case "alphabet1":
        return correctData.sort((a, b) => {
          const nameA = a.name;
          const nameB = b.name;
          return nameA < nameB ? -1 : 1;
        });
      case "alphabet2":
        return correctData.sort((a, b) => {
          const nameA = a.name;
          const nameB = b.name;
          return nameB > nameA ? 1 : -1;
        });
      default:
        return correctData;
    }
  }
};
export default sortOption;
