import styled from "styled-components";
import { motion } from "framer-motion";
import { Theme } from "../../store/theme/theme";
export const singleItemModalVariants = {
  hidden: {
    opacity: 0,
  },
  visible: () => ({
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.6,
    },
  }),
  exit: {
    opacity: 0,
  },
};
export const modalWraperVariants = {
  hidden: {
    opacity: 0,
  },
  visible: (index) => ({
    opacity: 1,
    transition: {
      duration: 0.3,
      delay: 0.3,
    },
  }),
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
      delay: 0.3,
    },
  },
};
export const WrapperForSingleOffer = styled(motion.div)`
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  // filter: blur(150px);
  z-index: 1001;
  overflow-x: hidden;
  overflow-y: auto;
  user-select: none;
  font-family: montserrat, non-serif;
  &::-webkit-scrollbar {
    width: 12px;
    border-radius: 0 5px 5px 0;
    background-color: ${({ isDark }) =>
      !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border-left: 3px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    border-right: 3px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    background-color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  @media${Theme.device.mobileL} {
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 0 2px 2px 0;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
      border-left: none;
      border-right: none;
    }
  }
`;

export const ForCloseIcon = styled(motion.div)`
  position: fixed;
  display: block;
  top: 20px;
  right: 20px;
  color: ${({ isDark }) =>
    !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  cursor: pointer;
  z-index: 3;
`;
export const SingleItemContent = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 800px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-transform: uppercase;
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  background: ${({ isDark }) =>
    isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  box-shadow: 0px 7px 29px 0px
    ${({ isDark }) =>
      isDark ? Theme.dark.eightColor.dark : Theme.light.eightColor.ligthGray};
  border-radius: 5px;
  font-family: montserrat, non-serif;
  & > h2 {
    margin: 20px;
  }
  @media${Theme.device.tablet} {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
  @media${Theme.device.mobileL} {
    width: 400px;
    // height: 140vh;
    & > h2 {
      width: calc(50% - 20px);
      text-align: center;
    }
  }
`;
export const ImageForSingle = styled.div`
  width: 100%;
  max-width: 450px;
  height: 300px;
  max-height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 10px;
  overflow: hidden;
  & > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
  }
  @media${Theme.device.mobileL} {
    max-height: 200px;
    & > svg {
      width: 80% !important;
    }
  }
`;
export const WraperFor2Items = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
export const BrandForSingle = styled.div`
  width: 70%;
  height: ${({ optionsBrandActive, brandsLength }) => {
    switch ((optionsBrandActive, brandsLength)) {
      case optionsBrandActive && brandsLength > 1:
        return "600px";
      case optionsBrandActive && !brandsLength > 1:
        return "300px";
      case !optionsBrandActive:
        return "135px";
    }
  }};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin: 10px;
  padding: 10px;
  transition: 0.5s;
  background-image: linear-gradient(
    315deg,
    ${({ isDark }) => (isDark ? "#3e3846" : "#eeebf1")} 0%,
    ${({ isDark }) =>
        isDark ? Theme.dark.primaryColor.dark : Theme.light.fiveColor.white}
      74%
  );
  border-radius: 5px;
  position: relative;
  & > section {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    min-height: 135px;
  }
  & > section > div {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  & > section > div > svg {
    display: block;
    width: 30px;
    padding: 5px;
    transition: 0.3s transform;
    transform-origin: center;
  }
  & section > img {
    width: 100px;
    height: 100px;
  }
  & > section > ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    // z-index: 93;
  }
  & section > ul > li {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50px;
    width: ${({ brandsLength }) => (brandsLength > 1 ? "33%" : "50%")};
    box-sizing: border-box;
    padding: 10px 15px;
    text-transform: uppercase;
    font-size: 0.9rem;
    z-index: 94;
    cursor: pointer;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    border: 1px solid
      ${({ isDark }) =>
        isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
    transition: 0.3s;
  }
  & > section > ul > li:hover {
    border: 1px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  }

  @media${Theme.device.tablet} {
    height: ${({ optionsBrandActive }) =>
      optionsBrandActive ? "800px" : "135px"};
    & > section > ul > li {
      width: 50%;
    }
  }
  @media${Theme.device.mobileL} {
    width: 80%;
    height: ${({ optionsBrandActive }) =>
      optionsBrandActive ? "800px" : "135px"};
    & > section > img {
      width: 60px;
      height: 60px;
    }
    & > section > ul > li {
      width: 50%;
    }
  }
`;
export const TimeForSingle = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px;
  padding: 10px;
  background-image: linear-gradient(
    315deg,
    ${({ isDark }) => (isDark ? "#3e3846" : "#eeebf1")} 0%,
    ${({ isDark }) =>
        isDark ? Theme.dark.primaryColor.dark : Theme.light.fiveColor.white}
      74%
  );
  border-radius: 5px;
  & > div {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > * {
      margin: 5px;
    }
  }
  @media${Theme.device.mobileL} {
    width: 80%;
    & > img {
      width: 60px;
      height: 60px;
    }
  }
`;
export const CategoryForSingle = styled.div`
  width: 70%;
  height: ${({ optionsCategoryActive }) =>
    optionsCategoryActive ? "600px" : "135px"};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin: 10px;
  padding: 10px;
  transition: 0.5s;
  background-image: linear-gradient(
    315deg,
    ${({ isDark }) => (isDark ? "#3e3846" : "#eeebf1")} 0%,
    ${({ isDark }) =>
        isDark ? Theme.dark.primaryColor.dark : Theme.light.fiveColor.white}
      74%
  );
  border-radius: 5px;
  position: relative;
  & > section {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    min-height: 135px;
  }
  & > section > img {
    width: 100px;
    height: 100px;
  }
  & > section > * {
    margin: 5px;
  }
  & > section > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  & > section > p {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  & > section > p > svg {
    display: block;
    width: 30px;
    padding: 5px;
    transition: 0.3s transform;
    transform-origin: center;
  }
  & > section > ul {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    // z-index: 93;
  }
  & section > ul > li {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 50px;
    width: 33%;
    box-sizing: border-box;
    padding: 10px 15px;
    text-transform: uppercase;
    font-size: 0.9rem;
    z-index: 94;
    cursor: pointer;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    border: 1px solid
      ${({ isDark }) =>
        isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
    transition: 0.3s;
  }
  & > section > ul > li:hover {
    border: 1px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  }
  @media${Theme.device.tablet} {
    height: ${({ optionsCategoryActive }) =>
      optionsCategoryActive ? "850px" : "135px"};
    & > section > ul > li {
      width: 50%;
    }
  }
  @media${Theme.device.mobileL} {
    width: 80%;
    height: ${({ optionsCategoryActive }) =>
      optionsCategoryActive ? "920px" : "135px"};
    & > section > img {
      width: 60px;
      height: 60px;
    }
    & > section > ul > li {
      width: 50%;
    }
  }
  //   & > p {
  //     text-transform: uppercase;
  //   }
`;
export const DataForSingle = styled(motion.div)`
  width: 70%;
  height: ${({ optionsActive }) => (optionsActive ? "450px" : "225px")};
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 10px;
  padding: 10px;
  background-image: linear-gradient(
    315deg,
    ${({ isDark }) => (isDark ? "#3e3846" : "#eeebf1")} 0%,
    ${({ isDark }) =>
        isDark ? Theme.dark.primaryColor.dark : Theme.light.fiveColor.white}
      74%
  );
  border-radius: 5px;
  position: relative;
  transition: 0.3s;
  & > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > div > section {
    width: 40%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin: 10px;
    padding: 10px;
  }
  & > div > section:first-of-type {
    background: ${({ isDark }) =>
      isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
    box-shadow: 0px 7px 29px 0px
      ${({ isDark }) =>
        isDark ? Theme.dark.eightColor.dark : Theme.light.eightColor.ligthGray};
    margin-top: 25px;
    border-radius: 15px;
    width: 55%;
  }
  & > p {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  & > p > svg {
    display: block;
    width: 30px;
    padding: 5px;
    transition: 0.3s transform;
    transform-origin: center;
  }

  & > div > section > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > * {
      margin: 10px;
    }
  }
  & > div > section:first-of-type > div {
    justify-content: flex-start;
  }
  & > div > section:last-of-type > div {
    background-color: ${Theme.light.secondaryColor.green};
  }
  & > div > section:last-of-type > div > p:first-of-type {
    width: 95%;
  }
  & > div > section > div > p {
    width: 50%;
  }
  & > div > section > div:last-of-type {
    cursor: ${({ link }) => (link ? "pointer" : "normal")};
  }
  & > div > section > div > p:last-of-type {
    // text-transform: lowercase;
  }
  & > div > section > div:last-of-type > p:last-of-type {
    // text-transform: lowercase;
  }
  & > div > section > div > p:first-of-type {
    text-align: right;
    width: 18%;
    font-size: 12px;
  }
  & > div > section > div > p:last-of-type {
    text-align: left;
  }
  @media${Theme.device.mobileL} {
    width: 80%;
    height: auto;
    & > div > section {
      width: 45%;
      margin: 0;
    }
    & > div > section:first-of-type {
      width: 55%;
    }
    & > div > section > div {
      flex-direction: column;
      margin: 5px;
    }
    & > div > section > div > p {
      width: 100%;
    }
    & > div > section > div > p:first-of-type {
      width: 100%;
      font-size: 12px;
      text-align: left;
      margin: 0;
    }
    & > div > section > div > p:last-of-type {
      margin-top: 2px;
      margin-bottom: 2px;
    }
  }
`;
export const ChangeButton = styled(motion.div)`
  width: 120px;
  height: 40px;
  border-radius: 5px;
  line-height: 40px;
  font-size: 1em;
  background: ${Theme.light.secondaryColor.green};
  color: ${Theme.light.primaryColor.white};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 30px;
  &:hover {
    box-shadow: 2px 2px 7px black;
  }
  & > a {
    text-decoration: none;
    color: ${Theme.light.primaryColor.white};
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  & > p {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > svg {
    padding-right: 16px;
  }
  @media${Theme.device.mobileL} {
    margin-bottom: 55px;
    & > svg {
      padding: 0;
    }
    // & > p,
    // & > a > p {
    //   display: none;
    // }
  }
`;
export const InputModalItems = styled(motion.div).attrs((props) => ({
  style: {
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
  },
}))`
  width: 100%;
  // height:20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 15px;
  & > div {
    width: 45%;
    margin-top: 5px;
  }
  & > div > p {
    margin-left: 5px;
    font-size: 12px;
  }
  & > div > textarea,
  & > div > input,
  & > div > select {
    display: block;
    width: 95%;
    font-size: 1em;
    padding: 5px;
    margin: 2px;
    border: none;
    box-shadow: 0px 7px 29px 0px
      ${({ isDark }) =>
        isDark ? Theme.dark.eightColor.dark : Theme.light.eightColor.ligthGray};
    border-radius: 12.5px;
    transition: background 1s;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    background: ${({ isDark }) =>
      isDark ? Theme.dark.fiveColor.white : Theme.light.primaryColor.white};
    padding-left: 10px !important;
  }
  & > div > textarea {
    max-height: 80px;
    height: 80px;
    max-width: 240px;
  }
  // & > div:nth-last-child(2) {
  //   width: 100%;
  //   & > input {
  //     width: 95%;
  //   }
  // }

  // & > div:last-child {
  //   width: 100%;
  //   & > input {
  //     width: 95%;
  //   }
  // }
  & > div > textarea::placeholder,
  & > div > input::placeholder,
  & > div > select::placeholder {
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  & > div > input:focus,
  & > div > textarea:focus {
    outline: none;
  }
  & > div > select:focus {
    outline: none;
    // color:${({ isDark }) =>
      !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    color: black;
  }
  & > div > textarea:hover,
  & > div > input:hover,
  & > div > select:hover {
    box-shadow: 2px 2px 7px black;
  }
  & > p {
    color: ${Theme.light.fourthColor.red};
    padding-top: 5px;
    font-size: 14px;
  }
  @media${Theme.device.mobileL} {
    & > div > textarea {
      max-width: 140px;
    }
  }
`;
export const SvgWraper = styled.div`
  width: 150px;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 150px;
    height: 90px;
  }
  & > div > p {
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%) !important;
    font-size: 20px;
  }
  & > div > p > p > span {
    font-size: 12px;
  }
  & > div > p > p:nth-child(even) {
    font-weight: bold;
  }
  & > div > p > p:nth-child(odd) {
    font-size: 12px;
  }
`;

export const imgSuspenseStyleDark = {
  width: "999%",
  color: `${Theme.light.fiveColor.white}`,
  padding: "5px",
};
export const imgSuspenseStyleLight = {
  width: "999%",
  color: ` ${Theme.dark.sixColor.dark}`,
  padding: "5px",
};
export const imgSuspenseStyleForBrandDark = {
  width: "20%",
  color: `${Theme.light.fiveColor.white}`,
  padding: "5px",
};
export const imgSuspenseStyleForBrandLight = {
  width: "20%",
  color: ` ${Theme.dark.sixColor.dark}`,
  padding: "5px",
};
export const styleForActiveLigth = {
  color: `${Theme.dark.sixColor.dark}`,
  transform: "rotate(180deg)",
};
export const styleForActiveDark = {
  color: `${Theme.light.fiveColor.white}`,
  transform: "rotate(180deg)",
};
export const styleForDisactiveLight = {
  color: `${Theme.dark.sixColor.dark}`,
  transform: "rotate(0)",
};
export const styleForDisactiveDark = {
  color: `${Theme.light.fiveColor.white}`,
  transform: "rotate(0)",
};
export const styleForActionsSvg = {
  height: "20px",
  paddingRight: "10px",
};
export const animationInputVariants = {
  hidden: {
    opacity: 0,
    // scale:0,
    y: -20,
  },
  visible: {
    opacity: 1,
    // scale:1,
    y: 0,
    // transformOrigin:"bottom",
    transition: { duration: 0.5 },
  },
  exit: {
    opacity: 0,
    // scale:0,
    y: -20,
  },
};
