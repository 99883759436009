import React, { useState, useEffect, useRef } from "react";
import useFetchHook from "../../helpers/useFetchHook";

import {
  WrapperForSingleOffer,
  ForCloseIcon,
  SingleItemContent,
  modalWraperVariants,
  singleItemModalVariants,
  BrandForSingle,
  DataForSingle,
  WraperFor2Items,
  imgSuspenseStyleForBrandDark,
  imgSuspenseStyleForBrandLight,
  ChangeButton,
  styleForActiveLigth,
  styleForActiveDark,
  styleForDisactiveDark,
  styleForDisactiveLight,
  InputModalItems,
  animationInputVariants,
} from "./style";

import { AnimatePresence } from "framer-motion";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import { connect } from "react-redux";
import {
  overflowHidden,
  shopUpdateClose,
} from "../../store/theme/duck/actions/actions";
import { setShopToUpdate } from "../../store/select/duck/actions/actions";
import { Theme } from "../../store/theme/theme";
import { SelectBrand } from "./selectBrand/SelectBrand";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/default.css";
import { API_URL } from "../../constant/constant";
import imageRegular from "../../assets/media/svg/image-regular.svg";
import { save } from "../../assets/media/svgPath/svgPath";
import { SvgDisplay } from "../../tools/svgDisplay/SvgDisplay";

import {
  encryptFrontData,
  decryptFrontData,
} from "../../utils/Crypto/cryptoFront.controller";

export const styleForMoreActionsSvg = {
  height: "30px",
};
const divVariants = {
  hidden: {
    opacity: 0,
    scale: 1.2,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
  },
};
const closePath =
  "M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z";

const downPath =
  "M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z";

const errAlfanumeric = "dostępne znaki alfanumeryczne";
const errNumeric = "dostępne znaki numeryczne [0-9].[01-99]";
const errToManySigns = "dostępne 3-30 znaków";
const regNameMultiLng =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšśŚžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9\s:/?_,.'-]+$/u;

const forDecimals = /^\d*(\.)?(\,)?(\d{0,2})?$/;

const fetchForChangeListItem = (shopItem, setUpdatetShopId) => {
  // console.log(shopItem, "update");
  const frontDataToEncrypt = {
    shopItem,
  };
  // encrypt data for fetch
  const ciphertext = encryptFrontData(
    frontDataToEncrypt,
    null,
    null,
    "ModalForShops.js"
  );
  const updateEndPoint = "shops/shopUpdate";
  const addEndPoint = "shops/add";
  fetch(
    `${API_URL}/api-studio/${shopItem?._id ? updateEndPoint : addEndPoint}`,
    {
      method: "POST",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: ciphertext,
      }),
    }
  )
    .then((response) => response.json())
    .then((result) => {
      // decrypt data from response
      return decryptFrontData(result.data, null, null, "Modal.js");
    })
    .then((data) => {
      // console.log(data);
      if (!data.ok) {
        alertify.set("notifier", "position", "bottom-left");
        alertify.error(`Przepraszamy coś poszło nie po naszej
        myśli,${data.error}`);
      } else {
        setUpdatetShopId(data?.shopItem?._id);
        alertify.set("notifier", "position", "bottom-left");
        alertify.success(`Aktualizacja danych przeszła pomyślnie`);
      }
    })
    .catch((err) => console.log(err));
};

const _ModalForShops = ({
  theme,
  overflowHidden,
  select,
  user,
  setTopDealUpdatedID,
  add,
  shopUpdateClose,
  setShopToUpdate,
  setUpdatetShopId,
}) => {
  const chosenItem = select?.shopToUpdate;

  const [shopItem, setShopItem] = useState(null);
  const [optionsActive, setOptionsActive] = useState(true);
  const [optionsBrandActive, setBrandOptionsActive] = useState(false);
  const [wasChanged, setWasChanged] = useState(false);

  const { data, loading, errorFromCrypto } = useFetchHook({
    user,
    endPoint: "shops/getBrands",
    method: "POST",
    body: {},
  });
  // console.log({ chosenItem, shopItem, data, loading }, "chosenItem");
  const [err, setErr] = useState("");

  let refs = useRef();
  let refsHref = useRef();
  const isDark = theme?.isDark;

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.target.blur();
    }
  };
  const handleOnchange = (e) => {
    const name = e.target.name;

    if (name === "street") {
      const { value } = e.target;
      if (value?.length > 2 && !regNameMultiLng.test(value)) {
        setErr({
          err: errAlfanumeric,
        });
        return;
      }

      if (value?.length <= 100) {
        setShopItem({ ...shopItem, street: value });
        setErr({
          err: "",
        });
      } else {
        setErr({
          err: errToManySigns,
        });
      }
    }
    if (name === "city") {
      const { value } = e.target;
      if (value?.length > 2 && !regNameMultiLng.test(value)) {
        setErr({
          err: errAlfanumeric,
        });
        return;
      }

      if (value?.length <= 100) {
        setShopItem({ ...shopItem, city: value });
        setErr({
          err: "",
        });
      } else {
        setErr({
          err: errToManySigns,
        });
      }
    }
    if (name === "number") {
      const { value } = e.target;
      if (value?.length > 0 && !regNameMultiLng.test(value)) {
        setErr({
          err: errAlfanumeric,
        });
        return;
      }

      if (value?.length <= 100) {
        setShopItem({ ...shopItem, number: value });
        setErr({
          err: "",
        });
      } else {
        setErr({
          err: errToManySigns,
        });
      }
    }
    if (name === "postCode") {
      const { value } = e.target;
      if (value?.length > 2 && !regNameMultiLng.test(value)) {
        setErr({
          err: errAlfanumeric,
        });
        return;
      }

      if (value?.length <= 100) {
        setShopItem({ ...shopItem, postCode: value });
        setErr({
          err: "",
        });
      } else {
        setErr({
          err: errToManySigns,
        });
      }
    }
    if (name === "latitiude") {
      const { value } = e.target;
      if (value?.length > 2 && !regNameMultiLng.test(value)) {
        setErr({
          err: errAlfanumeric,
        });
        return;
      }

      if (value?.length <= 100) {
        setShopItem({ ...shopItem, position: [value, shopItem.position[1]] });
        setErr({
          err: "",
        });
      } else {
        setErr({
          err: errToManySigns,
        });
      }
    }
    if (name === "longitiude") {
      const { value } = e.target;
      if (value?.length > 2 && !regNameMultiLng.test(value)) {
        setErr({
          err: errAlfanumeric,
        });
        return;
      }

      if (value?.length <= 100) {
        setShopItem({ ...shopItem, position: [shopItem.position[0], value] });
        setErr({
          err: "",
        });
      } else {
        setErr({
          err: errToManySigns,
        });
      }
    }
    if (name === "openingHours") {
      const { value } = e.target;
      if (value?.length > 2 && !regNameMultiLng.test(value)) {
        setErr({
          err: errAlfanumeric,
        });
        return;
      }

      if (value?.length <= 100) {
        setShopItem({ ...shopItem, openingHours: [value] });
        setErr({
          err: "",
        });
      } else {
        setErr({
          err: errToManySigns,
        });
      }
    }
  };

  const handleClickOutside = (e) => {
    if (
      refs.current &&
      refs.current.contains(e.target) &&
      !refsHref.current.contains(e.target)
    ) {
      overflowHidden();
      // topDealsUpdateClose();
      shopUpdateClose();
      // setTopDealsItemToUpdate(null);
      setShopToUpdate(null);
      setTopDealUpdatedID && setTopDealUpdatedID("");

      return document.removeEventListener("click", handleClickOutside);
    }
  };
  useEffect(() => {
    document && document.addEventListener("click", handleClickOutside, true);
    return () =>
      document.removeEventListener("click", handleClickOutside, true);
  });

  useEffect(() => {
    setShopItem(chosenItem);
  }, [chosenItem]);
  useEffect(() => {
    return () => {
      setUpdatetShopId(null);
    };
  }, []);
  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <ErrorBoundary>
          <WrapperForSingleOffer
            isDark={isDark}
            variants={modalWraperVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            ref={refs}
            // add={add}
          >
            <ForCloseIcon
              isDark={isDark}
              onClick={() => {
                overflowHidden();
                shopUpdateClose();
                setTopDealUpdatedID && setTopDealUpdatedID("");
              }}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="times"
                className="svg-inline--fa fa-times fa-w-11"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 352 512"
                style={styleForMoreActionsSvg}
              >
                <path fill="currentColor" d={closePath}></path>
              </svg>
            </ForCloseIcon>
            <SingleItemContent
              isDark={isDark}
              variants={singleItemModalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              ref={refsHref}
            >
              <h2>{`${shopItem?.name ? shopItem.name : ""}`}</h2>
              <DataForSingle
                isDark={isDark}
                optionsActive={optionsActive}
                link={shopItem?.linkHref}
              >
                <div>
                  <section>
                    <div>
                      <p>Ulica:</p>
                      <p>
                        {shopItem?.street || "brak danych"} {shopItem?.number}
                      </p>
                    </div>
                    <div>
                      <p>Miasto:</p>
                      <p>
                        {shopItem?.postCode || "brak danych"} {shopItem?.city}
                      </p>
                    </div>
                    <div>
                      <p>Geo:</p>
                      <p>
                        {shopItem?.position[0]} - {shopItem?.position[1]}
                      </p>
                    </div>
                  </section>
                  <section>
                    <div>
                      <p>
                        {shopItem?.openingHours?.length
                          ? shopItem?.openingHours
                          : "Brak danych o godzinach otwarcia"}
                      </p>
                    </div>
                  </section>
                </div>
                <p
                  onClick={() => {
                    // console.log("zmiana", optionsActive);
                    setOptionsActive(!optionsActive);
                  }}
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="angle-down"
                    className="svg-inline--fa fa-angle-down fa-w-10"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                    style={
                      optionsActive
                        ? isDark
                          ? styleForActiveDark
                          : styleForActiveLigth
                        : !isDark
                        ? styleForDisactiveLight
                        : styleForDisactiveDark
                    }
                  >
                    <path fill="currentColor" d={downPath}></path>
                  </svg>
                </p>

                {optionsActive && (
                  <div>
                    <InputModalItems
                      isDark={isDark}
                      variants={animationInputVariants}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                    >
                      <div>
                        <p>Ulica</p>
                        <input
                          type="text"
                          name="street"
                          placeholder="Brak danych"
                          value={shopItem?.street || ""}
                          onChange={handleOnchange}
                          onKeyDown={(e) => handleKeyDown(e)}
                        />
                      </div>
                      <div>
                        <p>Numer</p>
                        <input
                          type="text"
                          name="number"
                          placeholder="Brak danych"
                          value={shopItem?.number || ""}
                          onChange={handleOnchange}
                          onKeyDown={(e) => handleKeyDown(e)}
                        />
                      </div>
                      <div>
                        <p>Miasto</p>
                        <input
                          type="text"
                          name="city"
                          placeholder="Brak danych"
                          value={shopItem?.city || ""}
                          onChange={handleOnchange}
                          onKeyDown={(e) => handleKeyDown(e)}
                        />
                      </div>
                      <div>
                        <p>Kod pocztowy</p>
                        <input
                          type="text"
                          name="postCode"
                          placeholder="Brak danych"
                          value={shopItem?.postCode || ""}
                          onChange={handleOnchange}
                          onKeyDown={(e) => handleKeyDown(e)}
                        />
                      </div>

                      <div>
                        <p>Szerokość geogr.</p>
                        <input
                          type="text"
                          name="latitiude"
                          placeholder="Brak danych"
                          value={shopItem?.position[0]}
                          onChange={handleOnchange}
                          onKeyDown={(e) => handleKeyDown(e)}
                        />
                        <p>Długość geogr.</p>
                        <input
                          type="text"
                          name="longitiude"
                          placeholder="Brak danych"
                          value={shopItem?.position[1]}
                          onChange={handleOnchange}
                          onKeyDown={(e) => handleKeyDown(e)}
                        />
                      </div>
                      <div>
                        <p>Godziny otwarcia</p>
                        <textarea
                          type="text"
                          name="openingHours"
                          placeholder={`Brak danych-np. poniedziałek-piatek: 8:00-22:00
                                      sobota: 7:00-20:00`}
                          value={shopItem?.openingHours}
                          onChange={handleOnchange}
                          onKeyDown={(e) => handleKeyDown(e)}
                        />
                      </div>
                      {err?.err && <p>{err?.err}</p>}
                    </InputModalItems>
                  </div>
                )}
              </DataForSingle>
              <WraperFor2Items isDark={isDark}>
                <BrandForSingle
                  isDark={isDark}
                  optionsBrandActive={optionsBrandActive}
                  brandsLength={data?.brands?.length}
                >
                  <section>
                    <img
                      src={
                        shopItem?.name
                          ? `/static/assets/media/brands/${shopItem?.name
                              ?.split(" ")
                              ?.join("-")}.png`
                          : imageRegular
                      }
                      style={
                        isDark
                          ? imgSuspenseStyleForBrandDark
                          : imgSuspenseStyleForBrandLight
                      }
                    />
                    <p>{shopItem?.name || "brak marki"}</p>
                    <div
                      onClick={() => {
                        // console.log("zmiana", optionsBrandActive);
                        setBrandOptionsActive(!optionsBrandActive);
                      }}
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="angle-down"
                        className="svg-inline--fa fa-angle-down fa-w-10"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                        style={
                          optionsBrandActive
                            ? isDark
                              ? styleForActiveDark
                              : styleForActiveLigth
                            : !isDark
                            ? styleForDisactiveLight
                            : styleForDisactiveDark
                        }
                      >
                        <path fill="currentColor" d={downPath}></path>
                      </svg>
                    </div>
                  </section>
                  {optionsBrandActive && (
                    <section>
                      <SelectBrand
                        isDark={isDark}
                        setBrandOptionsActive={setBrandOptionsActive}
                        brands={data?.brands}
                        setShopItem={setShopItem}
                        shopItem={shopItem}
                      />
                    </section>
                  )}
                </BrandForSingle>
              </WraperFor2Items>

              <ChangeButton
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                onClick={() => {
                  if (!shopItem?.position[0] || !shopItem?.position[1]) {
                    setErr({ err: "Brak geolokalizacji" });
                    return;
                  }
                  if (!shopItem?.name) {
                    setErr({ err: "Brak marki" });
                    return;
                  }
                  overflowHidden();
                  shopUpdateClose();
                  setShopToUpdate(null);
                  fetchForChangeListItem(shopItem, setUpdatetShopId);
                }}
              >
                <p>
                  {/* <Trash style={styleForActionsSvg} /> */}
                  <SvgDisplay
                    viewBox={save.viewBox}
                    d={save.d}
                    width={20}
                    style={{ marginRight: "5px" }}
                    fill={
                      isDark
                        ? Theme.dark.thirdColor.white
                        : Theme.light.thirdColor.dark
                    }
                  />
                  zapisz
                </p>
              </ChangeButton>
            </SingleItemContent>
          </WrapperForSingleOffer>
        </ErrorBoundary>
      </AnimatePresence>
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  overflowHidden: () => dispatch(overflowHidden()),
  shopUpdateClose: () => dispatch(shopUpdateClose()),
  setShopToUpdate: (item) => dispatch(setShopToUpdate(item)),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  select: state.select,
  user: state.user,
});
export const ModalForShops = connect(
  mapStateToProps,
  mapDispatchToProps
)(_ModalForShops);
