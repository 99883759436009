import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import ProductsImg from "../../../../assets/media/svg/bag-shopping-solid.svg";
import { SingleOptionWrapper } from "../LeftColumn.style";
import { menuClose } from "../../../../store/theme/duck/actions/actions";

const Products = ({ user, theme, menuClose }) => {
  const [isActive, setActive] = useState(false);
  let location = useLocation();
  useEffect(() => {
    if (location?.pathname === "/studio/products") {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [location?.pathname]);
  return (
    <Link to="/studio/products" onClick={() => menuClose()}>
      <SingleOptionWrapper isDark={theme.isDark} isActive={isActive}>
        <img src={ProductsImg} alt="produkty" />
        <p>Promowane produkty</p>
      </SingleOptionWrapper>
    </Link>
  );
};
const mapDispatchToProps = (dispatch) => ({
  menuClose: () => dispatch(menuClose()),
});

const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(Products);
