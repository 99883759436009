export const MARK = "MARK";
export const SELECT_ITEM = "SELECT_ITEM";
export const SORT_TYPE = "SORT_TYPE";
export const SORT_DEALS_TYPE = "SORT_DEALS_TYPE";
export const TOP_DEALS_SELECT = "TOP_DEALS_SELECT";
export const CLEAR_TOP_DEALS = "CLEAR_TOP_DEALS";
export const SELECT_PAGE_FROM_DEAL = "SELECT_PAGE_FROM_DEAL";
export const CLEAR_SELECT_PAGE_FROM_DEAL = "CLEAR_SELECT_PAGE_FROM_DEAL";
export const SET_TARGET_CORDINATES = "SET_TARGET_CORDINATES";
export const CLEAR_TARGET_CORDINATES = "CLEAR_TARGET_CORDINATES";
export const NEWSPAPER_ID = "NEWSPAPER_ID";
export const LOCALIZATIONS_FOUNDED = "LOCALIZATIONS_FOUNDED";
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SELECT_ITEM_FROM_SEARCH = "SELECT_ITEM_FROM_SEARCH";
export const ADD_TO_SUGESTED_NEWSPAPERS = "ADD_TO_SUGESTED_NEWSPAPERS";
export const REMOVE_ONE_FROM_SUGESTED_NEWSPAPERS =
  "REMOVE_ONE_FROM_SUGESTED_NEWSPAPERS";
export const CLEAR_SUGESTED_NEWSPAPERS = "CLEAR_SUGESTED_NEWSPAPERS";
export const CHANGE_DATA_ANALYS_PERIOD = "CHANGE_DATA_ANALYS_PERIOD";
export const TOP_DEALS_ITEM_TO_UPDATE = "TOP_DEALS_ITEM_TO_UPDATE";
export const SHOP_TO_UPDATE = "SHOP_TO_UPDATE";
// export default {
//   MARK,
//   SELECT_ITEM,
//   SORT_TYPE,
//   SORT_DEALS_TYPE,
//   NEWSPAPER_ID,
//   LOCALIZATIONS_FOUNDED,
//   TOP_DEALS_SELECT,
//   CLEAR_TOP_DEALS,
//   SELECT_PAGE_FROM_DEAL,
//   CLEAR_SELECT_PAGE_FROM_DEAL,
//   SET_TARGET_CORDINATES,
//   CLEAR_TARGET_CORDINATES,
//   SET_CURRENT_PAGE,
//   SELECT_ITEM_FROM_SEARCH
// };
