import React from "react";
export const SvgDisplay = ({
  viewBox,
  d,
  style,
  isDark,
  isActive,
  fill,
  width,
  onClickCB,
}) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      style={style}
      width={width}
      onClick={onClickCB}
    >
      <path fill={fill || "currentColor"} d={d}></path>
    </svg>
  );
};
