import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Map, TileLayer, Marker, useLeaflet } from "react-leaflet";
import styled from "styled-components";
import { GetShops } from "./GetShops";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import { Theme } from "../../store/theme/theme";
import { AddShop } from "./addShop/AddShop";
const MapWrraper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  @media${Theme.device.mobileL} {
    z-index: 4;
  }
`;
const _MapContainer = ({
  select,
  localization,
  theme,
  updatetShopId,
  setUpdatetShopId,
}) => {
  const [name, setName] = useState("");
  const map = useRef(null);

  const arroundOption = localization.arround.filter(
    (option) => option.active === true
  );

  const coordinates = [
    localization.coordinates.lat,
    localization.coordinates.lng,
  ];
  useEffect(() => {
    // console.log("center");
    // map.current.viewport.center = coordinates;
    // map.current.contextValue.map.options.center = coordinates;
    // map.current.viewport.zoom = arroundOption[0].zoom;
    // map.current.onViewportChange(coordinates, arroundOption[0].zoom);
  }, [arroundOption[0]?.name]);
  useEffect(() => {
    setName(select.options.filter((item) => item.active)[0].valueName);
    return () => {
      setName("");
    };
  }, [select.options.filter((item) => item.active)[0].valueName]);
  return (
    <>
      <ErrorBoundary>
        <MapWrraper>
          <Map
            center={coordinates}
            zoom={arroundOption[0].zoom}
            scrollWheelZoom={false}
            doubleClickZoom={false}
            style={{ height: "calc(100vh - 200px)", width: "100%" }}
            ref={map}
          >
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={coordinates} zIndexOffset={998}></Marker>
            <GetShops
              name={name}
              arroundOption={arroundOption[0].value}
              theme={theme}
              map={map}
              updatetShopId={updatetShopId}
              setUpdatetShopId={setUpdatetShopId}
            />
          </Map>
          <AddShop />
        </MapWrraper>
      </ErrorBoundary>
    </>
  );
};
const mapStateToProps = (state) => ({
  select: state.select,
  localization: state.localization,
  theme: state.theme,
});
export const MapContainer = connect(mapStateToProps, {})(_MapContainer);
