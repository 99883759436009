import React from "react";
import { LoginWrapper } from "./LoginPage.style";
import UserPasswordReset from "../components/forgottenPass/UserPasswordReset";
const ForgottenPasswordPage = () => {
  return (
    <LoginWrapper>
      <UserPasswordReset />
    </LoginWrapper>
  );
};
export default ForgottenPasswordPage;
