import React from "react";
import LogoImg from "../../assets/media/img/studio-cenniczek-logo-for-dark.png";
import { LogoWrapper } from "./LogoStudio.style";

const LogoStudio = () => {
  return (
    <LogoWrapper>
      <img src={LogoImg} alt="logo studio cenniczek.com" />
    </LogoWrapper>
  );
};
export default LogoStudio;
