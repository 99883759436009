import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Theme } from "../../store/theme/theme";
import { connect } from "react-redux";
import {
  FormWrapper,
  pVariants,
  divVariants,
  ThumbsUpIconStyle,
} from "./Login.style";
import { VisiblePasswordHandler } from "./visiblePasswordHandler/VisiblePasswordHandler";
import { regEmail } from "../../tools/regEmail/regEmail";
import { Spinner } from "../../tools/spinner/Spinner";
import ReCAPTCHA from "react-google-recaptcha";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/default.css";
import { API_URL } from "../../constant/constant";
import {
  encryptFrontData,
  decryptFrontData,
} from "../../utils/Crypto/cryptoFront.controller";
import logoIcon from "../../assets/media/svg/cenniczek_svg_logo_tiny.svg";
import history from "../../utils/history";
import { recaptchaSiteKey } from "../../constant/constant";
import { useHistory } from "react-router-dom";
import { userSignInService } from "../../store/user/duck/actions/actions";
const sadFacePath =
  "M0 256C0 397.4 114.6 512 256 512s256-114.6 256-256S397.4 0 256 0S0 114.6 0 256zm240 80c0-8.8 7.2-16 16-16c45 0 85.6 20.5 115.7 53.1c6 6.5 5.6 16.6-.9 22.6s-16.6 5.6-22.6-.9c-25-27.1-57.4-42.9-92.3-42.9c-8.8 0-16-7.2-16-16zm-80 80c-26.5 0-48-21-48-47c0-20 28.6-60.4 41.6-77.7c3.2-4.4 9.6-4.4 12.8 0C179.6 308.6 208 349 208 369c0 26-21.5 47-48 47zM303.6 208c0-17.7 14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32s-32-14.3-32-32zm-128 32c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z";

const Login = ({ theme, userSignInService, user }) => {
  let newhistory = useHistory();
  const [body, setBody] = useState({ email: "", password: "" });
  const [mainValidation, setMainValidation] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);

  const recaptchaRef = React.createRef();
  const [human, setHuman] = useState(false);
  const [inputTextValue, setInputTextValue] = useState("");
  const [inputPasswordValue, setInputPasswordValue] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [validPassword, setValidPassword] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [responseError, setResponseError] = useState(false);
  const [getCorrectResponse, setCorrectResponse] = useState(false);
  const [errorFromCrypto, setErrorFromCrypto] = useState("");
  const [processing, setProcessing] = useState(false);

  const onChange = () => {
    setHuman(true);
    console.log("recaptcha resolved");
  };

  const passwordValidationHandle = (password) => {
    setInputPasswordValue(password);
    // if (responseError) {
    //   setResponseError("");
    // }

    if (password.length >= 6 && password.length < 33) {
      setPasswordError("");
    }
    if (!password) {
      setPasswordError("Wpisz proszę swoje hasło");
    }
    if (password && password.length < 6) {
      setPasswordError("Wpisane hasło jest zbyt krótkie, min 6 znaków");
    }
    if (password.length > 33) {
      setPasswordError("Wpisane hasło jest zbyt długie, max 33 znaki");
    }
    if (password.length >= 6 && password.length <= 33) {
      setPasswordError("");
      setValidPassword(true);
    }
  };
  const emailValidationHandle = (email) => {
    setInputTextValue(email);

    if (!email) {
      setEmailError("Wpisz proszę swój e-mail");
    }
    if (email && !regEmail.test(email)) {
      setEmailError("Wpisany e-mail nie jest poprawny");
    }
    if (regEmail.test(email)) {
      setEmailError("");
      setValidEmail(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    if (!human && recaptchaRef.current) {
      recaptchaRef.current.reset();
      // recaptchaRef.current.execute()
    }

    if (!inputTextValue) {
      setEmailError("Wpisz proszę swój e-mail");
    }
    if (!inputPasswordValue) {
      setPasswordError("Wpisz proszę swoje hasło");
    }

    if (validEmail && validPassword) {
      setBody({
        email: inputTextValue,
        password: inputPasswordValue,
      });
      setMainValidation(true);
    }
  };
  useEffect(() => {
    if (body?.email && body?.password && mainValidation) {
      if (responseError) {
        setResponseError("");
      }
      const frontDataToEncrypt = {
        email: body?.email,
        password: body?.password,
      };
      // encrypt data for fetch
      const ciphertext = encryptFrontData(
        frontDataToEncrypt,
        setErrorFromCrypto,
        null,
        "AfterValidatione.js-userLogin"
      );
      fetch(`${API_URL}/api-studio/user/login`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: ciphertext,
        }),
      })
        .then((response) => response.json())
        .then((result) => {
          // decrypt data from response
          return decryptFrontData(
            result.data,
            setErrorFromCrypto,
            null,
            "AfterValidatione.js-userLogin"
          );
        })
        .then((data) => {
          // console.log(data);
          setProcessing(false);
          if (data?.userLoged === true) {
            if (responseError) {
              setResponseError("");
            }
            setInputTextValue("");
            setInputPasswordValue("");
            setBody({ email: "", password: "" });
            setCorrectResponse(true);

            const toLogin = {
              email: data?.email,
              name:
                data?.user?.firstName[0].toUpperCase() +
                data?.user?.firstName.substring(1),
              represents: data?.user?.represents,
              interests: data?.user?.interests,
              avatar: data?.user?.avatar,
            };
            userSignInService(toLogin);
            // usserLogged(toLogin);__________________________________________________________________________________________________________________
            history.push("/studio/dashboard");
            // document.location.reload();
            alertify.set("notifier", "position", "bottom-left");
            alertify.success(`Zalogowałeś się pomyślnie`);
          } else if (data?.userLoged === false && data?.error) {
            setResponseError(`${data?.error}`);
          } else {
            setResponseError(
              "Przepraszamy coś poszło nie tak :( ,spróbuj ponownie."
            );
          }
        });
    }
  }, [body?.email, body?.password, mainValidation, history]);
  useEffect(() => {
    return () => {
      setInputTextValue("");
      setInputPasswordValue("");
      setBody({ email: "", password: "" });
    };
  }, []);

  return (
    <FormWrapper isDark={theme?.isDark}>
      {!processing && (
        <>
          <img src={logoIcon} alt="logo-icon" />
          <p>Studio.Cenniczek.com</p>
        </>
      )}
      {responseError && !processing && (
        <AnimatePresence>
          <>
            <motion.div
              variants={divVariants}
              initial="hidden"
              animate="visible"
            >
              <svg
                aria-hidden="true"
                focusable="false"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                style={ThumbsUpIconStyle}
                width={150}
              >
                <path fill="currentColor" d={sadFacePath}></path>
              </svg>
            </motion.div>
            <motion.p
              variants={pVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              style={{
                backgroundColor: `${Theme.light.fourthColor.red}`,
                padding: "5px",
              }}
            >
              {responseError}
            </motion.p>
          </>
        </AnimatePresence>
      )}
      {processing && <Spinner small={true} />}
      {!processing && (
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="podaj swój login"
            name="email"
            value={inputTextValue}
            onChange={(e) => emailValidationHandle(e.target.value)}
          />
          <AnimatePresence>
            {emailError && (
              <motion.p
                variants={pVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{
                  backgroundColor: `${Theme.light.fourthColor.red}`,
                  padding: "5px",
                }}
              >
                {emailError}
              </motion.p>
            )}
          </AnimatePresence>
          <div style={{ position: "relative" }}>
            <input
              type={passwordVisible ? "text" : "password"}
              placeholder="wpisz hasło"
              name="password"
              value={inputPasswordValue}
              onChange={(e) => passwordValidationHandle(e.target.value)}
            />
            <VisiblePasswordHandler
              passwordVisible={passwordVisible}
              setPasswordVisible={setPasswordVisible}
              isDark={theme?.isDark}
            />
          </div>

          <AnimatePresence>
            {passwordError && (
              <motion.p
                variants={pVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{
                  backgroundColor: `${Theme.light.fourthColor.red}`,
                  padding: "5px",
                }}
              >
                {passwordError}
              </motion.p>
            )}
          </AnimatePresence>
          <div style={{}}>
            <a href="/password-reset" alt="zapomniałem hasła">
              zapomniałem hasła
            </a>
            <a href="/sign-up" alt="rejestracja">
              rejestruj się
            </a>
          </div>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={recaptchaSiteKey}
            onChange={onChange}
            size={"normal"}
            hl={"pl"}
            theme={theme?.isDark ? "dark" : "light"}
            style={{ width: "300px" }}
          />
          {processing ? null : (
            <motion.button whileTap={{ scale: 0.95 }} type="submit">
              Loguj
            </motion.button>
          )}
        </form>
      )}
    </FormWrapper>
  );
};
const mapDispatchToProps = (dispatch) => ({
  userSignInService: (item) => dispatch(userSignInService(item)),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
