import styled from "styled-components";
import { Theme } from "../../store/theme/theme";
export const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 60px;
  margin: 10px;
  border-radius: 5px;
  text-transform: uppercase;
  background-color: ${Theme.light.secondaryColor.green};
  border: 1px solid black;
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.dark.thirdColor.white};
  transition: 0.3s;
  transform: scale(1);
  user-select: none;
  cursor: pointer;
  &:hover {
    box-shadow: 2px 2px 7px black;
  }
  & > button {
    // width: 100%;
    // height: 100%;
    padding-left: 5px;
    background-color: ${Theme.light.secondaryColor.green};
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    text-transform: uppercase;
    cursor: pointer;
    border: none;
  }

  // & > button:hover {
  //   box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
  //   transform: scale(1.1);
  // }
`;
