import history from "../utils/history";

const usePermissionController = (user) => {
  if (Object.keys(user)?.length) {
    if (
      user?.userLoged === false ||
      user?.interests === "regular" ||
      !user?.represents
    ) {
      //   history.push("/");
    }
  }
};
export default usePermissionController;
