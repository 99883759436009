import styled from "styled-components";
import { Theme } from "../../store/theme/theme";

export const WrapperForNewspapers = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.primaryColor.dark : Theme.light.sixColor.white};
`;

export const WrapperForInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  & > h4 {
    width: 100%;
    text-align: center;
    margin: 20px;
    text-transform: uppercase;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px;
  flex-wrap: wrap;
  & > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  & > form > div {
    width: 60%;
    margin: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  & > form > div > p {
    width: 50%;
  }
  & > form > div > input,
  & > form > div > select {
    width: 60%;
    height: 60px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    text-align: center;
    transition: 0.1s;
    transform: scale(1);
    background: ${({ isDark }) =>
      isDark
        ? Theme.dark.eightColor.dark
        : Theme.light.primaryColor.white} !important;
    color: ${({ isDark }) =>
      isDark ? Theme.light.fiveColor.white : Theme.light.thirdColor.dark};
    box-shadow: 0px 7px 29px 0px
      ${({ isDark }) =>
        isDark ? Theme.dark.eightColor.dark : Theme.light.eightColor.ligthGray};
  }

  & > form > div > textarea {
    width: 60%;
    height: 120px;
    margin: 20px;
    border: none;
    border-radius: 5px;
    text-align: center;
    transition: 0.1s;
    transform: scale(1);
    background: ${({ isDark }) =>
      isDark
        ? Theme.dark.eightColor.dark
        : Theme.light.primaryColor.white} !important;
    color: ${({ isDark }) =>
      isDark ? Theme.light.fiveColor.white : Theme.light.thirdColor.dark};
    box-shadow: 0px 7px 29px 0px
      ${({ isDark }) =>
        isDark ? Theme.dark.eightColor.dark : Theme.light.eightColor.ligthGray};
  }

  & > form > button {
    width: 200px;
    height: 60px;
    margin: 10px;
    border-radius: 5px;
    text-transform: uppercase;
    background-color: ${Theme.light.secondaryColor.green};
    border: 1px solid black;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.dark.thirdColor.white};
    transition: 0.3s;
    transform: scale(1);
    user-select: none;
    cursor: pointer;
  }
  & > form > button:hover {
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
    transform: scale(1.1);
  }
  @media${Theme.device.mobileL} {
    & > form > div {
      width: 100%;
    }
    & > form > div > input,
    & > form > div > select,
    & > form > div > textarea {
      width: 80%;
    }
    & > form > button {
      margin-bottom: 20px;
    }
  }
`;
export const ActionWrapper = styled.div`
  width: 70%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  & > p {
    color: red;
  }
`;

export const pVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};
