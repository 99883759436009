import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";

import { LoadDeals } from "../loadDeals/LoadDeals";

const MainTopDealsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 40vh;
  position: relative;
`;

const _MainTopDealsContent = ({
  select,
  theme,
  options,
  dealsType,
  setNumberOfDeals,
  setDealsNumber,
  dataforCenniczek,
  loading,
  setDeletedItems,
  deletedItems,
  topDealUpdatedID,
  topDealUpdatedTimestamp,
  setTopDealUpdatedTimestamp,
}) => {
  let activeSortType;
  let optionsConfirmed = options;

  activeSortType =
    select?.sortDeals.filter((item) => item.active) || "recomended";

  return (
    <MainTopDealsWrapper>
      <LoadDeals
        optionsConfirmed={optionsConfirmed}
        activeSortType={activeSortType && activeSortType[0]}
        topDealsSelected={select.topDealsSelected.name}
        theme={theme}
        dealsType={dealsType}
        setNumberOfDeals={setNumberOfDeals}
        setDealsNumber={setDealsNumber}
        dataforCenniczek={dataforCenniczek}
        loading={loading}
        setDeletedItems={setDeletedItems}
        deletedItems={deletedItems}
        topDealUpdatedID={topDealUpdatedID}
        topDealUpdatedTimestamp={topDealUpdatedTimestamp}
        setTopDealUpdatedTimestamp={setTopDealUpdatedTimestamp}
      />
    </MainTopDealsWrapper>
  );
};
const mapStateToProps = (state) => ({
  theme: state.theme,
  select: state.select,
});
export const MainTopDealsContent = connect(
  mapStateToProps,
  {}
)(_MainTopDealsContent);
