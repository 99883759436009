import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { API_URL } from "../../constant/constant";
import {
  decryptFrontData,
  encryptFrontData,
} from "../../utils/Crypto/cryptoFront.controller";
import { jsPDF } from "jspdf";
const dashboardService = {
  getAnalis: (start, stop, setData, setProcess, setErrorFromCrypto) => {
    const frontDataToEncrypt = {
      start: start,
      stop: stop,
    };

    // encrypt data for fetch
    const ciphertext = encryptFrontData(
      frontDataToEncrypt,
      setErrorFromCrypto,
      setProcess,
      "dashboardService.js"
    );

    fetch(`${API_URL}/api-studio/metrics`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: ciphertext }),
    })
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        // decrypt data from response
        return decryptFrontData(
          result.data,
          setErrorFromCrypto,
          setProcess,
          "dashboardService.js"
        );
      })
      .then((data) => {
        setData(data);
        setProcess(false);
      })
      .catch((err) => console.log(err));
  },
  days: (data, setDays) => {
    if (data && data.ok && data.start[0]) {
      const number = Math.round(
        (data.stop[0] - data.start[0]) / (1000 * 60 * 60 * 24)
      );
      const checkDate = (miliseconds) => {
        const daysFrom = [];
        for (let i = 0; i < number; i++) {
          daysFrom.push(
            new Date(
              miliseconds + 1000 * 60 * 60 + i * 1000 * 60 * 60 * 24
            ).toLocaleDateString()
          );
        }
        return daysFrom;
      };

      setDays({
        render: 0,
        number: number,
        time: {
          day: checkDate(data?.start[0]),
          shoping: checkDate(data?.start[0]).map((item) => {
            const result = data?.shopingList.filter((listItem) => {
              if (typeof listItem?.created === "string") {
                if (listItem.created.indexOf(",") !== -1) {
                  return (
                    listItem.created.slice(0, listItem.created.indexOf(",")) ===
                    item
                  );
                } else if (listItem.created.indexOf("o") !== -1) {
                  return (
                    listItem.created.slice(
                      0,
                      listItem.created.indexOf("o") - 1
                    ) === item
                  );
                }
              } else if (typeof listItem.created === "number") {
                return new Date(listItem.created).toLocaleDateString() === item;
              }
            });
            if (result.length > 0) {
              result[0].total = result
                .map((item) => parseFloat(item.total))
                .reduce((total, item) => total + item, 0)
                .toString();
              const arrayList = [];
              result.map((res) => arrayList.push(...res.list));
              result[0].list = arrayList;
            }

            return {
              result: result[0],
              day: item,
            };
          }),
        },
      });
    }
  },
  downloadCSVRaportForCenniczek: async (data, days, setGenerate, setError) => {
    const frontDataToEncrypt = {
      data,
      days,
    };

    // encrypt data for fetch
    const ciphertext = encryptFrontData(
      frontDataToEncrypt,
      null,
      null,
      "dashboardService.downloadCSVRaport.js"
    );
    const res = await fetch(
      `${API_URL}/api-studio/downloadCSVRaportForCenniczek`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: ciphertext }),
      }
    );
    const blob = await res.blob();
    if (res.status === 401) setError("Coś poszło nie tak");

    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.target = "_blank";
    a.download = "raport_cenniczek_info.csv";
    <ProgressBar completed={180} maxCompleted={200} />;
    a.click();
    URL.revokeObjectURL(downloadUrl);
    setGenerate(false);
  },
  downloadCSVRaportForContractor: async (data, days, setGenerate, setError) => {
    const frontDataToEncrypt = {
      data,
      days,
    };

    // encrypt data for fetch
    const ciphertext = encryptFrontData(
      frontDataToEncrypt,
      null,
      null,
      "dashboardService.downloadCSVRaport.js"
    );
    const res = await fetch(
      `${API_URL}/api-studio/downloadCSVRaportForContractor`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: ciphertext }),
      }
    );
    const blob = await res.blob();
    if (res.status === 401) setError("Coś poszło nie tak");

    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.target = "_blank";
    a.download = "raport_contractor_info.csv";
    <ProgressBar completed={180} maxCompleted={200} />;
    a.click();
    URL.revokeObjectURL(downloadUrl);
    setGenerate(false);
  },
  savingRaportsAsPDF: async (id, dimensions, isDark) => {
    const chartEl = document.getElementById(id);
    const image = chartEl.toDataURL("image/png", 1.0);

    const pdf = new jsPDF("landscape");
    isDark && pdf.setFillColor(49, 47, 47);
    isDark && pdf.rect(...dimensions, "F");
    pdf.addImage(image, "PNG", ...dimensions);
    pdf.save(`${id}.pdf`);
  },
  PDFRaportForCenniczek: async (data, days) => {
    const frontDataToEncrypt = {
      data,
      days,
    };

    // encrypt data for fetch
    const ciphertext = encryptFrontData(
      frontDataToEncrypt,
      null,
      null,
      "dashboardService.PDFRaportForCenniczek.js"
    );
    const res = await fetch(
      `${API_URL}/api-studio/downloadPDFRaportForCenniczek`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: ciphertext }),
      }
    );
    const blob = await res.blob();
    // if (res.status === 401) setError("Coś poszło nie tak");
    // console.log(blob, "blob");
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.target = "_blank";
    a.download = "raport.pdf";
    a.click();
    URL.revokeObjectURL(downloadUrl);
    // setGenerate(false);
  },
  PDFRaportForContractor: async (data, days) => {
    const frontDataToEncrypt = {
      data,
      days,
    };

    // encrypt data for fetch
    const ciphertext = encryptFrontData(
      frontDataToEncrypt,
      null,
      null,
      "dashboardService.PDFRaportForContractor.js"
    );
    const res = await fetch(
      `${API_URL}/api-studio/downloadPDFRaportForContractor`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: ciphertext }),
      }
    );
    const blob = await res.blob();
    // if (res.status === 401) setError("Coś poszło nie tak");
    // console.log(blob, "blob");
    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.target = "_blank";
    a.download = "raport.pdf";
    a.click();
    URL.revokeObjectURL(downloadUrl);
    // setGenerate(false);
  },
};
export default dashboardService;
