import React, { useState } from "react";

import { Theme } from "../../store/theme/theme";
import { SingleItemInfo, ActionWraper } from "./SingleItemShop.style";

import { motion } from "framer-motion";
import styled from "styled-components";
import { SvgDisplay } from "../../tools/svgDisplay/SvgDisplay";
import { pen, trash } from "../../assets/media/svgPath/svgPath";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/default.css";
import deleteShop from "../../helpers/deleteShop";
import { useEffect } from "react";
const searchPath =
  "M505.04 442.66l-99.71-99.69c-4.5-4.5-10.6-7-17-7h-16.3c27.6-35.3 44-79.69 44-127.99C416.03 93.09 322.92 0 208.02 0S0 93.09 0 207.98s93.11 207.98 208.02 207.98c48.3 0 92.71-16.4 128.01-44v16.3c0 6.4 2.5 12.5 7 17l99.71 99.69c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.59.1-33.99zm-297.02-90.7c-79.54 0-144-64.34-144-143.98 0-79.53 64.35-143.98 144-143.98 79.54 0 144 64.34 144 143.98 0 79.53-64.35 143.98-144 143.98zm.02-239.96c-40.78 0-73.84 33.05-73.84 73.83 0 32.96 48.26 93.05 66.75 114.86a9.24 9.24 0 0 0 14.18 0c18.49-21.81 66.75-81.89 66.75-114.86 0-40.78-33.06-73.83-73.84-73.83zm0 96c-13.26 0-24-10.75-24-24 0-13.26 10.75-24 24-24s24 10.74 24 24c0 13.25-10.75 24-24 24z";
const closeLocPath =
  "M112 316.94v156.69l22.02 33.02c4.75 7.12 15.22 7.12 19.97 0L176 473.63V316.94c-10.39 1.92-21.06 3.06-32 3.06s-21.61-1.14-32-3.06zM144 0C64.47 0 0 64.47 0 144s64.47 144 144 144 144-64.47 144-144S223.53 0 144 0zm0 76c-37.5 0-68 30.5-68 68 0 6.62-5.38 12-12 12s-12-5.38-12-12c0-50.73 41.28-92 92-92 6.62 0 12 5.38 12 12s-5.38 12-12 12z";
const openPath =
  "M624 448h-80V113.45C544 86.19 522.47 64 496 64H384v64h96v384h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16zM312.24 1.01l-192 49.74C105.99 54.44 96 67.7 96 82.92V448H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h336V33.18c0-21.58-19.56-37.41-39.76-32.17zM264 288c-13.25 0-24-14.33-24-32s10.75-32 24-32 24 14.33 24 32-10.75 32-24 32z";
const phonePath =
  "M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z";
const phoneCallPath =
  "M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM94 416c-7.033 0-13.057-4.873-14.616-11.627l-14.998-65a15 15 0 0 1 8.707-17.16l69.998-29.999a15 15 0 0 1 17.518 4.289l30.997 37.885c48.944-22.963 88.297-62.858 110.781-110.78l-37.886-30.997a15.001 15.001 0 0 1-4.289-17.518l30-69.998a15 15 0 0 1 17.16-8.707l65 14.998A14.997 14.997 0 0 1 384 126c0 160.292-129.945 290-290 290z";
const carPath =
  "M438.66 212.33l-11.24-28.1-19.93-49.83C390.38 91.63 349.57 64 303.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4l-19.93 49.83-11.24 28.1C17.22 221.5 0 244.66 0 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-27.34-17.22-50.5-41.34-59.67zm-306.73-54.16c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L368 208H112l19.93-49.83zM80 319.8c-19.2 0-32-12.76-32-31.9S60.8 256 80 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S380.8 256 400 256s32 12.76 32 31.9-12.8 31.9-32 31.9z";
const dotsPath =
  "M96 184c39.8 0 72 32.2 72 72s-32.2 72-72 72-72-32.2-72-72 32.2-72 72-72zM24 80c0 39.8 32.2 72 72 72s72-32.2 72-72S135.8 8 96 8 24 40.2 24 80zm0 352c0 39.8 32.2 72 72 72s72-32.2 72-72-32.2-72-72-72-72 32.2-72 72z";
const closePath =
  "M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z";
const divVariants = {
  hidden: {
    opacity: 0,
    // y: "-5%",
  },
  visible: (index) => ({
    opacity: 1,
    // y: 0,
    transition: {
      duration: 0.3,
      // delay:index*0.1+0.5,
      delay: 0.1,
    },
  }),
  exit: {
    opacity: 0,
    // y: "-5%",
  },
};
const styleForIcon = {
  width: "30px",
  margin: "10px",
  maxHeight: "30px",
};
const styleForDots = {
  width: "30px",
  maxHeight: "30px",
  position: "absolute",
  top: "20px",
  right: "0",
  margin: "10px 10px 10px 10px",
  color: `${Theme.light.fourthColor.red}`,
  cursor: "pointer",
};

const WraperForSingleItem = styled(motion.div)`
  width: 100%;
  position: relative;
`;
const SingleItemShop = ({
  localizationItem,
  option,
  theme,
  updatetShopId,
  setUpdatetShopId,
  deleteShopsNearUser,
  shopUpdateOpen,
  setShopToUpdate,
  itemOffset,
}) => {
  const [extended, setExtended] = useState(false);

  const closestShop = localizationItem?.item;
  const distanseFromUser =
    Math.round(localizationItem?.cordinatesDiff) > 1000
      ? ` ${(Math.round(localizationItem?.cordinatesDiff) / 1000).toFixed(
          2
        )} km`
      : ` ${Math.round(localizationItem?.cordinatesDiff)} m`;
  useEffect(() => {
    extended && setExtended(false);
  }, [itemOffset]);
  return (
    <SingleItemInfo
      isDark={theme?.isDark}
      extended={extended}
      className={extended ? "extended" : null}
    >
      <WraperForSingleItem>
        {closestShop?.name && (
          <motion.div
            variants={divVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <div>
              {/* <SearchLocation style={styleForIcon} /> */}
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="search-location"
                className="svg-inline--fa fa-search-location fa-w-16"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                style={styleForIcon}
                width={30}
              >
                <path fill="currentColor" d={searchPath}></path>
              </svg>
              Dystans do <span>{closestShop?.name}</span> {distanseFromUser}
            </div>

            {option && !extended && (
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="ellipsis-v"
                className="svg-inline--fa fa-ellipsis-v fa-w-6"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 192 512"
                style={styleForDots}
                onClick={() => setExtended(!extended)}
                width={30}
              >
                <path fill="currentColor" d={dotsPath}></path>
              </svg>
            )}
            {option && extended && (
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="times"
                className="svg-inline--fa fa-times fa-w-11"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 352 512"
                style={styleForDots}
                onClick={() => setExtended(!extended)}
                width={30}
              >
                <path fill="currentColor" d={closePath}></path>
              </svg>
            )}
          </motion.div>
        )}
        {extended && (
          <motion.div
            variants={divVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <p>Ulica</p>
            <div>
              {closestShop?.street ? closestShop?.street : "Brak danych"}
            </div>
          </motion.div>
        )}
        {extended && (
          <motion.div
            variants={divVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <p>Numer</p>
            <div>
              {closestShop?.number ? closestShop?.number : "Brak danych"}
            </div>
          </motion.div>
        )}
        {extended && (
          <motion.div
            variants={divVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <p>Kod pocztowy</p>
            <div>
              {closestShop?.postCode ? closestShop?.postCode : "Brak danych"}
            </div>
          </motion.div>
        )}
        {extended && (
          <motion.div
            variants={divVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <p>Miasto</p>
            <div>{closestShop?.city ? closestShop?.city : "Brak danych"}</div>
          </motion.div>
        )}

        {extended && (
          <motion.div
            variants={divVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <p>Telefon</p>
            <div>
              {closestShop?.phone ? (
                <span>
                  <a
                    href={`tel:${closestShop?.phone}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {closestShop?.phone}
                    {/* <PhoneCall style={styleForIcon} /> */}
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="phone-square"
                      className="svg-inline--fa fa-phone-square fa-w-14"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      style={styleForIcon}
                      width={30}
                    >
                      <path fill="currentColor" d={phoneCallPath}></path>
                    </svg>
                  </a>{" "}
                </span>
              ) : (
                "Brak danych"
              )}
            </div>
          </motion.div>
        )}
        {extended && (
          <motion.div
            variants={divVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <p>Godziny otwarcia</p>
            <div>
              {closestShop?.openingHours?.length
                ? closestShop?.openingHours?.map((item, index) => (
                    <p key={index}>{item}</p>
                  ))
                : "Brak danych"}
            </div>
          </motion.div>
        )}
        {extended && (
          <motion.div
            variants={divVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <p>Geo pozycja</p>
            <div>
              lat: {closestShop?.position[0]} lng:
              {closestShop?.position[1]}
            </div>
          </motion.div>
        )}
      </WraperForSingleItem>
      {extended && (
        <ActionWraper>
          <div
            onClick={(e) => {
              shopUpdateOpen();
              setShopToUpdate(closestShop);
            }}
          >
            <SvgDisplay
              viewBox={pen.viewBox}
              d={pen.d}
              width={20}
              style={{ marginBottom: "5px" }}
              fill={
                theme?.isDark
                  ? Theme.light.primaryColor.white
                  : Theme.light.thirdColor.dark
              }
            />
            <p style={{ color: Theme.light.secondaryColor.green }}>Edytuj</p>
          </div>
          <div
            onClick={() => {
              alertify.confirm(
                "Potwierdź usunięcie",
                `Usuwasz sklep ${closestShop?.name}`,
                async function () {
                  deleteShop(closestShop, "shops/delete").then((confirm) => {
                    if (confirm) {
                      setUpdatetShopId(closestShop?._id);
                      alertify.success(`Usunięto sklep ${closestShop?.name}`);
                    } else {
                      alertify.set("notifier", "position", "bottom-left");
                      alertify.error("Coś poszło nie tak");
                    }
                  });
                },
                function () {
                  alertify.error("Anulowano");
                }
              );
            }}
          >
            <SvgDisplay
              viewBox={trash.viewBox}
              d={trash.d}
              width={20}
              style={{ marginBottom: "5px" }}
              fill={
                theme?.isDark
                  ? Theme.light.primaryColor.white
                  : Theme.light.thirdColor.dark
              }
            />
            <p style={{ color: Theme.light.fourthColor.red }}>Usuń</p>
          </div>
        </ActionWraper>
      )}
    </SingleItemInfo>
  );
};

export default SingleItemShop;
