export const brands = [
  "abc",
  "auchan",
  "carrefour",
  "castorama",
  "dino",
  "eurocash",
  "groszek",
  "kaufland",
  "leroy merlin",
  "lidl",
  "makro",
  "max elektro",
  "media expert",
  "media markt",
  "mycenter",
  "netto",
  "obi",
  "odido",
  "polomarket",
  "rossmann",
  "rtv euro agd",
  "selgros",
  "stokrotka",
  "ziko dermo",
];
