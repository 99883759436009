import CryptoJS from "crypto-js/core";
import aes from "crypto-js/aes";
const REACT_APP_SECRET_CRYPTO =
  "AAAAB3NzaC1yc2EAAAADAQABAAABAQCvLkRKAGqaeElT+7RIRjmYfbMr9+f5PXu7zh80kunlCvUzWxOGrpRgX8lII8QedhYjx6VKIi7wS67Z/TH+MJRQXMcT5M/189iEAQq99y/hHFiQaab94hGZ26x/LgbB1OKyx+JmKeJFTOiYRTNXUlM6pNqRYF7OIQ4C5c3N1CZJ6lxYnP9zkbnkWtHrr1i8jSAzwHx9eWYEWNoky7jJRT2ywtvlX6UjVsG52+npYcQ6t6bDDa54Kch0Q0AiBMjt8VanL89RWPU8V52f2mUhDYheQ74houeotYhjuqWxO3PFETH7RYKiwiWeAzuMzAHiDU4943mwS1Xk88CXk+yKpEWH";
const SECRET_KEY = REACT_APP_SECRET_CRYPTO;
const GRAPHQL_SECRET_KEY =
  "AAAAB3NzaC1yc2EAAAADAQABAAABAQCvLkRKAGqaeElT+7RIRjmYfbMr9+f5PXu7zh80kunlCvUzWxOGrpRgX8lII8QedhYjx6VKIi7wS67Z/TH+MJRQXMcT5M/189iEAQq99y/hHFiQaab94hGZ26x/LgbB1OKyx+JmKeJFTOiYRTNXUlM6pNqRYF7OIQ4C5c3N1CZJ6lxYnP9zkbnkWtHrr1i8jSAzwHx9eWYEWNoky7jJRT2ywtvlX6UjVsG52+npYcQ6t6bDDa54Kch0Q0AiBMjt8VanL89RWPU8V52f2mUhDYheQ74houeotYhjuqWxO3PFETH7RYKiwiWeAzuMzAHiDU4943mwS1Xk88CXk+yKpEWH";

export const encryptFrontData = (
  frontDataToEncrypt,
  setErorr,
  setStartFetchProces,
  describer
) => {
  try {
    const ciphertext = aes
      .encrypt(JSON.stringify(frontDataToEncrypt), SECRET_KEY)
      .toString();
    if (setStartFetchProces) {
      setStartFetchProces(true);
    }
    return ciphertext;
  } catch (error) {
    console.log(
      error,
      `Bład encryptowania danych - request front,preparing for fetch from ${describer}`
    );
    if (setStartFetchProces) {
      setStartFetchProces(false);
    }
    setErorr(
      `Bład encryptowania danych - request front,preparing for fetch from ${describer}`
    );
  }
};

export const decryptFrontData = (
  dataForDecrypt,
  setErorr,
  setStopFetchProces,
  describer
) => {
  try {
    const bytes = aes.decrypt(dataForDecrypt, SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.log(
      error,
      `Bład decryptowania danych - response front,inside fetch response from ${describer}`
    );
    setStopFetchProces(false);
    setErorr(
      `Bład decryptowania danych - response front,inside fetch response from ${describer}`
    );
  }
};

export const encryptFrontGraphQLData = (frontDataToEncrypt, describer) => {
  try {
    const data = JSON.stringify(frontDataToEncrypt);
    if (typeof data === "string") {
      const cipherText = CryptoJS.AES.encrypt(
        data,
        GRAPHQL_SECRET_KEY
      ).toString();

      return cipherText;
    } else {
      console.log("Data for graphQL encryption is not string type");
    }
  } catch (error) {
    console.log(
      error,
      `Bład encryptowania danych - request front,preparing for fetch from ${describer}`
    );
  }
};

export const encryptFrontGraphQLDataForApollo = (
  frontDataToEncrypt,
  describer
) => {
  try {
    const data = JSON.stringify(frontDataToEncrypt).replace(/</g, "\\u003c");
    if (typeof data === "string") {
      const cipherText = CryptoJS.AES.encrypt(
        data,
        GRAPHQL_SECRET_KEY
      ).toString();

      return cipherText;
    } else {
      console.log("Data for apollo encryption is not string type");
    }
  } catch (error) {
    console.log(
      error,
      `Bład encryptowania danych - request front,preparing data for apollo ${describer}`
    );
  }
};

export const decryptFrontGraphQLData = (dataForDecrypt, describer) => {
  try {
    const bytes = aes.decrypt(dataForDecrypt, GRAPHQL_SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.log(
      error,
      `Bład decryptowania danych - response front,inside fetch response from ${describer}`
    );
  }
};
