import { SvgDisplay } from "../tools/svgDisplay/SvgDisplay";
import { overTimeLimit } from "../assets/media/svgPath/svgPath";
import {
  SignUpConfirmWrapper,
  divVariants,
  imgVariants,
} from "./SignUpConfirmPage.style";
import logoStudioDark from "../assets/media/img/studio-cenniczek-logo-for-dark.png";
import { motion } from "framer-motion";
import history from "../utils/history";
import { Theme } from "../store/theme/theme";

const SingnUpOverLimitPage = () => {
  return (
    <SignUpConfirmWrapper
      variants={divVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <motion.img
        src={logoStudioDark}
        variants={imgVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        alt="logo studio.cenniczek.com"
        onClick={() => {
          history.push("/");
        }}
      />
      <SvgDisplay
        viewBox={overTimeLimit.viewBox}
        d={overTimeLimit.d}
        width={"20vh"}
      />
      <p>
        Niestety został przekroczony czas na potwierdzenie rejestracji.
        Skorzystaj z fromularza jeszcze raz
      </p>
      <div>
        <a
          href="/sign-up"
          alt="rejestracja"
          style={{ color: `${Theme.dark.secondaryColor.green}` }}
        >
          Rejestruj się
        </a>
      </div>
    </SignUpConfirmWrapper>
  );
};
export default SingnUpOverLimitPage;
