import { useEffect, useRef, useState } from "react";
import { ActionWrapper, FormWrapper, pVariants } from "./AddNewspaper.style";
import { motion, AnimatePresence } from "framer-motion";
import { Theme } from "../../store/theme/theme";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/default.css";
import { API_URL } from "../../constant/constant";
import {
  encryptFrontData,
  decryptFrontData,
} from "../../utils/Crypto/cryptoFront.controller";
import { Spinner } from "../../tools/spinner/Spinner";
import { brands } from "./brandsConstant";

const regNameMultiLng =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

const categoryOptions = [
  "gazetki markety",
  "gazetki media i agd",
  "gazetki dom i ogród",
  "gazetki zdrowie i uroda",
];

const categoryAlt = (category) => {
  const ind = categoryOptions.findIndex((item) => item === category);
  if (ind !== -1) {
    return String(ind + 1);
  } else return "";
};
const UploadNewspaper = ({ isDark, user }) => {
  const formRef = useRef();

  const [errorFromCrypto, setErrorFromCrypto] = useState("");
  // errors
  const [nameError, setNameError] = useState("");
  const [altNameError, setAltNameError] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [startDuringTimeError, setStartDuringTimeError] = useState("");
  const [stopDuringTimeError, setStopDuringTimeError] = useState("");
  const [publicationDateError, setPublicationDateError] = useState("");
  const [fileError, setFileError] = useState("");

  // values
  const [name, setName] = useState("");
  const [altName, setAltName] = useState("");
  const [category, setCategory] = useState("gazetki markety");
  const [startDuringTime, setStartDuringTime] = useState("");
  const [stopDuringTime, setStopDuringTime] = useState("");
  const [publicationTime, setPublicationTime] = useState("");
  const [file, setFile] = useState("");
  //
  const [procesing, setProcesing] = useState(false);
  const clear = () => {
    // setName("");
    setAltName("");
    // setCategory("");
    setStartDuringTime("");
    setStopDuringTime("");
    setPublicationTime("");
    formRef?.current?.reset();
  };
  const handleUpload = async (e) => {
    e.preventDefault();
    if (!name) setNameError("wpisz nazwe gazetki");
    if (!altName) setAltNameError("wpisz alternatywną nazwe gazetki");
    if (!category) setCategoryError("wybierz categorie");
    if (!startDuringTime)
      setStartDuringTimeError("wybierz date rozpoczęcia gazetki");
    if (!stopDuringTime)
      setStopDuringTimeError("wybierz date zakńczenia gazetki");
    if (!publicationTime)
      setPublicationDateError("wybierz date publikacji gazetki");
    if (!file?.name) setFileError("wybierz plik");

    if (
      !nameError &&
      !altNameError &&
      !categoryError &&
      !startDuringTimeError &&
      !stopDuringTimeError &&
      !publicationDateError &&
      !fileError &&
      name &&
      altName &&
      category &&
      startDuringTime &&
      stopDuringTime &&
      publicationTime &&
      file?.name
    ) {
      setProcesing(true);
      const [startYear, startMonth, startDay] = startDuringTime.split("-");
      const [stoptYear, stopMonth, stopDay] = stopDuringTime.split("-");
      const [pubYear, pubMonth, pubDay] = publicationTime.split("-");

      console.log("ALL GOOD");
      const categoryIndex = categoryAlt(category);

      const reader = new FileReader();

      reader.onload = async (event) => {
        const fileData = event.target.result;
        try {
          const response = await fetch(
            `${API_URL}/api-studio/uploadNewspaperForCenniczek`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              credentials: "include",
              body: JSON.stringify({
                file: fileData,
                name,
                altName,
                startDuringTime: `${startDay}.${startMonth}.${startYear}`,
                stopDuringTime: `${stopDay}.${stopMonth}.${stoptYear}`,
                publicationTime: `${pubDay}.${pubMonth}.${pubYear}`,
                categoryIndex,
              }),
            }
          )
            .then((response) => response.json())
            .then((result) => {
              return decryptFrontData(
                result.data,
                setErrorFromCrypto,
                null,
                "UploadNewspaper.js-cenniczek"
              );
            })
            .then((data) => {
              setProcesing(false);
              if (data?.ok) {
                alertify.set("notifier", "position", "bottom-left");
                alertify.success(
                  `Gazetka przesłana pomyślnie - oczekuje w kolejce na rozpoznawanie, zajmie to ok 1h`
                );

                clear();
              } else {
                alertify.set("notifier", "position", "top-center");
                alertify.alert(data.error);
                clear();
              }
            });

          // Handle response from the server as needed
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      };

      reader.readAsDataURL(file);
    } else {
      console.log("SOMETHING is SHEET");
      console.log({
        nameError,
        altNameError,
        categoryError,
        startDuringTimeError,
        stopDuringTimeError,
        publicationDateError,
        fileError,
        name,
        altName,
        category,
        startDuringTime,
        stopDuringTime,
        publicationTime,
        file,
      });
    }
  };

  const handleName = (value) => {
    console.log(value, "value");
    setName(value);
    if (value.length >= 3 && value.length < 33) {
      setNameError("");
    }
    if (!brands.includes(value)) {
      setNameError("Wybrana marka jest niepoprawna");
    }
    if (
      regNameMultiLng.test(value) &&
      value.length >= 3 &&
      value.length <= 33
    ) {
      setNameError("");
    }
  };
  const handleAltName = (value) => {
    setAltName(value);
    if (value.length >= 3 && value.length < 33) {
      setAltNameError("");
    }
    if (!value) {
      setAltNameError("Wpisz proszę nazwe alternatywna gazetki");
    }
    if (value && value.length < 3) {
      setAltNameError("Wpisana nazwa jest zbyt krótka");
    }
    if (value.length > 33) {
      setAltNameError("Wpisana nazwa jest zbyt długa");
    }
    if (!regNameMultiLng.test(value)) {
      setAltNameError("Wpisz poprawnie nazwe alternatywna [a-z]");
    }
    if (
      regNameMultiLng.test(value) &&
      value.length >= 3 &&
      value.length <= 33
    ) {
      setAltNameError("");
    }
  };
  const handleCategory = (value) => {
    setCategory(value);
    if (!categoryOptions.includes(value)) {
      setCategoryError("Wybrana kategoria jest niepoprawna");
    }

    if (
      regNameMultiLng.test(value) &&
      value.length >= 3 &&
      value.length <= 33
    ) {
      setCategoryError("");
    }
  };
  const handleStartDuringTime = (value) => {
    const date = new Date(value);
    setStartDuringTime(value);
    if (date instanceof Date) {
      setStartDuringTimeError("");
    } else {
      setStartDuringTimeError("niepoprawny format daty");
    }
  };
  const handleStopDuringTime = (value) => {
    const date = new Date(value);
    const dateInMili = +date;
    const startInMil = +new Date(startDuringTime);
    setStopDuringTime(value);
    if (date instanceof Date === false) {
      setStopDuringTimeError("niepoprawny format daty");
    } else if (dateInMili < startInMil && typeof startInMil === "number") {
      setStopDuringTimeError(
        "Data końcowa nie może być ustawiona przed jej rozpoczęciem"
      );
    } else {
      setStopDuringTimeError("");
    }
  };
  const handlePublicationDate = (value) => {
    // const [year, month, day] = value.split("-");
    const date = new Date(value);
    setPublicationTime(value);
    if (date instanceof Date) {
      setPublicationDateError("");
    } else {
      setPublicationDateError("niepoprawny format daty");
    }
  };
  const handleFileChange = (value) => {
    if (value[0]) {
      const extensionFile = value[0].name.slice(
        value[0].name.length - 3,
        value[0].name.length
      );
      if (extensionFile !== "pdf") {
        setFileError("niepoprawny format pliku, tylko pdf");
      } else {
        setFileError("");
        setFile(value[0]);
      }
    }
  };
  useEffect(() => {
    setCategory(categoryOptions[0]);
  }, []);
  useEffect(() => {
    user?.represents !== "cenniczek" && setName(user?.represents);
  }, [user?.represents]);
  useEffect(() => {
    return () => {
      clear();
    };
  }, []);
  return (
    <FormWrapper isDark={isDark}>
      <form onSubmit={(e) => handleUpload(e)} ref={formRef}>
        <div>
          <p>Marka gazetki</p>
          {user.represents === "cenniczek" ? (
            <>
              <select
                type="text"
                name="name"
                placeholder="nazwa"
                value={name}
                onChange={(e) => handleName(e.target.value)}
              >
                {brands?.map((option) => {
                  return (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  );
                })}
              </select>
              <AnimatePresence>
                {nameError && (
                  <motion.p
                    variants={pVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    style={{
                      backgroundColor: `${Theme.light.fourthColor.red}`,
                      padding: "5px",
                    }}
                  >
                    {nameError}
                  </motion.p>
                )}
              </AnimatePresence>
            </>
          ) : (
            <>
              <input
                type="text"
                name="name"
                placeholder="nazwa"
                value={user?.represents}
                readOnly
                // onChange={(e) => handleName(e.target.value)}
              />
              <AnimatePresence>
                {nameError && (
                  <motion.p
                    variants={pVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    style={{
                      backgroundColor: `${Theme.light.fourthColor.red}`,
                      padding: "5px",
                    }}
                  >
                    {nameError}
                  </motion.p>
                )}
              </AnimatePresence>
            </>
          )}
        </div>
        <div>
          <p>Alternatywna nazwa</p>
          <input
            type="text"
            name="alterantiveName"
            placeholder="altNazwa"
            value={altName}
            onChange={(e) => handleAltName(e.target.value)}
          />
          <AnimatePresence>
            {altNameError && (
              <motion.p
                variants={pVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{
                  backgroundColor: `${Theme.light.fourthColor.red}`,
                  padding: "5px",
                }}
              >
                {altNameError}
              </motion.p>
            )}
          </AnimatePresence>
        </div>
        <div>
          <p>Kategoria gazetki</p>
          <select
            name="alternativeName"
            placeholder="kategoria"
            value={category}
            onChange={(e) => handleCategory(e.target.value)}
          >
            {categoryOptions.map((option) => {
              return (
                <option key={option} value={option}>
                  {option}
                </option>
              );
            })}
          </select>
          <AnimatePresence>
            {categoryError && (
              <motion.p
                variants={pVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{
                  backgroundColor: `${Theme.light.fourthColor.red}`,
                  padding: "5px",
                }}
              >
                {categoryError}
              </motion.p>
            )}
          </AnimatePresence>
        </div>
        <div>
          <p>Data rozpoczęcia</p>
          <input
            type="date"
            name="startDuring"
            placeholder="start gazetki"
            value={startDuringTime}
            onChange={(e) => handleStartDuringTime(e.target.value)}
          />
          <AnimatePresence>
            {startDuringTimeError && (
              <motion.p
                variants={pVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{
                  backgroundColor: `${Theme.light.fourthColor.red}`,
                  padding: "5px",
                }}
              >
                {startDuringTimeError}
              </motion.p>
            )}
          </AnimatePresence>
        </div>
        <div>
          <p>Data zakończenia</p>
          <input
            type="date"
            name="endDuring"
            placeholder="koniec gazetki"
            value={stopDuringTime}
            onChange={(e) => handleStopDuringTime(e.target.value)}
          />
          <AnimatePresence>
            {stopDuringTimeError && (
              <motion.p
                variants={pVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{
                  backgroundColor: `${Theme.light.fourthColor.red}`,
                  padding: "5px",
                }}
              >
                {stopDuringTimeError}
              </motion.p>
            )}
          </AnimatePresence>
        </div>
        <div>
          <p>Data publikacji</p>
          <input
            type="date"
            name="publicateddate"
            placeholder="start publikacji"
            value={publicationTime}
            onChange={(e) => handlePublicationDate(e.target.value)}
          />
          <AnimatePresence>
            {publicationDateError && (
              <motion.p
                variants={pVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{
                  backgroundColor: `${Theme.light.fourthColor.red}`,
                  padding: "5px",
                }}
              >
                {publicationDateError}
              </motion.p>
            )}
          </AnimatePresence>
        </div>
        <div>
          <input
            type="file"
            name="fileUpload"
            placeholder="załaduj plik"
            // fileName={file}
            onChange={(e) => handleFileChange(e.target.files)}
          />
          <AnimatePresence>
            {fileError && (
              <motion.p
                variants={pVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{
                  backgroundColor: `${Theme.light.fourthColor.red}`,
                  padding: "5px",
                }}
              >
                {fileError}
              </motion.p>
            )}
          </AnimatePresence>
          <AnimatePresence>
            {errorFromCrypto && (
              <motion.p
                variants={pVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{
                  backgroundColor: `${Theme.light.fourthColor.red}`,
                  padding: "5px",
                }}
              >
                {errorFromCrypto}
              </motion.p>
            )}
          </AnimatePresence>
        </div>

        {procesing ? (
          <ActionWrapper>
            <Spinner />
          </ActionWrapper>
        ) : (
          <button type="submit">Wyślij</button>
        )}
      </form>
    </FormWrapper>
  );
};
export default UploadNewspaper;
