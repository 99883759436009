import { convertStrToDate } from "../../../helpers/convertStrToDate";
export const sortBySelected = (
  correctData,
  filterSortType,
  topDealUpdatedTimestamp
) => {
  let copyArr = [];

  if (correctData?.length) {
    copyArr = [...correctData];
  } else {
    return [];
  }
  switch (filterSortType.name) {
    case "recomended":
      return copyArr.sort((a, b) =>
        a.popular > b.popular ? -1 : a.popular < b.popular ? 1 : 0
      );
    case "newest":
      return copyArr.sort((a, b) => {
        const aEnd = convertStrToDate(a.startDuringTime);
        const bEnd = convertStrToDate(b.startDuringTime);
        return bEnd - aEnd;
      });
    case "oldest":
      return copyArr.sort((a, b) => {
        const aEnd = convertStrToDate(a.startDuringTime);
        const bEnd = convertStrToDate(b.startDuringTime);
        return bEnd < aEnd ? 1 : -1;
      });

    case "alphabet1":
      return copyArr.sort((a, b) => {
        const nameA = a.name;
        const nameB = b.name;
        return nameA < nameB ? -1 : 1;
      });
    case "alphabet2":
      return copyArr.sort((a, b) => {
        const nameA = a.name;
        const nameB = b.name;
        return nameB > nameA ? 1 : -1;
      });
    default:
      return copyArr;
  }
};
