import React, { useEffect } from "react";
import { connect } from "react-redux";
import controller from "../../helpers/usePermissionController";
import Contact from "../contact/Contact";

const ContactUs = ({ user, theme }) => {
  useEffect(() => {
    controller(user);
  }, []);
  return (
    <>
      <p>Kontakt</p>
      <Contact user={user} isDark={theme?.isDark} />
    </>
  );
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.user,
});
export default connect(mapStateToProps, {})(ContactUs);
