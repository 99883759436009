export const Theme = {
  light: {
    primaryColor: {
      white: "rgba(253, 255, 252, 1)",
    },
    secondaryColor: {
      green: "rgba(46, 196, 182, 1)",
    },
    thirdColor: {
      dark: "rgba(49, 47, 47, 1)",
    },
    fourthColor: {
      red: "rgba(190, 30, 45, 1)",
    },
    fiveColor: {
      white: "rgba(253, 255, 252, .6)",
    },
    sixColor: {
      white: "rgba(248, 243, 243, 1)",
    },
    sevenColor: {
      gray: "rgb(172,172,170)",
    },
    eightColor: {
      ligthGray: "rgba(100, 100, 111, 0.2)",
    },
    nineColor: {
      white: "rgba(253, 255, 252, .2)",
    },
  },
  dark: {
    primaryColor: {
      dark: "rgba(49, 47, 47, 1)",
    },
    secondaryColor: {
      green: "rgba(46, 196, 182, 1)",
    },
    thirdColor: {
      white: "rgba(253, 255, 252, 1)",
    },
    fourthColor: {
      red: "rgba(190, 30, 45, 1)",
    },
    fiveColor: {
      white: "rgba(253, 255, 252, 0.3)",
    },
    sixColor: {
      dark: "rgba(49, 47, 47, .6)",
    },
    sevenColor: {
      dark: "rgba(49, 47, 47, .1)",
    },
    eightColor: {
      dark: "rgba(34, 25,25, 1)",
    },
    nineColor: {
      dark: "rgba(49, 47, 47, .2)",
    },
  },
  breakPoint: {
    desktopL: "2560",
    desktop: "1200",
    tablet: "992",
    mobileL: "768",
    mobileM: "576",
    mobileS: "360",
  },
  device: {
    desktopL: `(max-width:2559.98px)`,
    desktopM: `(max-width:1368.98px)`,
    desktop: `(max-width:1199.98px)`,
    tablet: `(max-width:991.98px)`,
    mobileL: `(max-width:767.98px)`,
    mobileM: `(max-width:575.98px)`,
    mobileS: `(max-width:374.98px)`,
    mobileXS: `(max-width:299.98px)`,
  },
  size: {
    h1: "20px",
    fontSizeMobileL: "16px",
    fontSizeMobileM: "14px",
    fontSizeMobileS: "10px",
    fontSizeMobileXS: "10px",
  },
};
// npx pwa-asset-generator public/cenniczek-icon-192.png public -m public/manifest.json --padding "calc(50vh - 25%) calc(50vw - 25%)" -b "linear-gradient(315deg, #e8dbfc 0%, #312f2f 74%)" -q 100
// -i public/asset-generator-changes.html --favicon
