import React, { useState, useEffect, useRef } from "react";

import { motion, AnimatePresence } from "framer-motion";
import { Spinner } from "../../../tools/spinner/Spinner";
import { animationInputVariants } from "../style";
const loginOptionsVariants = {
  hidden: {
    opacity: 0,
    // scale:0,
    y: -20,
  },
  visible: {
    opacity: 1,
    // scale:1,
    y: 0,
    // transformOrigin:"bottom",
    transition: { duration: 0.3 },
  },
  exit: {
    opacity: 0,
    // scale:0,
    y: -20,
  },
};

const SelectContentForBrand = ({
  item,
  setBrandOptionsActive,
  setShopItem,
  shopItem,
}) => {
  const optionName = item;
  const [refToLi, setRefToLi] = useState(null);
  const litItem = useRef(null);

  if (refToLi) {
    refToLi.addEventListener("click", () => {
      setBrandOptionsActive(false);
      setShopItem({ ...shopItem, name: item?.name });
    });
  }

  return (
    <motion.li
      variants={animationInputVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      ref={(current) => {
        litItem.current = current;
        setRefToLi(current);
      }}
    >
      {item.name}
    </motion.li>
  );
};

export const SelectBrand = ({
  setBrandOptionsActive,
  brands,
  setShopItem,
  shopItem,
}) => {
  useEffect(() => {
    return () => {
      brands = [];
    };
  });

  if (brands) {
    const sortedData = brands?.sort((a, b) => {
      const nameA = a.name;
      const nameB = b.name;
      return nameA < nameB ? -1 : 1;
    });

    const createSelectOptions = sortedData.map((item) => (
      <SelectContentForBrand
        key={item?._id}
        item={item}
        setBrandOptionsActive={setBrandOptionsActive}
        setShopItem={setShopItem}
        shopItem={shopItem}
      />
    ));
    return <ul>{createSelectOptions}</ul>;
  } else return <Spinner />;
};
