import { SvgDisplay } from "../tools/svgDisplay/SvgDisplay";
import { handRegular } from "../assets/media/svgPath/svgPath";
import {
  SignUpConfirmWrapper,
  divVariants,
  imgVariants,
} from "./SignUpConfirmPage.style";
import logoStudioDark from "../assets/media/img/studio-cenniczek-logo-for-dark.png";
import { motion } from "framer-motion";
import history from "../utils/history";

const SingUpConfirmPage = ({ earlierConfirm }) => {
  return (
    <SignUpConfirmWrapper
      variants={divVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <motion.img
        src={logoStudioDark}
        variants={imgVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        alt="logo studio.cenniczek.com"
        onClick={() => {
          history.push("/");
        }}
      />
      <SvgDisplay
        viewBox={handRegular.viewBox}
        d={handRegular.d}
        width={"30vh"}
      />

      {earlierConfirm ? (
        <p>
          Weryfikacja emailu potwierdzona wcześniej, zostaniesz poinformowany o
          aktywacji konta w osobny emailu. Dziękujemy za cierpliwość
        </p>
      ) : (
        <p>
          Pozostał jeszcze jeden krok weryfikacji konta, zostaniesz
          poinformowany o aktywacji konta w osobny emailu. Dziękujemy za
          cierpliwość.
        </p>
      )}
    </SignUpConfirmWrapper>
  );
};
export default SingUpConfirmPage;
