import styled from "styled-components";
import { Theme } from "../../store/theme/theme";

export const AvatarWrapper = styled.div`
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media${Theme.device.mobileL} {
    margin: 8px;
  }
`;
export const ImgWrapper = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.eightColor.dark : Theme.light.fiveColor.white};
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  box-shadow: 1px 1px 7px
    ${({ isDark }) =>
      isDark ? Theme.dark.eightColor.dark : Theme.light.fiveColor.white};
  & > img {
    width: ${({ avatar }) => (avatar ? "100%" : "30px")};
  }
`;
