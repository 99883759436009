import useNewspaperControllerForContractor from "./useNewspaperControllerForContractor";
import { Spinner } from "../../tools/spinner/Spinner";
import { SingleItem } from "./SingleItem";
import sortOption from "../../helpers/sortOption";
import { SectionSort } from "../sectionSort/SectionSort";
import { WrapperForContractorNewspapers } from "./Newspaper.Style";

const NewspapersContractor = ({ user, theme, select, addProduct }) => {
  const { data, loading, errorFromCrypto } =
    useNewspaperControllerForContractor(user);
  let trueData;
  let truePre;

  const filterSortOption = select?.sort.filter(
    (item) => item.active === true
  )[0];

  if (data) {
    trueData = sortOption(data?.newspapers, filterSortOption);
    truePre = sortOption(data?.preBooks, filterSortOption);
  }

  return (
    <>
      {loading && <Spinner />}
      {errorFromCrypto && <p>{errorFromCrypto}</p>}

      <SectionSort
        isDark={theme.isDark}
        isBrandActive={select.options[1].active}
        isBrandSelected={select.options[1].selectedItemID}
        isCategoryActive={select.options[0].active}
      />
      <WrapperForContractorNewspapers
        isDark={theme?.isDark}
        center={true}
        length={truePre?.length}
      >
        {!addProduct &&
          truePre?.map((item, index) => {
            return (
              <SingleItem
                key={item?._id}
                item={item}
                isDark={theme.isDark}
                index={index}
                isMobile={theme?.isMobile}
                pre={true}
              />
            );
          })}
      </WrapperForContractorNewspapers>
      <WrapperForContractorNewspapers
        isDark={theme?.isDark}
        length={trueData?.length}
        center={truePre?.length ? true : false}
      >
        {trueData
          ?.sort((a, b) => (a?.txtRecognizing ? -1 : 1))
          ?.map((item, index) => {
            return (
              <SingleItem
                key={item?._id}
                item={item}
                isDark={theme.isDark}
                index={index}
                isMobile={theme?.isMobile}
                addProduct={addProduct}
              />
            );
          })}
      </WrapperForContractorNewspapers>
    </>
  );
};
export default NewspapersContractor;
