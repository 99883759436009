import React, { useState } from "react";
import {
  MainContent,
  MailIconStyle,
  HeadLine,
  FormWrapper,
  eyeStyle,
} from "./styleForUserRegisterSection";
import { Theme } from "../../store/theme/theme";
import { motion, AnimatePresence } from "framer-motion";
import ReCAPTCHA from "react-google-recaptcha";
import logoIcon from "../../assets/media/svg/cenniczek_svg_logo_tiny.svg";
import history from "../../utils/history";
import { recaptchaSiteKey } from "../../constant/constant";
// const envePath =
//   "M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z";
const envePath =
  "M352 128c0 70.7-57.3 128-128 128s-128-57.3-128-128S153.3 0 224 0s128 57.3 128 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM504 312V248H440c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V136c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H552v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z";
const eyePath =
  "M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM432 256c0 79.5-64.5 144-144 144s-144-64.5-144-144s64.5-144 144-144s144 64.5 144 144zM288 192c0 35.3-28.7 64-64 64c-11.5 0-22.3-3-31.6-8.4c-.2 2.8-.4 5.5-.4 8.4c0 53 43 96 96 96s96-43 96-96s-43-96-96-96c-2.8 0-5.6 .1-8.4 .4c5.3 9.3 8.4 20.1 8.4 31.6z";
const eyeSlashPath =
  "M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L525.6 386.7c39.6-40.6 66.4-86.1 79.9-118.4c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C465.5 68.8 400.8 32 320 32c-68.2 0-125 26.3-169.3 60.8L38.8 5.1zM223.1 149.5C248.6 126.2 282.7 112 320 112c79.5 0 144 64.5 144 144c0 24.9-6.3 48.3-17.4 68.7L408 294.5c5.2-11.8 8-24.8 8-38.5c0-53-43-96-96-96c-2.8 0-5.6 .1-8.4 .4c5.3 9.3 8.4 20.1 8.4 31.6c0 10.2-2.4 19.8-6.6 28.3l-90.3-70.8zm223.1 298L373 389.9c-16.4 6.5-34.3 10.1-53 10.1c-79.5 0-144-64.5-144-144c0-6.9 .5-13.6 1.4-20.2L83.1 161.5C60.3 191.2 44 220.8 34.5 243.7c-3.3 7.9-3.3 16.7 0 24.6c14.9 35.7 46.2 87.7 93 131.1C174.5 443.2 239.2 480 320 480c47.8 0 89.9-12.9 126.2-32.5z";
const pVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export const BeforeValidatione = ({ validate, setBodyEmail }) => {
  const recaptchaRef = React.createRef();
  const [human, setHuman] = useState(false);
  const [inputTextValue, setInputTextValue] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const [emailError, setEmailError] = useState("");

  // const regName = /^[a-zA-Z '.-]*$/i
  const regNameMultiLng =
    /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
  const regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const onChange = () => {
    setHuman(true);
    console.log("recaptcha resolved");
  };

  const emailValidationHandle = (email) => {
    setInputTextValue(email);
    if (!email) {
      setEmailError("Wpisz proszę swój e-mail");
    }
    if (email && !regEmail.test(email)) {
      setEmailError("Wpisany e-mail nie jest poprawny");
    }
    if (regEmail.test(email)) {
      setEmailError("");
      setValidEmail(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!human && recaptchaRef.current) {
      recaptchaRef.current.reset();
      // recaptchaRef.current.execute()
    }

    if (!inputTextValue) {
      setEmailError("Wpisz proszę swój e-mail");
    }

    if (validEmail) {
      setBodyEmail({
        email: inputTextValue,
      });
      validate(true);
    }
  };

  return (
    <MainContent layout>
      <HeadLine>
        <img
          src={logoIcon}
          alt="logo-icon"
          onClick={() => {
            history.push("/");
          }}
        />
        <p>Studio.Cenniczek.com</p>
        <div>
          {/* <MailIcon style={MailIconStyle} /> */}

          <p>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="envelope"
              className="svg-inline--fa fa-envelope fa-w-16"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
              style={MailIconStyle}
              width={130}
            >
              <path fill="currentColor" d={envePath}></path>
            </svg>
          </p>
        </div>
      </HeadLine>
      <FormWrapper>
        <h5>Formularz resetu hasła</h5>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="twój adres e-mail"
            name="email"
            value={inputTextValue}
            onChange={(e) => emailValidationHandle(e.target.value)}
          />
          <AnimatePresence>
            {emailError && (
              <motion.p
                variants={pVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{
                  backgroundColor: `${Theme.light.fourthColor.red}`,
                  padding: "5px",
                }}
              >
                {emailError}
              </motion.p>
            )}
          </AnimatePresence>
          <div>
            <a
              href="/"
              alt="login"
              style={{ color: `${Theme.dark.thirdColor.white}` }}
            >
              Zaloguj się
            </a>
          </div>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={recaptchaSiteKey}
            onChange={onChange}
            size={"normal"}
            hl={"pl"}
            theme={"dark"}
            style={{ width: "300px" }}
          />
          <motion.button whileTap={{ scale: 0.95 }} type="submit">
            Resetuj hasło
          </motion.button>
        </form>
      </FormWrapper>
    </MainContent>
  );
};
