import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/default.css";
import { API_URL } from "../../constant/constant";
import {
  encryptFrontData,
  decryptFrontData,
} from "../../utils/Crypto/cryptoFront.controller";

export const fetchForChangeUserName = (
  user,
  nameValue,
  setSaveBtnVisible,
  setOptionsActive,
  userSignInService
) => {
  // console.log(topDealsItem, "update");
  const frontDataToEncrypt = {
    user,
    nameValue,
  };
  // encrypt data for fetch
  const ciphertext = encryptFrontData(
    frontDataToEncrypt,
    null,
    null,
    "Modal.js"
  );
  fetch(`${API_URL}/api-studio/settings/userUpdate`, {
    method: "POST",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      data: ciphertext,
    }),
  })
    .then((response) => response.json())
    .then((result) => {
      // decrypt data from response
      return decryptFrontData(
        result.data,
        null,
        null,
        "fetchForChangeUserName.js"
      );
    })
    .then((data) => {
      if (!data.ok) {
        alertify.set("notifier", "position", "bottom-left");
        alertify.error(`Przepraszamy coś poszło nie po naszej
                          myśli,${data.error}`);
      } else {
        alertify.set("notifier", "position", "bottom-left");
        alertify.success(`Aktualizacja przeszła pomyślnie`);
        setSaveBtnVisible(false);
        setOptionsActive(false);
        const nameControlled = `${data?.user?.firstName
          .charAt(0)
          .toUpperCase()}${data?.user?.firstName?.slice(1)}`;
        const toLogin = {
          userLoged: true,
          email: data?.user?.email,
          name: nameControlled,
          represents: data?.user?.represents,
          interests: data?.user?.interests,
        };
        userSignInService(toLogin);
        // console.log(data?.updateTopDeals, "ModalUpdate");
      }
    })
    .catch((err) => console.log(err));
};

export const fetchForChangeUserAvatar = (
  user,
  nameValue,
  avatarValue,
  setSaveBtnAvatarVisible,
  setOptionsAvatarActive,
  userSignInService
) => {
  // console.log(topDealsItem, "update");
  const frontDataToEncrypt = {
    user,
    nameValue,
    avatarValue,
  };
  // encrypt data for fetch
  const ciphertext = encryptFrontData(
    frontDataToEncrypt,
    null,
    null,
    "Modal.js"
  );
  fetch(`${API_URL}/api-studio/settings/userUpdate`, {
    method: "POST",
    cache: "no-cache",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      data: ciphertext,
    }),
  })
    .then((response) => response.json())
    .then((result) => {
      // decrypt data from response
      return decryptFrontData(
        result.data,
        null,
        null,
        "fetchForChangeUserAvatar.js"
      );
    })
    .then((data) => {
      if (!data.ok) {
        alertify.set("notifier", "position", "bottom-left");
        alertify.error(`Przepraszamy coś poszło nie po naszej
                          myśli,${data.error}`);
      } else {
        alertify.set("notifier", "position", "bottom-left");
        alertify.success(`Aktualizacja przeszła pomyślnie`);
        setSaveBtnAvatarVisible(false);
        setOptionsAvatarActive(false);
        const nameControlled = `${data?.user?.firstName
          .charAt(0)
          .toUpperCase()}${data?.user?.firstName?.slice(1)}`;
        console.log(data);
        const toLogin = {
          userLoged: true,
          email: data?.user?.email,
          avatar: data?.user?.avatar,
          name: nameControlled,
          represents: data?.user?.represents,
          interests: data?.user?.interests,
        };
        userSignInService(toLogin);
        // console.log(data?.updateTopDeals, "ModalUpdate");
      }
    })
    .catch((err) => console.log(err));
};
