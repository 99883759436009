import { useState } from "react";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/default.css";
import { API_URL } from "../constant/constant";
import {
  decryptFrontData,
  encryptFrontData,
} from "../utils/Crypto/cryptoFront.controller";

const deleteTopDealsItem = async (item, endPoint) => {
  //   const [procesing, setProcesing] = useState(false);
  //   const [confirmation, setConfirmation] = useState(false);
  //   const [errorFromCrypto, setErrorFromCrypto] = useState("");

  // setProcesing(true);
  const frontDataToEncrypt = { item };
  const ciphertext = encryptFrontData(
    frontDataToEncrypt,
    null,
    null,
    `deleteTopDealsItem-${endPoint}`
  );
  return fetch(`${API_URL}/api-studio/${endPoint}`, {
    method: "POST",
    body: JSON.stringify({
      data: ciphertext,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
  })
    .then((response) => response.json())
    .then((result) => {
      return decryptFrontData(
        result.data,
        null,
        null,
        `deleteTopDealsItem-${endPoint}`
      );
    })
    .then((data) => {
      if (!data?.ok) {
        //   alertify.set("notifier", "position", "top-center");
        alertify.alert("Błąd usuwania", "Cos poszło nie tak", function () {
          alertify.success("Ok");
        });
        return false;
      }
      return true;
    })
    .catch((err) => {
      alertify.set("notifier", "position", "top-center");
      alertify.alert("Błąd usuwania", err);
      return false;
      //   history.push("/");
    });
};
export default deleteTopDealsItem;
