import {
  MARK,
  SELECT_ITEM,
  SORT_TYPE,
  SORT_DEALS_TYPE,
  NEWSPAPER_ID,
  TOP_DEALS_SELECT,
  CLEAR_TOP_DEALS,
  SELECT_PAGE_FROM_DEAL,
  CLEAR_SELECT_PAGE_FROM_DEAL,
  SET_TARGET_CORDINATES,
  CLEAR_TARGET_CORDINATES,
  SET_CURRENT_PAGE,
  SELECT_ITEM_FROM_SEARCH,
  ADD_TO_SUGESTED_NEWSPAPERS,
  REMOVE_ONE_FROM_SUGESTED_NEWSPAPERS,
  CLEAR_SUGESTED_NEWSPAPERS,
  CHANGE_DATA_ANALYS_PERIOD,
  TOP_DEALS_ITEM_TO_UPDATE,
  SHOP_TO_UPDATE,
} from "../types/type";

const INITIAL_SELECT_STATE = {
  dataAnalysisPeriod: localStorage.getItem("dataPeriod")
    ? JSON.parse(localStorage.getItem("dataPeriod"))
    : 14,
  options: [
    {
      name: "kategorie",
      active: true,
      selectedItemID: "",
      valueName: "",
    },
    {
      name: "sklepy",
      active: false,
      selectedItemID: "",
      valueName: "",
    },
    {
      name: "okolica",
      active: false,
      selectedItemID: "",
      valueName: "",
    },
  ],
  topDealsSelected: {
    id: "",
    name: "",
    categoryProductId: null,
    imgUrl: "",
  },
  sort: [
    { name: "recomended", value: "polecane", active: false },
    { name: "newest", value: "najnowsze", active: true },
    { name: "oldest", value: "najstarsze", active: false },
    { name: "alphabet1", value: "a-z", active: false },
    { name: "alphabet2", value: "z-a", active: false },
  ],
  sortDeals: [
    { name: "recomended", value: "polecane", active: true },
    { name: "newest", value: "najnowsze", active: false },
    { name: "oldest", value: "najstarsze", active: false },
    { name: "alphabet1", value: "a-z", active: false },
    { name: "alphabet2", value: "z-a", active: false },
  ],
  newspaperId:
    localStorage && localStorage.getItem("newsPaperId")
      ? localStorage.getItem("newsPaperId")
      : "",
  selectedPage: "",
  targetCordinates: {
    xSart: "",
    yStart: "",
    xEnd: "",
    yEnd: "",
  },
  currentPage: "",
  sugestedNewspapers: [],
  topDealsItemToUpdate: null,
  shopToUpdate: null,
};

export const selectReducer = (state = INITIAL_SELECT_STATE, actions) => {
  switch (actions.type) {
    case MARK:
      // state.options.map((arr) => arr.name === actions.item ? arr.active = true : arr.active = false)
      state.options.map((arr) => {
        if (arr.name === actions.item) {
          arr.active = true;
          arr.selectedItemID = "";
          arr.valueName = "";
        } else {
          arr.active = false;
          arr.selectedItemID = "";
          arr.valueName = "";
        }
        return null;
      });
      return {
        ...state,
      };

    case SELECT_ITEM:
      state.options.map((option) => {
        if (option.active) {
          option.selectedItemID = actions.item.id;
          option.valueName = actions.item.name;
          // state.newspaperId = "";
        } else if (!option.active) {
          option.selectedItemID = "";
          option.valueName = "";
        }

        return null;
      });
      return {
        ...state,
      };

    case SELECT_ITEM_FROM_SEARCH:
      state.options.map((option) => {
        if (option.active) {
          option.selectedItemID = actions.item._id;
          option.valueName = actions.item.name;
          // state.newspaperId = "";
        } else if (!option.active) {
          option.selectedItemID = "";
          option.valueName = "";
        }

        return null;
      });
      return {
        ...state,
      };

    case SORT_TYPE:
      state.sort.map((sort) => {
        if (sort.value === actions.item.value) {
          sort.active = true;
        } else {
          sort.active = false;
        }
        return null;
      });
      return { ...state };

    case TOP_DEALS_SELECT:
      state.topDealsSelected = {
        id: actions.item.id,
        name: actions.item.name,
        categoryProductId: actions.item.categoryProductId,
        imgUrl: actions.item.imgUrl,
      };
      return { ...state };
    case CLEAR_TOP_DEALS:
      state.topDealsSelected = {
        id: "",
        name: "",
        categoryProductId: "",
        imgUrl: "",
      };
      return { ...state };
    case SELECT_PAGE_FROM_DEAL:
      state.selectedPage = actions.item;
      return { ...state };
    case CLEAR_SELECT_PAGE_FROM_DEAL:
      state.selectedPage = "";
      return { ...state };
    case SET_TARGET_CORDINATES:
      state.targetCordinates = actions.item;
      return { ...state };
    case CLEAR_TARGET_CORDINATES:
      state.targetCordinates = {
        xSart: "",
        yStart: "",
        xEnd: "",
        yEnd: "",
      };
      return { ...state };
    case SORT_DEALS_TYPE:
      state.sortDeals.map((sortDeal) => {
        if (sortDeal.value === actions.item.value) {
          sortDeal.active = true;
        } else {
          sortDeal.active = false;
        }
        return null;
      });
      return { ...state };

    case NEWSPAPER_ID:
      state.newspaperId = actions.item;
      localStorage && localStorage.setItem("newsPaperId", state.newspaperId);
      return { ...state };

    case SET_CURRENT_PAGE:
      state.currentPage = actions.item;
      return { ...state };

    case ADD_TO_SUGESTED_NEWSPAPERS:
      state.sugestedNewspapers.length = 0;
      state.sugestedNewspapers.push(...actions.item);
      return { ...state };

    case REMOVE_ONE_FROM_SUGESTED_NEWSPAPERS:
      state.sugestedNewspapers = state.sugestedNewspapers.filter(
        (item) => item.id !== actions.item.id
      );
      return { ...state };

    case CLEAR_SUGESTED_NEWSPAPERS:
      state.sugestedNewspapers.length = 0;
      return { ...state };

    case CHANGE_DATA_ANALYS_PERIOD:
      localStorage && localStorage.setItem("dataPeriod", actions.item);
      return { ...state, dataAnalysisPeriod: actions.item };

    case TOP_DEALS_ITEM_TO_UPDATE:
      return { ...state, topDealsItemToUpdate: actions.item };

    case SHOP_TO_UPDATE:
      return { ...state, shopToUpdate: actions.item };

    default:
      return { ...state };
  }
};
// export default selectReducer;
