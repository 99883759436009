import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import Newspapers from "../../../../assets/media/svg/newspaper-solid.svg";
import { SingleOptionWrapper } from "../LeftColumn.style";
import { menuClose } from "../../../../store/theme/duck/actions/actions";

const NewsPapers = ({ user, theme, menuClose }) => {
  const [isActive, setActive] = useState(false);
  let location = useLocation();
  useEffect(() => {
    if (location?.pathname === "/studio/newspapers") {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [location?.pathname]);
  return (
    <Link to="/studio/newspapers" onClick={() => menuClose()}>
      <SingleOptionWrapper isDark={theme.isDark} isActive={isActive}>
        <img src={Newspapers} alt="gazetki promocyjne" />
        <p>Gazetki promocyjne</p>
      </SingleOptionWrapper>
    </Link>
  );
};

const mapDispatchToProps = (dispatch) => ({
  menuClose: () => dispatch(menuClose()),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(NewsPapers);
