import { USER_SIGN_IN, USER_SIGN_OUT } from "../types/type";

export const userSignInService = (item) => ({
  type: USER_SIGN_IN,
  item,
});
export const userSignOutOfService = (item) => ({
  type: USER_SIGN_OUT,
  item,
});
