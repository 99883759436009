import React, { useState } from "react";
import { connect } from "react-redux";
import { MainWrapper, MainWrapperLayer } from "./styleForUserRegisterSection";
import { BeforeValidatione } from "./BeforeValidatione";
import { AfterValidatione } from "./AfterValidatione";

export const UserRegister = () => {
  const [mainValidation, setMainValidation] = useState(false);
  const [body, setBody] = useState({
    first_name: "",
    email: "",
    password: "",
    represents: "",
  });

  return (
    <>
      <MainWrapper>
        <MainWrapperLayer>
          {!mainValidation ? (
            <BeforeValidatione
              validate={setMainValidation}
              setBodyEmail={setBody}
            />
          ) : (
            <AfterValidatione
              mainValidation={mainValidation}
              body={body}
              changeAfterBadResponse={setMainValidation}
            />
          )}
        </MainWrapperLayer>
      </MainWrapper>
    </>
  );
};
