import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  WrraperSection,
  MainWrapperLayer,
  MainContent,
  styleForActionsSvg,
} from "./stylesForTopDeals";
import { SortStyle } from "./sortSection/SortStyle";
import { SectionSortTopDeals } from "./sortSection/SectionSortTopDeals";
import { TopDealsCarusel } from "./topDealsCarusel/TopDealsCarusel";
import { MainTopDealsContent } from "./mainTopDealsContent/MainTopDealsContent";
import { clearTopDeals, chose } from "../../store/select/duck/actions/actions";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import useFetchWithObservableHook from "../../helpers/useFetchWithObservableHook";
import { checkOffset } from "../../helpers/scrollObserver";

const trashPath =
  "M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z";
const TopDealsContractor = ({
  theme,
  options,
  select,
  clearTopDeals,
  setTopDealsShoppageFromNewspaper,
  topDealsPageFromNewsPaper,
  dealsType,
  setNumberOfDeals,
  user,
  topDealsSelectedName,
  topDealUpdatedID,
  setTopDealUpdatedTimestamp,
  topDealUpdatedTimestamp,
}) => {
  const numberOfTopDeals = theme?.isMobile ? 15 : 35;

  const [dealsNumber, setDealsNumber] = useState({
    offset: 0,
    limit: numberOfTopDeals,
  });
  const [observableActive, setObservableActive] = useState(false);
  const [deletedItems, setDeletedItems] = useState([]);
  const obrervabelElementRef = useRef();
  const variableForSortDeals =
    select?.sortDeals.filter((item) => item.active)[0]?.name || "recomended";
  const { data, loading, errorFromCrypto } = useFetchWithObservableHook({
    user,
    endPoint: "topDeals/ForContractor",
    method: "POST",
    body: {
      topDealsSelectedName,
      offset: dealsNumber?.offset,
      limit: dealsNumber?.limit,
      sortType: variableForSortDeals,
      deletedItems,
      topDealUpdatedID,
      topDealUpdatedTimestamp,
    },
  });

  const handleScroll = () => {
    let scroll = window && window.scrollY;
    const activeObservableElem = checkOffset(obrervabelElementRef, scroll, 600);
    if (activeObservableElem) {
      setObservableActive(true);
    } else {
      setObservableActive(false);
    }
  };
  useEffect(() => {
    setDealsNumber({
      offset: 0,
      limit: numberOfTopDeals,
    });
  }, [
    select?.topDealsSelected?.name,
    variableForSortDeals,
    topDealUpdatedID,
    topDealUpdatedTimestamp,
  ]);

  useEffect(() => {
    handleScroll();
    if (
      observableActive === true &&
      !select?.topDealsSelected?.name &&
      loading === false &&
      dealsNumber?.limit <= data?.totalItemProducts
      // dealsNumber?.offset <= numberOfDeals
    ) {
      // console.log("refetchuje", observableActive);
      setDealsNumber({
        offset: dealsNumber.limit,
        limit: dealsNumber.limit + numberOfTopDeals,
      });
    }
  }, [
    observableActive,
    data?.itemProducts?.length,
    select?.topDealsSelected?.name,
    topDealsSelectedName,
    variableForSortDeals,
    loading,
    deletedItems?._id,
    topDealUpdatedID,
    topDealUpdatedTimestamp,
  ]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });
  // console.log({ data, loading, errorFromCrypto });
  return (
    <>
      <WrraperSection isDark={theme.isDark}>
        <MainWrapperLayer isDark={theme.isDark}>
          <MainContent isDark={theme.isDark}>
            <ErrorBoundary>
              <TopDealsCarusel
                update={
                  select.options[1].valueName || select.options[2].valueName
                }
                topDealsSelectedName={select?.topDealsSelected?.name}
                dataforCenniczek={data}
                loading={loading}
                deletedItems={deletedItems}
              />
              <SortStyle isDark={theme.isDark}>
                <h1>
                  {`${select.topDealsSelected.name}` || "wszystkie"}
                  {`${select.topDealsSelected.name}` ? (
                    <span
                      onClick={() => clearTopDeals()}
                      style={{
                        fontSize: "14px",
                        padding: "5px",
                        borderRadius: "5px",
                        border: `1px solid black`,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "35px",
                        marginLeft: "15px",
                      }}
                    >
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="trash-alt"
                        className="svg-inline--fa fa-trash-alt fa-w-14"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                        style={styleForActionsSvg}
                        height={22}
                      >
                        <path fill="currentColor" d={trashPath}></path>
                      </svg>
                    </span>
                  ) : (
                    ""
                  )}
                </h1>

                <SectionSortTopDeals isDark={theme.isDark} />
              </SortStyle>
              <MainTopDealsContent
                options={options}
                setTopDealsShoppageFromNewspaper={
                  setTopDealsShoppageFromNewspaper
                }
                topDealsPageFromNewsPaper={topDealsPageFromNewsPaper}
                dealsType={dealsType}
                setNumberOfDeals={setNumberOfDeals}
                setDealsNumber={setDealsNumber}
                dataforCenniczek={data}
                loading={loading}
                setDeletedItems={setDeletedItems}
                deletedItems={deletedItems}
                topDealUpdatedID={topDealUpdatedID}
                topDealUpdatedTimestamp={topDealUpdatedTimestamp}
                setTopDealUpdatedTimestamp={setTopDealUpdatedTimestamp}
              />
              <div ref={obrervabelElementRef}></div>
            </ErrorBoundary>
          </MainContent>
        </MainWrapperLayer>
      </WrraperSection>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  clearTopDeals: (item) => dispatch(clearTopDeals(item)),
  chose: (item) => dispatch(chose(item)),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  select: state.select,
  user: state.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(TopDealsContractor);
