import styled from "styled-components";
import { Theme } from "../../store/theme/theme";
import { motion } from "framer-motion";
export const WrraperSection = styled.div`
  // background-color: #f8f9d2;
  width: 100%;
  position: relative;

  user-select: none;
  @media${Theme.device.mobileL} {
    z-index: 5;
  }
`;
export const MainWrapperLayer = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  transition: background 0.3s;
  display: flex;
  justify-content: space-around;
  // align-items:flex-start;
`;
export const MainContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 70vh;
  max-width: calc(90vw - 400px);
  color: ${({ isDark }) =>
    !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  font-family: montserrat;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 40px;
  @media${Theme.device.tablet} {
    max-width: ${Theme.breakPoint.tablet}px;
  }
`;
export const SectionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 100px;
  flex-wrap: no-wrap;
  & > h2 {
    // width: 55%;
    margin: 10px 0;
    font-size: 30px;
    color: ${({ isDark }) =>
      !isDark ? Theme.dark.thirdColor.white : Theme.dark.thirdColor.white};
  }

  & > h2 > span {
    text-align: left;
    width: 300px;
    // max-width: 300px;
    padding-bottom: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-transform: uppercase;
    border-bottom: 1px solid
      ${({ isDark }) =>
        !isDark
          ? Theme.dark.primaryColor.dark
          : Theme.light.primaryColor.white};
    color: ${({ isDark }) =>
      !isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  }
  & > h2 > span > span {
    cursor: pointer;
    transition: 0.3s;
  }
  & > h2 > span > span:hover {
    transform: scale(1.1);
  }
  @media${Theme.device.mobileL} {
    padding-left: 20px;
    flex-wrap: wrap;
    & > h2 {
      font-size: 22px;
    }
    & > h2 > span {
      width: 240px;
    }
  }
`;
export const More = styled(motion.div)`
  width: 100%;
  height: 100px;
  border: none;
  & > a {
    display: block;
    margin: 25px auto;
    width: 300px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    border: 1px solid
      ${({ isDark }) =>
        isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
    border-radius: 30px;
    transform: scale(1);
    transition: 0.1s;
    background: ${Theme.light.secondaryColor.green};
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.dark.thirdColor.white};
  }
  & > a:hover {
    background: none;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    transform: scale(1.01);
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
  }
  @media${Theme.device.mobileS} {
    & > a {
      width: 240px;
    }
  }
`;
export const MainPostWraper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export const Content = styled.div`
  width: 90%;
  height: 30%;
  margin: 5px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  & > h2 {
    margin: 10px;
    text-transform: uppercase;
  }
  & > p {
    text-align: left;
    font-size: 14px;
    line-height: 22px;
  }
`;

export const styleForActionsSvg = {
  height: "22px",
  // paddingLeft:"15px",
};
