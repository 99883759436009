import { Spinner } from "../../tools/spinner/Spinner";
import { MainBar } from "./mainBar/MainBar";
import { MainBrandBar } from "./mainBrandBar/MainBrandBar";
import { MainCategoryBar } from "./mainCategoryBar/MainCategoryBar";
import { MainTitle } from "./mainTitle/MainTitle";
import Button from "../button/Button";
import { download } from "../../assets/media/svgPath/svgPath";
import { MainMetricsWrapper, ActionWrapper } from "./Dashboard.style";
import { TimeChart } from "./timeChart/TimeChart";

const CenniczekRepresents = ({
  days,
  isDark,
  data,
  isMobileL,
  generateRaportforCenniczek,
  generate,
  error,
}) => {
  return (
    <>
      <MainMetricsWrapper>
        <MainTitle
          days={days}
          isDark={isDark}
          data={data}
          isMobileL={isMobileL}
        />
        <MainBar days={days} isDark={isDark} isMobileL={isMobileL} />
        <MainBrandBar days={days} isDark={isDark} isMobileL={isMobileL} />
        <MainCategoryBar days={days} isDark={isDark} isMobileL={isMobileL} />
        <TimeChart days={days} isDark={isDark} isMobileL={isMobileL} />
      </MainMetricsWrapper>
      <ActionWrapper>
        {!generate && !error ? (
          <Button
            icon={download}
            text={"pobierz raport"}
            isDark={isDark}
            handleClick={generateRaportforCenniczek}
          />
        ) : generate && !error ? (
          <Spinner />
        ) : (
          <p>{error}</p>
        )}
      </ActionWrapper>
      {/* <UserList days={days} isDark={isDark} setDays={setDays} /> */}
    </>
  );
};
export default CenniczekRepresents;
