import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import controller from "../../helpers/usePermissionController";
import ReactPaginate from "react-paginate";
import SingleItemShop from "../singleItemShop/SingleItemShop";
import { deleteShopsNearUser } from "../../store/localization/duck/actions/actions";
import { shopUpdateOpen } from "../../store/theme/duck/actions/actions";
import { setShopToUpdate } from "../../store/select/duck/actions/actions";

const PaginationShops = ({
  user,
  theme,
  itemsPerPage,
  localization,
  updatetShopId,
  setUpdatetShopId,
  deleteShopsNearUser,
  shopUpdateOpen,
  setShopToUpdate,
}) => {
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = localization?.partnersArroundUser
    ?.sort((a, b) => a?.cordinatesDiff - b?.cordinatesDiff)
    ?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(
    localization?.partnersArroundUser?.length / itemsPerPage
  );
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) %
      localization?.partnersArroundUser?.length;

    setItemOffset(newOffset);
  };
  useEffect(() => {
    controller(user);
  }, []);
  return (
    <>
      <div style={{ height: "30px" }}></div>

      {currentItems.map((item) => (
        <SingleItemShop
          localizationItem={item}
          key={item?._id}
          theme={theme}
          option={true}
          updatetShopId={updatetShopId}
          setUpdatetShopId={setUpdatetShopId}
          deleteShopsNearUser={deleteShopsNearUser}
          shopUpdateOpen={shopUpdateOpen}
          setShopToUpdate={setShopToUpdate}
          itemOffset={itemOffset}
        />
      ))}

      <ReactPaginate
        breakLabel="..."
        nextLabel=">"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        pageCount={pageCount}
        previousLabel="<"
        renderOnZeroPageCount={null}
        className="paginationLight"
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.user,
  localization: state.localization,
});
const mapDisaptchToProps = (dispatch) => ({
  deleteShopsNearUser: (item) => dispatch(deleteShopsNearUser(item)),
  shopUpdateOpen: () => dispatch(shopUpdateOpen()),
  setShopToUpdate: (item) => dispatch(setShopToUpdate(item)),
});
export default connect(mapStateToProps, mapDisaptchToProps)(PaginationShops);
