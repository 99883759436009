import React, { useState, useEffect, useRef, useMemo } from "react";
import { Theme } from "../../../store/theme/theme";
import { connect } from "react-redux";
import { topDealsSelect } from "../../../store/select/duck/actions/actions";

// import { useQuery } from "@apollo/react-hooks";
// import { getProductKeysCategoriesQuery } from "../../../queries/queries";
// import { ErrorFromData } from "../../error/ErrorFromData";
import { useParams } from "react-router-dom";

import {
  CarouselProvider,
  Slider,
  ButtonBack,
  ButtonNext,
  DotGroup,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import {
  sliderStyle,
  slideStyle,
  slideStyleActive,
  slideStyleActiveLight,
  buttonBackStyle,
  buttonNextStyle,
  btnStyle,
  caruselWraperVariants,
  CaruselWrapper,
  WraperMobile,
  mobileSlideStyleActive,
  mobileSlideStyleActiveLight,
  mobileSlideStyle,
} from "./style/style";
import { resizeScreen, onLoadScreen } from "./helpers/Helpers";
import { ShowDeals } from "./showDeals/ShowDeals";
import { ShowDealsMobile } from "./showDealsMobile/ShowDealsMobile";

const backPath =
  "M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z";
const rightPath =
  "M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z";

const _TopDealsCarusel = ({
  select,
  update,
  topDealsSelect,
  topDealsSelectedName,
  dataforCenniczek,
  loading,
  theme,
  topDealUpdatedID,
}) => {
  const [btnVisible, setBtnVisible] = useState({ visible: false, slidesNr: 4 });
  const [nrPages, setNrpages] = useState(0);

  const [isMobile, setIsMobile] = useState(false);
  const [tap, setTap] = useState(false);
  const mobileWrapRef = useRef();
  const memoizedData = useMemo(
    () => dataforCenniczek,
    [
      dataforCenniczek?.productKeysCategory?.length,
      dataforCenniczek?.totalItemProducts,
      topDealUpdatedID,
    ]
  );

  // let pureData = useGetProductKeysCategory({ select });

  useEffect(() => {
    if (tap) {
      setTimeout(() => {
        if (tap) {
          setTap(false);
        }
      }, 1000);
    }
  }, [tap]);
  useEffect(() => {
    if (window.innerWidth < Theme.breakPoint.tablet) {
      setIsMobile(true);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < Theme.breakPoint.tablet) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });
  }, []);
  useEffect(() => {
    // data from brandName for handle brandPage
    if (loading === false && memoizedData?.productKeysCategory?.length) {
      setNrpages(memoizedData?.productKeysCategory?.length);
    }

    // data from dataFromHomePage for handle homePage
  }, [loading, memoizedData?.productKeysCategory?.length]);
  useEffect(() => {
    onLoadScreen(btnVisible, setBtnVisible, 1, 220, Theme.breakPoint.desktop);
    resizeScreen(btnVisible, setBtnVisible, 1, 220, Theme.breakPoint.desktop);
  }, []);
  useEffect(() => {
    return () => {
      setNrpages(0);
    };
  }, []);

  return (
    <CaruselWrapper>
      {isMobile === false && (
        <CarouselProvider
          naturalSlideWidth={220}
          naturalSlideHeight={140}
          totalSlides={nrPages}
          visibleSlides={btnVisible.slidesNr}
          orientation={"horizontal"}
          isIntrinsicHeight={true}
          step={btnVisible.slidesNr}
          dragStep={1}
        >
          <Slider style={sliderStyle}>
            <ShowDeals
              valueName={
                select.options[1].valueName || select.options[2].valueName
              }
              select={select}
              topDealsSelect={topDealsSelect}
              slideStyleActive={
                theme?.isDark ? slideStyleActive : slideStyleActiveLight
              }
              slideStyle={slideStyle}
              caruselWraperVariants={caruselWraperVariants}
              update={update}
              topDealsSelectedName={topDealsSelectedName}
              dataforCenniczek={memoizedData}
              isDark={theme.isDark}
            />
          </Slider>
          <ButtonBack
            style={btnVisible.visible ? buttonBackStyle : { display: "none" }}
          >
            {/* <BackArrow style={btnStyle} /> */}
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="chevron-left"
              className="svg-inline--fa fa-chevron-left fa-w-10"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              style={btnStyle}
              width={25}
            >
              <path fill="currentColor" d={backPath}></path>
            </svg>
          </ButtonBack>
          <ButtonNext
            style={btnVisible.visible ? buttonNextStyle : { display: "none" }}
          >
            {/* <NextArrow style={btnStyle} /> */}
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="chevron-right"
              className="svg-inline--fa fa-chevron-right fa-w-10"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              style={btnStyle}
              width={25}
            >
              <path fill="currentColor" d={rightPath}></path>
            </svg>
          </ButtonNext>
          {typeof window !== "undefined" &&
          window.innerWidth < Theme.breakPoint.desktop ? (
            <DotGroup
              className="caruselTopDealsDots"
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                padding: "10px",
              }}
            >
              <div style={{ width: "10px", margin: "2px" }}></div>
            </DotGroup>
          ) : null}
        </CarouselProvider>
      )}
      {isMobile === true && (
        <>
          <WraperMobile
            ref={mobileWrapRef}
            tap={tap}
            onScroll={() => {
              if (!tap) setTap(true);
            }}
          >
            <ShowDealsMobile
              valueName={
                select.options[1].valueName || select.options[2].valueName
              }
              select={select}
              topDealsSelect={topDealsSelect}
              slideStyleActive={
                theme?.isDark
                  ? mobileSlideStyleActive
                  : mobileSlideStyleActiveLight
              }
              slideStyle={mobileSlideStyle}
              caruselWraperVariants={caruselWraperVariants}
              update={update}
              topDealsSelectedName={topDealsSelectedName}
              isDark={theme.isDark}
              dataforCenniczek={memoizedData}
            />
          </WraperMobile>
        </>
      )}
    </CaruselWrapper>
  );
};
const mapStateToProps = (state) => ({
  theme: state.theme,
  select: state.select,
});
const mapDispatchToProps = (dispatch) => ({
  topDealsSelect: (item) => dispatch(topDealsSelect(item)),
});
export const TopDealsCarusel = connect(
  mapStateToProps,
  mapDispatchToProps
)(_TopDealsCarusel);
