import useNewspaperControllerForCenniczek from "./useNewspaperControllerForCenniczek";
import { Spinner } from "../../tools/spinner/Spinner";
import { SingleItem } from "./SingleItem";
import sortOption from "../../helpers/sortOption";
import { SectionSort } from "../sectionSort/SectionSort";
import { WrapperForNewspapers, WrapperForInputs } from "./Newspaper.Style";

const NewspapersCenniczek = ({ user, theme, select, addProduct }) => {
  const { data, loading, errorFromCrypto } =
    useNewspaperControllerForCenniczek(user);
  let trueData;
  let truePre;

  const filterSortOption = select?.sort.filter(
    (item) => item.active === true
  )[0];

  if (data) {
    let withouTxtRecognizing;
    if (addProduct) {
      withouTxtRecognizing = data?.newspapers?.filter(
        (item) => !item?.txtRecognizing
      );
    } else {
      withouTxtRecognizing = data?.newspapers;
    }

    trueData = sortOption(withouTxtRecognizing, filterSortOption);
    truePre = sortOption(data?.preBooks, filterSortOption);
  }

  return (
    <>
      {loading && <Spinner />}
      {errorFromCrypto && <p>{errorFromCrypto}</p>}

      <SectionSort
        isDark={theme.isDark}
        isBrandActive={select.options[1].active}
        isBrandSelected={select.options[1].selectedItemID}
        isCategoryActive={select.options[0].active}
      />
      <WrapperForNewspapers
        isDark={theme?.isDark}
        center={true}
        length={truePre?.length}
      >
        {!addProduct &&
          truePre?.map((item, index) => {
            return (
              <SingleItem
                key={index}
                item={item}
                isDark={theme.isDark}
                index={index}
                isMobile={theme?.isMobile}
                pre={true}
              />
            );
          })}
      </WrapperForNewspapers>
      <WrapperForNewspapers isDark={theme?.isDark} length={trueData?.length}>
        {trueData
          ?.sort((a, b) => (a?.txtRecognizing ? -1 : 1))
          ?.map((item, index) => {
            return (
              <SingleItem
                key={index}
                item={item}
                isDark={theme.isDark}
                index={index}
                isMobile={theme?.isMobile}
                addProduct={addProduct}
                pre={false}
              />
            );
          })}
      </WrapperForNewspapers>
    </>
  );
};
export default NewspapersCenniczek;
