import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { TopWrapper } from "./AddShop.style";
import { plus } from "../../../assets/media/svgPath/svgPath";
import { SvgDisplay } from "../../../tools/svgDisplay/SvgDisplay";
import { AnimatePresence } from "framer-motion";
import { shopUpdateOpen } from "../../../store/theme/duck/actions/actions";
import { setShopToUpdate } from "../../../store/select/duck/actions/actions";

const topVariants = {
  hidden: {
    opacity: 0,
    y: 0,
    scale: 0.6,
  },
  visible: {
    opacity: 1,
    y: 50,
    scale: 1,
    transition: {
      duration: 0.3,
    },
  },
  exit: {
    opacity: 0,
    y: 50,
    scale: 0.6,
    transition: {
      duration: 0.3,
    },
  },
};
const _AddShop = ({ theme, shopUpdateOpen, setShopToUpdate }) => {
  const add = () => {
    const item = {
      openingHours: [],
      position: [],
      city: "",
      name: "",
      number: "",
      postCode: "",
      street: "",
      phone: "",
    };
    shopUpdateOpen();
    setShopToUpdate(item);
  };

  return (
    <AnimatePresence>
      <TopWrapper
        isDark={theme.isDark}
        onClick={add}
        whileTap={{ scale: 0.95 }}
        variants={topVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <SvgDisplay
          d={plus.d}
          viewBox={plus.viewBox}
          style={{ width: "30px", height: "30px" }}
        />
        <p>Dodaj</p>
      </TopWrapper>
    </AnimatePresence>
  );
};

const mapStateToProps = (state) => ({
  theme: state.theme,
});
const mapDisaptchToProps = (dispatch) => ({
  shopUpdateOpen: () => dispatch(shopUpdateOpen()),
  setShopToUpdate: (item) => dispatch(setShopToUpdate(item)),
});

export const AddShop = connect(mapStateToProps, mapDisaptchToProps)(_AddShop);
