import React, { useEffect } from "react";
import { connect } from "react-redux";
import { userSignInService } from "../../store/user/duck/actions/actions";
import useMainDashBoardController from "./useMainDashBoardController";
import LeftColumn from "./leftColumn/LeftColumn";
import { Switch, Route, Router } from "react-router-dom";
import history from "../../utils/history";
import Dashboard from "../dashboard/Dashboard";
import NewsPapers from "../newspapers/Newspapers";
import Products from "../products/Products";
import AddProduct from "../addProduct/AddProduct";
import { Newspaper } from "../newspaper/Newspaper";
import NotFound from "../notFound/NotFound";
import Shops from "../shops/Shops";
import Settings from "../settings/Settings";
import ContactUs from "../contacUs/ContactUs";
import { MainWrapper, RightWrapper } from "./MainDashboard.style";
import Header from "../header/Header";
import { createGlobalStyle } from "styled-components";
import { Theme } from "../../store/theme/theme";
import AddNewspaper from "../addNewspaper/AddNewspaper";
import { ScrollToTop } from "../../helpers/scrollToTop/SrollToTop";
import Messages from "../messages/Messages";

const GlobalStyle = createGlobalStyle`
*,*::after,*::before{
  box-sizing:border-box;
  margin:0;
  padding:0;
  scroll-behavior: smooth;
}
body{
  overflow-y:${({ overflow }) => (overflow ? `hidden !important` : "")};
  overflow-y:${({ overflowY }) => (overflowY ? `hidden !important` : "")};
  &::-webkit-scrollbar {
    width: 12px;
    border-radius: 0 5px 5px 0;
    background-color: ${({ isDark }) =>
      !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border-left: 3px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    border-right: 3px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
   
    background-color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  @media${Theme.device.mobileL} {
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 0 2px 2px 0;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
    
      border-right: none;
      border-left: none;
    }
  }
}
`;

const MainDashboard = ({ user, theme, userSignInService }) => {
  const userData = useMainDashBoardController(user);
  useEffect(() => {
    if (user?.userLoged === false) {
      if (userData?.userLoged) {
        userSignInService(userData);
      }
    }
  }, [userData, user?.userLoged]);

  return (
    <MainWrapper>
      <GlobalStyle
        overflow={theme?.isMenuOpen}
        overflowY={theme?.canOverflow}
        isDark={theme?.isDark}
      />
      <LeftColumn />
      <RightWrapper isDark={theme?.isDark} isMenuOpen={theme?.isMenuOpen}>
        <Header />
        <ScrollToTop />
        <Router history={history}>
          <Switch>
            <Route path="/studio/dashboard" exact>
              <Dashboard />
            </Route>
            <Route path="/studio/newspapers" exact>
              <NewsPapers />
            </Route>
            <Route path="/studio/addNewspaper" exact>
              <AddNewspaper />
            </Route>
            <Route path="/studio/products" exact>
              <Products />
            </Route>
            <Route path="/studio/addProduct" exact>
              <AddProduct />
            </Route>
            <Route path="/studio/shops" exact>
              <Shops />
            </Route>
            <Route path="/studio/settings" exact>
              <Settings />
            </Route>
            <Route path="/studio/contact-us" exact>
              <ContactUs />
            </Route>
            <Route path="/studio/messages" exact>
              <Messages />
            </Route>
            <Route path="/studio/gazetka-promocyjna/:name-:alternativeFinalName/:duringTime/:pageNr">
              <Newspaper />
            </Route>
            <Route path="/studio/">
              <NotFound dashboard={true} />
            </Route>
          </Switch>
        </Router>
      </RightWrapper>
    </MainWrapper>
  );
};
const mapDispatchToProps = (dispatch) => ({
  userSignInService: (item) => dispatch(userSignInService(item)),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(MainDashboard);
