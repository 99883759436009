import { eyeStyle } from "../Login.style";
import { Theme } from "../../../store/theme/theme";
import { eyePath, eyeSlashPath } from "../../../assets/media/svgPath/svgPath";
import { SvgDisplay } from "../../../tools/svgDisplay/SvgDisplay";

export const VisiblePasswordHandler = ({
  passwordVisible,
  setPasswordVisible,
  isDark,
}) => {
  return !passwordVisible ? (
    <SvgDisplay
      viewBox={eyePath.viewBox}
      d={eyePath.d}
      style={eyeStyle}
      onClickCB={() => setPasswordVisible(true)}
      width={15}
      fill={Theme.dark.fiveColor.white}
    />
  ) : (
    <SvgDisplay
      viewBox={eyeSlashPath.viewBox}
      d={eyeSlashPath.d}
      style={eyeStyle}
      onClickCB={() => setPasswordVisible(false)}
      width={15}
      fill={Theme.dark.fiveColor.white}
    />
  );
};
