import React, { useEffect, useState } from "react";
import { Spinner } from "../../../tools/spinner/Spinner";
import { SingleDeal } from "../singleDeal/SingleDeal";
import { sortBySelected } from "./loadDeals.helper";

export const LoadDealsCenniczek = ({
  topDealsSelected,
  activeSortType,
  optionsConfirmed,
  theme,
  dataforCenniczek,
  loading,
  setDeletedItems,
  deletedItems,
  topDealUpdatedID,
  topDealUpdatedTimestamp,
  setTopDealUpdatedTimestamp,
}) => {
  const [result, setResult] = useState(false);
  // console.log(dataforCenniczek, "data");

  if (!result && loading === false && dataforCenniczek?.itemProducts) {
    setResult(dataforCenniczek?.itemProducts);
    // console.log("zmiana 1");
  }

  useEffect(() => {
    if (dataforCenniczek?.itemProducts?.length && topDealsSelected && loading) {
      setResult(false);
      // console.log("zmiana 2");
    }
    if (dataforCenniczek?.itemProducts?.length && topDealUpdatedID && loading) {
      setResult(false);
      // console.log("zmiana 3");
    }
    if (
      dataforCenniczek?.itemProducts?.length &&
      topDealsSelected &&
      !loading
    ) {
      // const newData = dataforCenniczek?.itemProducts;
      // const updated = dataforCenniczek?.itemProducts.filter(
      //   (item) => String(item?._id) === topDealUpdatedID
      // );
      // const findUpdated = dataforCenniczek?.itemProducts.findIndex(
      //   (item) => String(item?._id) === topDealUpdatedID
      // );
      // if (findUpdated !== -1 && updated[0]) {
      //   newData[findUpdated] = updated[0];
      // }
      // console.log(updated, "updated");
      // console.log(findUpdated, "findUpdated");
      // console.log("zmiana 4", dataforCenniczek?.itemProducts);
      setResult(dataforCenniczek?.itemProducts);
    }
    if (dataforCenniczek?.itemProducts?.length && !topDealsSelected) {
      let newData, filterByDeleted;
      if (result && loading === false && topDealUpdatedTimestamp) {
        // setResult(dataforCenniczek?.itemProducts);

        // console.log("zmiana 5");
        // newData = [];
        // dataforCenniczek?.itemProducts.forEach((item) => {
        //   const ind = newData.findIndex(
        //     (dataItem) => dataItem._id === item._id
        //   );
        //   if (ind === -1) {
        //     newData.push(item);
        //   }
        // });
        // filterByDeleted = newData.filter(
        //   (item) => String(item?._id) !== String(deletedItems?._id)
        // );
        setResult(null);
        setTopDealUpdatedTimestamp(null);
      } else {
        // if (
        //   dataforCenniczek?.itemProducts?.length &&
        //   topDealUpdatedID &&
        //   loading
        // ) {
        //   setResult([]);
        // }
        newData = result?.length ? [...result] : [];
        dataforCenniczek?.itemProducts.forEach((item) => {
          const ind = newData.findIndex(
            (dataItem) => dataItem._id === item._id
          );
          if (ind === -1) {
            newData.push(item);
          }
        });
        filterByDeleted = newData.filter(
          (item) => String(item?._id) !== String(deletedItems?._id)
        );
        // const updated = dataforCenniczek?.itemProducts.filter(
        //   (item) => String(item?._id) === topDealUpdatedID
        // );
        // const findUpdated = filterByDeleted.findIndex(
        //   (item) => String(item?._id) === topDealUpdatedID
        // );
        // if (findUpdated !== -1 && updated[0]) {
        //   filterByDeleted[findUpdated] = updated[0];
        // }
        // console.log(dataforCenniczek?.itemProducts);
        // console.log("zmiana 6");

        setResult(filterByDeleted);
      }
    }
  }, [
    topDealsSelected,
    dataforCenniczek?.itemProducts?.length,
    dataforCenniczek?.timeStamp,
    dataforCenniczek?.totalItemProducts,
    loading,
    deletedItems?._id,
    topDealUpdatedID,
    topDealUpdatedTimestamp,
  ]);

  useEffect(() => {
    return () => {
      setResult(null);
    };
  }, []);
  // if (loading) {
  //   return <Spinner />;
  // } else {

  if (loading && result === false) {
    return <Spinner />;
  }

  if (!loading && result?.length === 0) {
    return <p>nie znaleźliśmy promocji</p>;
  } else {
    const filteredBySelected = sortBySelected(
      result,
      activeSortType,
      topDealUpdatedTimestamp
    );
    return (
      <>
        {filteredBySelected?.map((item, index) => {
          return (
            <SingleDeal
              item={item}
              key={item?._id}
              isDark={theme.isDark}
              setDeletedItems={setDeletedItems}
            />
          );
        })}
        {loading && (
          <div style={{ position: "relative", width: "100%", height: "25vh" }}>
            <Spinner />
          </div>
        )}
      </>
    );
  }
  // }
};
