import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { City } from "country-state-city";
import Select from "react-select";
import { HeaderSearchWrapper } from "./GetCities.style";
import { Theme } from "../../store/theme/theme";
import { getUserLocalization } from "../../store/localization/duck/actions/actions";

const GetCities = ({ theme, getUserLocalization }) => {
  const [cities, setCities] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (selectedOption?.value?.name) {
      getUserLocalization({
        coords: {
          latitude: selectedOption?.value?.lat,
          longitude: selectedOption?.value?.lng,
        },
      });
    }
  }, [selectedOption?.value?.name]);

  useEffect(() => {
    const allCities = City.getCitiesOfCountry("PL");
    const standarizationCities = allCities.map((item) => {
      return {
        value: {
          name: item?.name,
          lat: item?.latitude,
          lng: item?.longitude,
        },
        label: item?.name,
      };
    });
    setCities(standarizationCities);
  }, []);
  return (
    <>
      <HeaderSearchWrapper>
        <p>Wyszukaj miasto</p>
        <Select
          defaultValue={selectedOption}
          onChange={setSelectedOption}
          options={cities}
          placeholder="Miasto"
          className={theme?.isDark ? "citySearchDark" : "citySearchLigth"}
          styles={{
            input: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: theme?.isDark
                ? Theme.dark.sixColor.dark
                : Theme.light.fiveColor.white,
              color: theme?.isDark
                ? Theme.dark.thirdColor.white
                : Theme.light.thirdColor.dark,
            }),
            singleValue: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: theme?.isDark
                ? Theme.dark.sixColor.dark
                : Theme.light.fiveColor.white,
              color: theme?.isDark
                ? Theme.dark.thirdColor.white
                : Theme.light.thirdColor.dark,
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: theme?.isDark
                ? Theme.dark.sixColor.dark
                : Theme.light.fiveColor.white,
              color: theme?.isDark
                ? Theme.dark.thirdColor.white
                : Theme.light.thirdColor.dark,
              borderColor: theme?.isDark
                ? Theme.dark.thirdColor.white
                : Theme.light.thirdColor.dark,
            }),
            indicatorContainer: (baseStyles, state) => ({
              ...baseStyles,
              color: theme?.isDark
                ? Theme.dark.thirdColor.white
                : Theme.light.thirdColor.dark,
            }),
            menuList: (baseStyles, state) => ({
              ...baseStyles,
              backgroundColor: theme?.isDark
                ? Theme.dark.eightColor.dark
                : Theme.light.fiveColor.white,
              color: theme?.isDark
                ? Theme.dark.thirdColor.white
                : Theme.light.thirdColor.dark,
            }),
          }}
        />
      </HeaderSearchWrapper>
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  getUserLocalization: (item) => dispatch(getUserLocalization(item)),
});

const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(GetCities);
