import useFetchHook from "../../helpers/useFetchHook";

const useNewspaperControllerForContractor = (user) => {
  const { data, loading, errorFromCrypto } = useFetchHook({
    user,
    endPoint: "getAllNewspaperForContractor",
    method: "POST",
    body: { brand: user?.represents },
  });
  if (!user?.userLoged || user.represents === "cenniczek") return {};

  return { data, loading, errorFromCrypto };
};
export default useNewspaperControllerForContractor;
