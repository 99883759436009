import styled from "styled-components";
import { Theme } from "../../store/theme/theme";
export const WrapperForNotFound = styled.div`
  width: 100%;
  min-height: ${({ dashboard }) =>
    dashboard ? "calc(100vh - 80px)" : "100vh"};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.primaryColor.dark : Theme.light.sixColor.white};
  & > h3 {
    font-size: 50px;
  }
  & > p {
    margin-top: 30px;
  }
`;
