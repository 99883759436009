import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import controller from "../../helpers/usePermissionController";
import {
  WrapperDatePicker,
  DashboardWrapper,
  ConentWrapper,
} from "./Dashboard.style";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import dashboardService from "./dasboardService";
import { Spinner } from "../../tools/spinner/Spinner";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import CenniczekRepresents from "./Dashboard.cenniczek";
import ContractorRepresents from "./Dashboard.Contractor";
import basketShop from "../../assets/media/svg/basket-shopping-solid.svg";

const Dashboard = ({ user, theme, select }) => {
  const [generate, setGenerate] = useState(false);
  const [error, setError] = useState("");
  // const [value, onChange] = useState([new Date(), new Date()]);
  const [value, onChange] = useState(null);
  const [errorFromCrypto, setErrorFromCrypto] = useState("");
  const [process, setProcess] = useState(false);
  const [data, setData] = useState(null);
  const [days, setDays] = useState({
    number: null,
    time: null,
    render: 0,
  });
  useEffect(() => {
    controller(user);
    const firstDateControl =
      +new Date() - Number(select?.dataAnalysisPeriod) * 24 * 60 * 60 * 1000;
    onChange([new Date(firstDateControl), new Date()]);
  }, []);

  useEffect(() => {
    dashboardService.days(data, setDays);
  }, [data?.start[0], data?.stop[0]]);

  useEffect(() => {
    if (value && value.length && value[0]?.getDate() && value[1]?.getDate()) {
      // console.log(value[0].getUTCMilliseconds(), "init");
      dashboardService.getAnalis(
        value[0],
        value[1],
        setData,
        setProcess,
        setErrorFromCrypto
      );
    }
    if (!value) {
      setData(null);
      setDays({
        number: null,
        time: null,
      });
    }
  }, [value && value[0], value && value[1]]);

  useEffect(() => {
    if (process) {
      setDays({
        number: null,
        time: null,
        render: 0,
      });
    }
  }, [process]);
  const generateRaportforCenniczek = () => {
    setError("");
    setGenerate(true);
    dashboardService.downloadCSVRaportForCenniczek(
      data,
      days,
      setGenerate,
      setError
    );
    dashboardService.PDFRaportForCenniczek(data, days);
  };
  const generateRaportForContractor = () => {
    setError("");
    setGenerate(true);
    dashboardService.downloadCSVRaportForContractor(
      data,
      days,
      setGenerate,
      setError
    );

    dashboardService.PDFRaportForContractor(data, days);
  };

  // console.log({ data, days, value, process, error });
  const checkConditions = ({ user, data, days, process, select }) => {
    if (user?.represents === "cenniczek") {
      if (
        data?.ok &&
        days?.number > 0 &&
        !process &&
        select?.dataAnalysisPeriod
      ) {
        return (
          <CenniczekRepresents
            days={days}
            isDark={theme?.isDark}
            data={data}
            isMobileL={theme?.isMobile}
            generateRaportforCenniczek={generateRaportforCenniczek}
            generate={generate}
            error={error}
          />
        );
      }
    }
    if (user?.represents !== "cenniczek") {
      if (
        data?.ok &&
        days?.number > 0 &&
        !process &&
        select?.dataAnalysisPeriod
      ) {
        return (
          <ContractorRepresents
            days={days}
            isDark={theme?.isDark}
            data={data}
            isMobileL={theme?.isMobile}
            generateRaportForContractor={generateRaportForContractor}
            generate={generate}
            error={error}
            represents={user?.represents}
          />
        );
      }
    }
  };

  return (
    <DashboardWrapper>
      <p>Tablica główna</p>
      <WrapperDatePicker isDark={theme.isDark}>
        <DateRangePicker
          isOpen={false}
          onChange={onChange}
          value={value}
          locale={"pl-PL"}
          format={"dd-MM-y"}
          dayPlaceholder={"dd"}
          monthPlaceholder={"mm"}
          yearPlaceholder={"rrrr"}
          calendarClassName={theme.isDark ? "darkCalendar" : "ligthCalendar"}
        />
        <ErrorBoundary>
          <ConentWrapper isDark={theme.isDark}>
            {process && <Spinner />}
            {data && data?.error && !process && (
              <>
                <img src={basketShop} />
                <h3>{data.error}</h3>
              </>
            )}
            {checkConditions({
              user,
              data,
              days,
              process,
              select,
            })}
          </ConentWrapper>
        </ErrorBoundary>
      </WrapperDatePicker>
    </DashboardWrapper>
  );
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.user,
  select: state.select,
});
export default connect(mapStateToProps, {})(Dashboard);
