import styled from "styled-components";
import { Theme } from "../../store/theme/theme";

export const WrapperForNewspapers = styled.div`
  width: ${({ length }) => (length > 12 ? "100%" : "66%")};
  display: flex;
  justify-content: ${({ center }) => (center ? "center" : "flex-start")};
  align-items: center;
  flex-wrap: wrap;
  @media${Theme.device.mobileL} {
    width: 100%;
  }
`;
export const WrapperForContractorNewspapers = styled.div`
  width: ${({ length }) => (length > 12 ? "100%" : "66%")};
  min-height: ${({ center }) => (center ? "" : "100vh")};
  display: flex;
  justify-content: ${({ center }) => (center ? "center" : "flex-start")};
  align-items: center;
  flex-wrap: wrap;
  @media${Theme.device.mobileL} {
    width: 100%;
  }
`;

export const WrapperForInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  & > h4 {
    width: 100%;
    text-align: center;
    margin: 20px;
    text-transform: uppercase;
  }
`;

export const FormWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px;
  flex-wrap: wrap;
  & > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  & > form > div {
    width: 60%;
    height: 60px;
    margin: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > form > div > input {
    width: 60%;
    height: 60px;
    margin: 20px;
    border: none;
    border-radius: 5px;
    text-align: center;
    transition: 0.1s;
    transform: scale(1);
    background: ${Theme.dark.eightColor.dark} !important;
    color: ${Theme.light.fiveColor.white};
  }
  & > form > button {
    width: 200px;
    height: 60px;
    margin: 10px;
    border-radius: 5px;
    text-transform: uppercase;
    background-color: ${Theme.light.secondaryColor.green};
    border: 1px solid black;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.dark.thirdColor.white};
    transition: 0.3s;
    transform: scale(1);
    user-select: none;
    cursor: pointer;
  }
  & > form > button:hover {
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
    transform: scale(1.1);
  }
`;
