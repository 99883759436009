import {
  MARK,
  SELECT_ITEM,
  SORT_TYPE,
  SORT_DEALS_TYPE,
  NEWSPAPER_ID,
  LOCALIZATIONS_FOUNDED,
  TOP_DEALS_SELECT,
  CLEAR_TOP_DEALS,
  SELECT_PAGE_FROM_DEAL,
  CLEAR_SELECT_PAGE_FROM_DEAL,
  SET_TARGET_CORDINATES,
  CLEAR_TARGET_CORDINATES,
  SET_CURRENT_PAGE,
  SELECT_ITEM_FROM_SEARCH,
  ADD_TO_SUGESTED_NEWSPAPERS,
  REMOVE_ONE_FROM_SUGESTED_NEWSPAPERS,
  CLEAR_SUGESTED_NEWSPAPERS,
  CHANGE_DATA_ANALYS_PERIOD,
  TOP_DEALS_ITEM_TO_UPDATE,
  SHOP_TO_UPDATE,
} from "../types/type";
export const chose = (item) => ({
  type: MARK,
  item,
});
export const selectId = (item) => ({
  type: SELECT_ITEM,
  item,
});
export const sortType = (item) => ({
  type: SORT_TYPE,
  item,
});
export const sortDealsType = (item) => ({
  type: SORT_DEALS_TYPE,
  item,
});
export const newspaperId = (item) => ({
  type: NEWSPAPER_ID,
  item,
});
export const localizations = (item) => ({
  type: LOCALIZATIONS_FOUNDED,
  item,
});
export const topDealsSelect = (item) => ({
  type: TOP_DEALS_SELECT,
  item,
});
export const clearTopDeals = (item) => ({
  type: CLEAR_TOP_DEALS,
  item,
});
export const selectPageFromDeal = (item) => ({
  type: SELECT_PAGE_FROM_DEAL,
  item,
});
export const clearSelectPageFromDeal = (item) => ({
  type: CLEAR_SELECT_PAGE_FROM_DEAL,
  item,
});
export const setTargetCordinates = (item) => ({
  type: SET_TARGET_CORDINATES,
  item,
});
export const clearTargetCordinates = (item) => ({
  type: CLEAR_TARGET_CORDINATES,
  item,
});
export const setCurrentPage = (item) => ({
  type: SET_CURRENT_PAGE,
  item,
});
export const selectIdFromSearch = (item) => ({
  type: SELECT_ITEM_FROM_SEARCH,
  item,
});
export const addToSugestedNewspapers = (item) => ({
  type: ADD_TO_SUGESTED_NEWSPAPERS,
  item,
});
export const removeOneFromSugestedNewspapers = (item) => ({
  type: REMOVE_ONE_FROM_SUGESTED_NEWSPAPERS,
  item,
});
export const clearSugestedNewspapers = (item) => ({
  type: CLEAR_SUGESTED_NEWSPAPERS,
  item,
});
export const changeDataAnalysPeriod = (item) => ({
  type: CHANGE_DATA_ANALYS_PERIOD,
  item,
});
export const setTopDealsItemToUpdate = (item) => ({
  type: TOP_DEALS_ITEM_TO_UPDATE,
  item,
});
export const setShopToUpdate = (item) => ({
  type: SHOP_TO_UPDATE,
  item,
});
// export default {
//   chose,
//   selectId,
//   sortType,
//   sortDealsType,
//   newspaperId,
//   localizations,
//   topDealsSelect,
//   clearTopDeals,
//   selectPageFromDeal,
//   clearSelectPageFromDeal,
//   setTargetCordinates,
//   clearTargetCordinates,
//   setCurrentPage,
//   selectIdFromSearch,
// };
