import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  setPartnersArroundUser,
  deleteShopsNearUser,
} from "../../store/localization/duck/actions/actions";
import { shopUpdateOpen } from "../../store/theme/duck/actions/actions";
import { setShopToUpdate } from "../../store/select/duck/actions/actions";
import { Marker, Popup } from "react-leaflet";
import L from "leaflet";
import { Spinner } from "../../tools/spinner/Spinner";
// import { ReactComponent as PhoneCall } from "../../media/svg/phone-square.svg";
// import { ReactComponent as Car } from "../../media/svg/car.svg";
import styled from "styled-components";
import { Theme } from "../../store/theme/theme";
import { API_URL } from "../../constant/constant";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import {
  encryptFrontData,
  decryptFrontData,
} from "../../utils/Crypto/cryptoFront.controller";
import { SvgDisplay } from "../../tools/svgDisplay/SvgDisplay";
import { pen, trash } from "../../assets/media/svgPath/svgPath";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/default.css";
import deleteShop from "../../helpers/deleteShop";

const phonePath =
  "M400 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V80c0-26.51-21.49-48-48-48zM94 416c-7.033 0-13.057-4.873-14.616-11.627l-14.998-65a15 15 0 0 1 8.707-17.16l69.998-29.999a15 15 0 0 1 17.518 4.289l30.997 37.885c48.944-22.963 88.297-62.858 110.781-110.78l-37.886-30.997a15.001 15.001 0 0 1-4.289-17.518l30-69.998a15 15 0 0 1 17.16-8.707l65 14.998A14.997 14.997 0 0 1 384 126c0 160.292-129.945 290-290 290z";
const carPath =
  "M438.66 212.33l-11.24-28.1-19.93-49.83C390.38 91.63 349.57 64 303.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4l-19.93 49.83-11.24 28.1C17.22 221.5 0 244.66 0 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-27.34-17.22-50.5-41.34-59.67zm-306.73-54.16c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L368 208H112l19.93-49.83zM80 319.8c-19.2 0-32-12.76-32-31.9S60.8 256 80 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S380.8 256 400 256s32 12.76 32 31.9-12.8 31.9-32 31.9z";
const ActionWraper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Theme.light.fourthColor.red}!important;
  cursor: pointer;
  & > div {
    margin: 20px;
  }
  & > a,
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Theme.light.fourthColor.red}!important;
    transition: transform 0.3s;
  }
  & > a:hover,
  & > div:hover {
    transform: scale(1.1);
  }
`;
const styleForIcon = {
  width: "20px",
  margin: "10px",
  maxHeight: "20px",
};
const _GetShops = ({
  name,
  localization,
  select,
  setPartnersArroundUser,
  arroundOption,
  deleteShopsNearUser,
  map,
  shopUpdateOpen,
  setShopToUpdate,
  updatetShopId,
  setUpdatetShopId,
}) => {
  const [partners, setPartners] = useState([]);
  const [errorFromData, setErrorFromData] = useState("");
  const [errorFromCrypto, setErrorFromCrypto] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    !loading && setLoading(true);
    setErrorFromData("");
    setPartners("");
    const frontDataToEncrypt = {
      name: "getAll",
      arroundOption: arroundOption,
      userLat: localization.coordinates.lat,
      userLng: localization.coordinates.lng,
    };
    // encrypt data for fetch
    const ciphertext = encryptFrontData(
      frontDataToEncrypt,
      setErrorFromCrypto,
      null,
      "GetShops.js"
    );
    fetch(`${API_URL}/api-studio/shops/getShops`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({
        data: ciphertext,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        // decrypt data from response
        return decryptFrontData(
          result.data,
          setErrorFromCrypto,
          null,
          "GetShops.js"
        );
      })
      .then((data) => {
        if (data) {
          if (data.error) setErrorFromData(data.error);
          const shopsFromApi = data.data.map((shop) => shop.item);
          setLoading(false);
          if (!shopsFromApi.length) {
            setErrorFromData(
              "Brak wyników-spróbuj zwiększyć zakres poszukiwań "
            );
            setPartners("");
            deleteShopsNearUser();
          } else {
            setErrorFromData("");
            setPartnersArroundUser(data.data);
            setPartners(shopsFromApi);
          }
        }
      });
  }, [
    arroundOption,
    name,
    localization?.coordinates?.lat,
    localization?.coordinates?.lng,
    updatetShopId,
  ]);

  const markData = (partners, name) => {
    if (partners.length) {
      const checkIconForOurPartnet = (name) => {
        const nameTrim = name.trim().toLowerCase();
        return nameTrim.split(" ").join("-");
      };

      return partners.map((item, index) => {
        const afterTrimName = checkIconForOurPartnet(item.name);

        const partnerPoint = new L.Icon({
          iconUrl: `/static/assets/media/brands/${afterTrimName}.png`,
          iconRetinaUrl: `/static/assets/media/brands/${afterTrimName}.png`,
          iconAnchor: [20, 40],
          popupAnchor: [0, -35],
          iconSize: [40, 40],
          shadowSize: [29, 40],
          shadowAnchor: [7, 40],
        });

        return (
          <Marker position={item.position} key={index} icon={partnerPoint}>
            <Popup>
              {item.name.toUpperCase()} <br />
              {item.city && item.postCode && `${item.postCode} - `}
              {item.city && item.city} <br />
              {item.street
                ? `ul: ${item.street} ${item.number || ""}`
                : null}{" "}
              <br />
              {item.city && item.street && item.number ? (
                <ActionWraper>
                  <a
                    // href={`https://www.google.pl/maps/place/${item.street} ${
                    //   item.number
                    // },${item.city || ""}`}
                    href={`https://www.google.com/maps/dir/?api=1&origin=${
                      localization.coordinates.lat
                    },${localization.coordinates.lng}&destination=${
                      item.street
                    } ${item.number},${item.city || ""}&travelmode=car`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {"Wyznacz trasę"}
                    {/* <Car style={styleForIcon} /> */}
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="car-alt"
                      className="svg-inline--fa fa-car-alt fa-w-15"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 480 512"
                      style={styleForIcon}
                      width={20}
                    >
                      <path fill="currentColor" d={carPath}></path>
                    </svg>
                  </a>
                </ActionWraper>
              ) : (
                <ActionWraper>
                  <a
                    href={`https://www.google.com/maps/dir/?api=1&origin=${localization.coordinates.lat},${localization.coordinates.lng}&destination=${item.position}&travelmode=car`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {"Wyznacz trasę"}
                    {/* <Car style={styleForIcon} /> */}
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="car-alt"
                      className="svg-inline--fa fa-car-alt fa-w-15"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 480 512"
                      style={styleForIcon}
                      width={20}
                    >
                      <path fill="currentColor" d={carPath}></path>
                    </svg>
                  </a>
                </ActionWraper>
              )}
              <br />
              {item.openingHours && `Godziny otwarcia:`}
              {item.openingHours &&
                item.openingHours.map((item, index) => (
                  <p key={index}>{item}</p>
                ))}
              <br />
              {item.phone && (
                <ActionWraper>
                  <a
                    href={`tel:${item.phone}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`tel: ${item.phone}`}
                    {/* <PhoneCall style={styleForIcon} /> */}
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="phone-square"
                      className="svg-inline--fa fa-phone-square fa-w-14"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      style={styleForIcon}
                      width={20}
                    >
                      <path fill="currentColor" d={phonePath}></path>
                    </svg>
                  </a>
                </ActionWraper>
              )}
              <br />
              <ActionWraper>
                <div
                  onClick={(e) => {
                    // console.log(item, map, "edit");
                    map?.current?.leafletElement?._popup?._closeButton?.click();
                    shopUpdateOpen();
                    setShopToUpdate(item);
                  }}
                >
                  <SvgDisplay
                    viewBox={pen.viewBox}
                    d={pen.d}
                    width={20}
                    style={{ marginBottom: "5px" }}
                    fill={Theme.light.thirdColor.dark}
                  />
                  <p style={{ color: Theme.light.secondaryColor.green }}>
                    Edytuj
                  </p>
                </div>
                <div
                  onClick={() => {
                    map?.current?.leafletElement?._popup?._closeButton?.click();
                    alertify.confirm(
                      "Potwierdź usunięcie",
                      `Usuwasz sklep ${item?.name}`,
                      async function () {
                        deleteShop(item, "shops/delete").then((confirm) => {
                          if (confirm) {
                            setUpdatetShopId(item?._id);
                            alertify.success(`Usunięto sklep ${item?.name}`);
                          } else {
                            alertify.set("notifier", "position", "bottom-left");
                            alertify.error("Coś poszło nie tak");
                          }
                        });
                      },
                      function () {
                        alertify.error("Anulowano");
                      }
                    );
                  }}
                >
                  <SvgDisplay
                    viewBox={trash.viewBox}
                    d={trash.d}
                    width={20}
                    style={{ marginBottom: "5px" }}
                    fill={Theme.light.thirdColor.dark}
                  />
                  <p>Usuń</p>
                </div>
              </ActionWraper>
            </Popup>
          </Marker>
        );
      });
    }
  };
  return (
    <>
      <ErrorBoundary>
        {partners.length ? markData(partners, name) : null}

        {/* {!errorFromData && partners.length < 1 ? <Spinner /> : null} */}
        {loading && <Spinner />}

        {errorFromData && (
          <div
            style={{
              width: "100%",
              height: "40px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
              zIndex: "999",
              lineHeight: "40px",
              textAlign: "center",
              fontSize: "30px",
              color: "#BE1E2D",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            {errorFromData}
          </div>
        )}
      </ErrorBoundary>
    </>
  );
};

const mapStateToProps = (state) => ({
  select: state.select,
  localization: state.localization,
});
const mapDisaptchToProps = (dispatch) => ({
  setPartnersArroundUser: (item) => dispatch(setPartnersArroundUser(item)),
  deleteShopsNearUser: (item) => dispatch(deleteShopsNearUser(item)),
  shopUpdateOpen: () => dispatch(shopUpdateOpen()),
  setShopToUpdate: (item) => dispatch(setShopToUpdate(item)),
});
export const GetShops = connect(mapStateToProps, mapDisaptchToProps)(_GetShops);
