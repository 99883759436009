import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  HeaderWrapper,
  bellStyle,
  MenuBarWrapper,
  LogoWraper,
} from "./Header.style";
import { bell, bars } from "../../assets/media/svgPath/svgPath";
import { SvgDisplay } from "../../tools/svgDisplay/SvgDisplay";
import { Theme } from "../../store/theme/theme";
import { ThemeSwitcher } from "./themeSwitcher/ThemeSwitcher";
import { menuOpen } from "../../store/theme/duck/actions/actions";
import logoIcon from "../../../src/assets/media/svg/cenniczek_svg_logo_tiny.svg";
import history from "../../utils/history";

const Header = ({ user, theme, menuOpen }) => {
  return (
    <HeaderWrapper>
      <LogoWraper
        onClick={() => {
          history.push("/studio/dashboard");
        }}
      >
        <img src={logoIcon} alt="logo-icon" />
      </LogoWraper>
      <p>{user?.name ? `Witaj ${user?.name}` : "Witaj ponownie"}</p>
      <ThemeSwitcher />
      <SvgDisplay
        viewBox={bell.viewBox}
        d={bell.d}
        style={bellStyle}
        width={25}
        fill={
          theme?.isDark
            ? Theme.dark.thirdColor.white
            : Theme.light.thirdColor.dark
        }
        onClickCB={() => {
          history.push("/studio/messages");
        }}
      />
      <MenuBarWrapper isMenuOpen={theme?.isMenuOpen} onClick={() => menuOpen()}>
        <SvgDisplay
          viewBox={bars.viewBox}
          d={bars.d}
          width={25}
          fill={
            theme?.isDark
              ? Theme.dark.thirdColor.white
              : Theme.light.thirdColor.dark
          }
        />
      </MenuBarWrapper>
    </HeaderWrapper>
  );
};
const mapDispatchToProps = (dispatch) => ({
  menuOpen: () => dispatch(menuOpen()),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);
