import { useEffect, useState } from "react";
import { API_URL } from "../../constant/constant";
import { decryptFrontData } from "../../utils/Crypto/cryptoFront.controller";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/default.css";
import history from "../../utils/history";

const useMainDashBoardController = (user) => {
  const [userData, setUserData] = useState({});
  const [errorFromCrypto, setErrorFromCrypto] = useState("");
  useEffect(() => {
    if (!user?.userLoged) {
      fetch(`${API_URL}/api-studio/user-exist`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      })
        .then((response) => response.json())
        .then((result) => {
          return decryptFrontData(
            result.data,
            setErrorFromCrypto,
            null,
            "MainDashboard.js"
          );
        })
        .then((data) => {
          if (data) {
            const toLogin = {
              userLoged: data?.userLoged,
              email: data?.email,
              name: data?.name,
              represents: data?.user?.represents,
              interests: data?.user?.interests,
              avatar: data?.user?.avatar,
            };
            if (
              data?.userLoged &&
              data?.email &&
              data?.interests &&
              data?.user?.representsConfirmed &&
              !data?.error
            ) {
              // userSignInService(toLogin);
              setUserData(toLogin);
              alertify.set("notifier", "position", "bottom-left");
              alertify.success(`Jesteś zalogowany`);
            } else if (data?.error) {
              alertify.set("notifier", "position", "top-center");
              alertify.alert(data.error);
              history.push("/login");
            }
          } else if (errorFromCrypto) {
            alertify.set("notifier", "position", "top-center");
            alertify.alert(errorFromCrypto);
            history.push("/login");
          } else {
            alertify.set("notifier", "position", "top-center");
            alertify.alert("Coś poszło nie po naszej myśli");
            history.push("/login");
          }
        })
        .catch((err) => {
          alertify.set("notifier", "position", "top-center");
          alertify.alert(err);
          history.push("/login");
        });
    }
  }, [user?.userLoged, errorFromCrypto]);
  return userData;
};
export default useMainDashBoardController;
