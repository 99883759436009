import styled from "styled-components";
import { Theme } from "../../store/theme/theme";

export const WrapperDatePicker = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
export const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  & > p {
    margin-bottom: 10px;
  }
`;
export const ConentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  & > img {
    width: 180px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -80%);
  }
  & > h3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 250%);
  }
  @media${Theme.device.mobileL} {
    & > img {
      width: 100px;
      transform: translate(-50%, -80%);
    }
    & > h3 {
      transform: translate(-50%, 80%);
    }
  }
`;
export const MainMetricsWrapper = styled.div`
  width: 70%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
  @media${Theme.device.mobileL} {
    width: 100%;
  }
`;
export const ActionWrapper = styled.div`
  width: 70%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  & > p {
    color: red;
  }
`;
