export const calculatePxOfCutImage = (imgDimensions, crop, imgRefDimension) => {
  const containerSize = 20;

  const xStart =
    crop.x * (imgDimensions.width / imgRefDimension.width) - containerSize;
  const yStart =
    crop.y * (imgDimensions.height / imgRefDimension.height) - containerSize;

  return {
    x: parseInt(xStart),
    y: parseInt(yStart),
  };
};
