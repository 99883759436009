import React, { useState, useEffect, useRef } from "react";
import {
  DataForAIPost,
  styleForActiveLigth,
  styleForActiveDark,
  styleForDisactiveDark,
  styleForDisactiveLight,
  InputModalItems,
  animationInputVariants,
} from "./style";
const downPath =
  "M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z";

const AIBrandPost = ({ AIPost, brandPostByAI, setBrandPostByAI, isDark }) => {
  const [optionsActive, setOptionsActive] = useState(true);
  return (
    <DataForAIPost isDark={isDark} optionsActive={true}>
      {/* <div>
        <section>
          <div>
            <p>Tytuł</p>
            <p>{AIPost?.title || "brak danych"}</p>
          </div>
          <div>
            <p>Intro</p>
            <p>{AIPost?.intro || "brak danych"}</p>
          </div>
          <div>
            <p>Tekst</p>
            <p>{AIPost?.content[0].text || "brak danych"}</p>
          </div>
        </section>
      </div> */}

      <div>
        <p>Tytuł</p>
        <textarea
          rows="2"
          placeholder="Tytuł"
          value={AIPost?.title || ""}
          onChange={(e) =>
            setBrandPostByAI({ ...brandPostByAI, title: e.target.value })
          }
        ></textarea>
      </div>
      <div>
        <p>Intro</p>
        <textarea
          rows="5"
          placeholder="Intro"
          value={AIPost?.intro || ""}
          onChange={(e) =>
            setBrandPostByAI({ ...brandPostByAI, intro: e.target.value })
          }
        ></textarea>
      </div>
      <div>
        <p>Tekst</p>
        <textarea
          rows="20"
          placeholder="Text"
          value={AIPost?.content[0].text}
          onChange={(e) => {
            const text = [{ text: e.target.value }];
            setBrandPostByAI({ ...brandPostByAI, content: text });
          }}
        ></textarea>
      </div>
    </DataForAIPost>
  );
};
export default AIBrandPost;
