import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
// import { ReactComponent as ImgSuspense } from "../../media/svg/image-regular.svg";
// import { ReactComponent as StartTime } from "../../media/svg/calendar-check-solid.svg";
// import { ReactComponent as EndTime } from "../../media/svg/calendar-times-solid.svg";
import { Theme } from "../../store/theme/theme";
import { motion, AnimatePresence } from "framer-motion";
import { API_URL } from "../../constant/constant";
import { Spinner } from "../../tools/spinner/Spinner";
import { SvgDisplay } from "../../tools/svgDisplay/SvgDisplay";
import { plus } from "../../assets/media/svgPath/svgPath";
const imagePath =
  "M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 336H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v276a6 6 0 0 1-6 6zM128 152c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zM96 352h320v-80l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L192 304l-39.515-39.515c-4.686-4.686-12.284-4.686-16.971 0L96 304v48z";
const startPath =
  "M436 160H12c-6.627 0-12-5.373-12-12v-36c0-26.51 21.49-48 48-48h48V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h128V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h48c26.51 0 48 21.49 48 48v36c0 6.627-5.373 12-12 12zM12 192h424c6.627 0 12 5.373 12 12v260c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V204c0-6.627 5.373-12 12-12zm333.296 95.947l-28.169-28.398c-4.667-4.705-12.265-4.736-16.97-.068L194.12 364.665l-45.98-46.352c-4.667-4.705-12.266-4.736-16.971-.068l-28.397 28.17c-4.705 4.667-4.736 12.265-.068 16.97l82.601 83.269c4.667 4.705 12.265 4.736 16.97.068l142.953-141.805c4.705-4.667 4.736-12.265.068-16.97z";
const endPath =
  "M436 160H12c-6.6 0-12-5.4-12-12v-36c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48v36c0 6.6-5.4 12-12 12zM12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm257.3 160l48.1-48.1c4.7-4.7 4.7-12.3 0-17l-28.3-28.3c-4.7-4.7-12.3-4.7-17 0L224 306.7l-48.1-48.1c-4.7-4.7-12.3-4.7-17 0l-28.3 28.3c-4.7 4.7-4.7 12.3 0 17l48.1 48.1-48.1 48.1c-4.7 4.7-4.7 12.3 0 17l28.3 28.3c4.7 4.7 12.3 4.7 17 0l48.1-48.1 48.1 48.1c4.7 4.7 12.3 4.7 17 0l28.3-28.3c4.7-4.7 4.7-12.3 0-17L269.3 352z";
const ImgForSuspenseStyle = styled(motion.div)`
  width: 90%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  color: ${({ isDark }) =>
    !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  // color:${Theme.light.thirdColor.dark};
`;
const imgSuspenseVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
};
const divVariants = {
  hidden: {
    opacity: 0,
    scale: 1.2,
  },
  visible: (index) => ({
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.3,
      delay: index * 0.01,
    },
  }),
  exit: {
    opacity: 0,
    scale: 1.05,
    transition: {
      duration: 0.01,
    },
  },
};
const imgSuspenseStyle = {
  width: "90%",
};
const DivWrappper = styled(motion.article)`
  & > a {
    width: 200px;
    height: 350px;
    margin: 20px;
    border: 1px solid
      ${({ isDark }) => (isDark ? "black" : Theme.dark.thirdColor.white)};
    border-radius: 12px;
    background: ${({ isDark }) =>
      isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    transition: 0.1s;
    text-align: center;
    text-transform: uppercase;
    transform: scale(1);
    cursor: ${({ pre }) => (pre ? "unset" : "pointer")};
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    box-shadow: 0px 7px 29px 0px
      ${({ isDark }) =>
        isDark ? Theme.dark.eightColor.dark : Theme.light.eightColor.ligthGray};
  }
  & > a:hover {
    transform: scale(1.01);
    box-shadow: 1px 1px 15px
      ${({ isDark }) =>
        isDark ? Theme.dark.eightColor.dark : Theme.light.thirdColor.dark};
  }
  & > a > div {
    width: 100%;
    height: 65%;
    position: relative;
    overflow: hidden;
  }
  & > a > div > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.3s;
    opacity: ${({ optActive, addProduct }) =>
      optActive && addProduct ? "0" : "1"};
  }
  & > a > div > p {
    transition: 0.3s;
    opacity: ${({ optActive }) => (optActive ? "1" : "0")};
  }
  & > a > div > div > div > h6 {
    font-weight: normal;
    font-size: 16px;
  }
  & > a > div > div > div {
    display: flex;
  }
  &:last-of-type {
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &:last-of-type:hover {
    transform: scale(1);
    box-shadow: none;
    cursor: auto;
  }
  & > a > div:last-of-type {
    height: ${({ pre }) => {
      return pre ? "50% !important" : "35% !important";
    }};
    background: ${({ isDark }) =>
      isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
    z-index: 10;
    margin: 0 !important;
  }
  & > a > div > div > h3 {
    margin-top: 10px;
  }

  @media${Theme.device.mobileL} {
    & > a {
      margin: 12px;
      width: 165px;
      height: 320px;
    }
  }
  @media${Theme.device.mobileM} {
    & > a {
      margin: 7px;
      width: 160px;
      height: 320px;
    }
    & > a > div > div > img {
      bottom: 10px !important;
      left: 10px !important;
      height: 55px !important;
    }
    & > a > div > div {
      left: 70px !important;
    }
    & > a > div > div > h3 {
      margin-top: 5px;
    }
  }
  @media${Theme.device.mobileXS} {
    & > a {
      width: 124px;
      height: 290px;
      margin: 5px;
    }

    & > a > div > div > img {
      bottom: 15px !important;
      left: 0 !important;
      height: 42px !important;
    }
    & > a > div > div {
      left: 50px !important;
      bottom: 10px !important;
    }
    & > a > div > div > div > h3 {
      font-size: 12px !important;
    }
  }
`;
const calculateDelta = (
  { touchCordinates, setTouchCordinates },
  { optActive, setOptActive },
  { eventStart, eventEnd },
  msLongPress
) => {
  const acceptedDelta = 5;
  const endEventTimer = +new Date();
  const touchXEnd =
    eventEnd?.changedTouches && eventEnd?.changedTouches[0]?.pageX;
  const touchYEnd =
    eventEnd?.changedTouches && eventEnd?.changedTouches[0]?.pageY;
  const mouseXEnd = eventEnd?.pageX;
  const mouseYEnd = eventEnd?.pageY;

  const touchXStart = eventStart?.touches && eventStart?.touches[0]?.pageX;
  const touchYStart = eventStart?.touches && eventStart?.touches[0]?.pageY;
  const mouseXStart = eventStart?.pageX;
  const mouseYStart = eventStart?.pageY;
  const clearTouchCordinatesState = () =>
    setTouchCordinates({
      touchStartTime: null,
      touchXStart: null,
      touchYStart: null,
      mouseStartTime: null,
      mouseXStart: null,
      mouseYStart: null,
    });

  if (touchXStart && touchYStart && !touchXEnd && !touchYEnd) {
    console.log("finger touchLong occurs during user press");
    if (!optActive) {
      setOptActive(true);
      clearTouchCordinatesState();
    }
    return;
  }
  if (mouseXStart && mouseYStart && !mouseXEnd && !mouseYEnd) {
    console.log("mouse longPress occurs during user press");
    if (!optActive) {
      setOptActive(true);
      clearTouchCordinatesState();
    }
    return;
  }

  if (
    touchCordinates?.touchXStart &&
    touchCordinates?.touchYStart &&
    touchXEnd &&
    touchYEnd
  ) {
    if (
      !optActive &&
      endEventTimer - touchCordinates?.touchStartTime >= msLongPress
    ) {
      const deltaX = Math.abs(touchCordinates?.touchXStart - touchXEnd);
      const deltaY = Math.abs(touchCordinates?.touchYStart - touchYEnd);
      if (deltaX <= acceptedDelta && deltaY <= acceptedDelta) {
        console.log("finger touchLong occurs after finger out");
        setOptActive(true);
        clearTouchCordinatesState();
      } else {
        console.log("touch event swapp detected");
        clearTouchCordinatesState();
      }
    } else if (
      !optActive &&
      endEventTimer - touchCordinates?.touchStartTime < msLongPress
    ) {
      console.log("finger event to short ");
      clearTouchCordinatesState();
    }
    return;
  }
  if (
    touchCordinates?.mouseXStart &&
    touchCordinates?.mouseYStart &&
    mouseXEnd &&
    mouseYEnd
  ) {
    if (
      !optActive &&
      endEventTimer - touchCordinates?.mouseStartTime >= msLongPress
    ) {
      const deltaX = Math.abs(touchCordinates?.mouseXStart - mouseXEnd);
      const deltaY = Math.abs(touchCordinates?.mouseYStart - mouseYEnd);
      if (deltaX <= acceptedDelta && deltaY <= acceptedDelta) {
        console.log("mouse longPress occurs after key mouse out");
        setOptActive(true);
        clearTouchCordinatesState();
      } else {
        console.log("mouse event swapp detected");
        clearTouchCordinatesState();
      }
    } else if (
      !optActive &&
      endEventTimer - touchCordinates?.mouseStartTime < msLongPress
    ) {
      console.log("mouse press to short");
      clearTouchCordinatesState();
    }
    return;
  }
};
export const SingleItem = ({
  item,
  isDark,
  index,
  isMobile,
  pre,
  addProduct,
}) => {
  const msLongPress = 200;
  const [isLoaded, setIsLoaded] = useState(false);
  const [optActive, setOptActive] = useState(false);
  const [touchCordinates, setTouchCordinates] = useState({
    touchStartTime: null,
    touchXStart: null,
    touchYStart: null,
    mouseStartTime: null,
    mouseXStart: null,
    mouseYStart: null,
  });
  useEffect(() => {
    return () => {
      setTouchCordinates({
        touchStartTime: null,
        touchXStart: null,
        touchYStart: null,
        mouseStartTime: null,
        mouseXStart: null,
        mouseYStart: null,
      });
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (optActive) {
        setOptActive(false);
      }
    }, 3000);
  }, [optActive]);

  return (
    <AnimatePresence exitBeforeEnter>
      <DivWrappper
        key={item.id}
        variants={divVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        custom={index}
        whileTap={{ scale: 0.95 }}
        isDark={isDark}
        optActive={optActive}
        addProduct={addProduct}
        pre={pre}
        onMouseOver={() => {
          if (!optActive) {
            setOptActive(true);
          }
        }}
        onMouseLeave={() => {
          if (optActive) {
            setOptActive(false);
          }
        }}
        onTouchStart={(e) => {
          setTouchCordinates({
            mouseXStart: null,
            mouseYStart: null,
            mouseStartTime: null,
            touchStartTime: +new Date(),
            touchXStart: e?.touches[0]?.pageX,
            touchYStart: e?.touches[0]?.pageY,
          });
          // if (!optActive) {
          //   setOptActive(true);
          // }
        }}
        onTouchEnd={(e) => {
          calculateDelta(
            { touchCordinates, setTouchCordinates },
            {
              optActive,
              setOptActive,
            },
            {
              eventEnd: e,
            },
            msLongPress
          );
        }}
      >
        <Link
          to={
            !pre && !addProduct
              ? {
                  pathname: `https://cenniczek.com/gazetka-promocyjna/${item.name}-${item.alternativeFinalName}/${item.startDuringTime}-${item.endDuringTime}/1`,
                }
              : !pre &&
                `/studio/gazetka-promocyjna/${item.name}-${item.alternativeFinalName}/${item.startDuringTime}-${item.endDuringTime}/1`
          }
          target={(addProduct && "_self") || (pre && "_self") || "_blank"}
          onClick={() => {
            // clearSelectPageFromDeal();
            // newspaperId(item.id);
            // console.log({ item, pre });
          }}
          disabled={item?.txtRecognizing ? "disabled" : ""}
          style={
            item?.txtRecognizing
              ? { position: "relative" }
              : { position: "static" }
          }
          alt={item?.txtRecognizing ? "trwa rozpoznawanie" : ""}
        >
          {item?.txtRecognizing && (
            <Spinner
              small={true}
              cut={true}
              txt={item?.txtRecognizing ? "trwa rozpoznawanie" : ""}
            />
          )}
          {isLoaded ? null : (
            <AnimatePresence>
              <ImgForSuspenseStyle
                isLoaded={isLoaded}
                variants={imgSuspenseVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                isDark={isDark}
              >
                {/* <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="far"
                  data-icon="image"
                  className="svg-inline--fa fa-image fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  style={imgSuspenseStyle}
                  width={30}
                >
                  <path fill="currentColor" d={imagePath}></path>
                </svg> */}
                <SvgDisplay
                  viewBox={"0 0 512 512"}
                  d={imagePath}
                  width={100}
                  style={{ marginTop: "30px" }}
                  fill={
                    isDark
                      ? Theme.dark.fiveColor.white
                      : Theme.dark.sixColor.dark
                  }
                />
              </ImgForSuspenseStyle>
            </AnimatePresence>
          )}

          <div
            style={{
              position: "absolute",
              left: "0",
              top: "0",
              filter: `${item?.txtRecognizing ? "grayscale(.8)" : ""}`,
            }}
          >
            {!pre && (
              <img
                src={
                  item.thumbnail
                    ? `${API_URL}${item.thumbnail}`
                    : `${API_URL}${item.imgUrl}`
                }
                onLoad={() => setIsLoaded(true)}
                alt={`gazetka ${item.name} czas obowiązywania od ${item.startDuringTime} do ${item.endDuringTime}`}
              />
            )}
            {!pre && addProduct && optActive && (
              <p>
                <SvgDisplay
                  viewBox={plus.viewBox}
                  d={plus.d}
                  width={60}
                  style={{ marginTop: "50px", marginBottom: "30px" }}
                  fill={
                    isDark
                      ? Theme.dark.fiveColor.white
                      : Theme.dark.sixColor.dark
                  }
                />
                <p>Dodaj produkt</p>
              </p>
            )}
          </div>
          <div
            style={{
              width: "100%",
              height: `${isMobile ? "35%" : "30%"}`,
              display: "flex",
              justifyContent: "space-around",
              margin: "5px",
              position: "absolute",
              left: "0",
              bottom: "0",
              zIndex: "2",
            }}
          >
            <div>
              <h3
                style={{
                  fontWeight: "normal",
                  fontSize: "16px",
                  overflow: "hidden",
                }}
              >
                gazetka {item.name}
                {pre && " " + item?.alternativeFinalName}
              </h3>
            </div>
            <div>
              <img
                src={`/static/assets/media/brands/${item?.name
                  .split(" ")
                  .join("-")}.png`}
                alt={`gazetka ${item.name} obowiązująca od ${item.startDuringTime} do ${item.endDuringTime}`}
                style={{
                  height: "60px",
                  position: "absolute",
                  left: "5px",
                  bottom: "5px",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "absolute",
                left: "80px",
                bottom: "10px",
              }}
            >
              {item?.status === "active" && (
                <div
                  style={{
                    color: "green",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  <h3
                    style={{
                      color: "green",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    aktualna
                  </h3>
                </div>
              )}
              {item?.status === "future" && (
                <div
                  style={{
                    color: "blue",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  <h3
                    style={{
                      color: "blue",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    następna
                  </h3>
                </div>
              )}
              {pre && (
                <div
                  style={{
                    color: "blue",
                    fontSize: "15px",
                    fontWeight: "bold",
                  }}
                >
                  <h3
                    style={{
                      color: "blue",
                      fontSize: "15px",
                      fontWeight: "bold",
                    }}
                  >
                    oczekuje
                  </h3>
                </div>
              )}

              <div style={{ textAlign: "left" }}>
                <span>
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="calendar-check"
                    className="svg-inline--fa fa-calendar-check fa-w-14"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    style={{
                      width: "13px",
                      marginRight: "10px",
                      textAlign: "right",
                    }}
                    width={13}
                  >
                    <path fill="currentColor" d={startPath}></path>
                  </svg>
                </span>
                <h6>
                  {item.startDuringTime.slice(
                    0,
                    item.startDuringTime.length - 5
                  )}
                </h6>
              </div>
              <div style={{ textAlign: "left" }}>
                <span>
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="calendar-times"
                    className="svg-inline--fa fa-calendar-times fa-w-14"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    style={{ width: "13px", marginRight: "10px" }}
                    width={13}
                  >
                    <path fill="currentColor" d={endPath}></path>
                  </svg>
                </span>
                <h6>
                  {item.endDuringTime.slice(0, item.endDuringTime.length - 5)}
                </h6>
              </div>
            </div>
          </div>
        </Link>
      </DivWrappper>
    </AnimatePresence>
  );
};
