import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import controller from "../../helpers/usePermissionController";
import { MainMsgWrapper } from "./Messages.style";
import screwdriver from "../../assets/media/svg/screwdriver-wrench-solid.svg";

const Messages = ({ user, theme }) => {
  useEffect(() => {
    controller(user);
  }, []);
  return (
    <MainMsgWrapper>
      <img src={screwdriver} />
      <p>Strona w budowie</p>
    </MainMsgWrapper>
  );
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.user,
});
export default connect(mapStateToProps, {})(Messages);
