import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { connect } from "react-redux";
import controller from "../../helpers/usePermissionController";
import TopDealsCenniczek from "../topDeals/TopDeals.Cenniczek";
import TopDealsContractor from "../topDeals/TopDeals.Contractor";
import { CENNICZEK, CONTRACTOR } from "../topDeals/loadDeals/loadDeals.type";
import { Modal } from "../modal/Modal";
const Products = ({ user, theme, select }) => {
  useEffect(() => {
    controller(user);
  }, []);
  const [topDealUpdatedID, setTopDealUpdatedID] = useState("");
  const [topDealUpdatedTimestamp, setTopDealUpdatedTimestamp] = useState(null);

  const topDealsSelectedName = select?.topDealsSelected?.name;
  const portalContainer =
    typeof window !== "undefined" && document.getElementById("root");
  return (
    <>
      {theme.isTopDealsUpdateOpen &&
        portalContainer?.children?.length > 0 &&
        createPortal(
          <Modal
            setTopDealUpdatedID={setTopDealUpdatedID}
            setTopDealUpdatedTimestamp={setTopDealUpdatedTimestamp}
          />,
          portalContainer
        )}
      <p style={{ marginBottom: "20px" }}>Promowane produkty</p>
      {user?.represents === "cenniczek" ? (
        <TopDealsCenniczek
          dealsType={CENNICZEK}
          topDealsSelectedName={topDealsSelectedName}
          topDealUpdatedID={topDealUpdatedID}
          setTopDealUpdatedID={setTopDealUpdatedID}
          setTopDealUpdatedTimestamp={setTopDealUpdatedTimestamp}
          topDealUpdatedTimestamp={topDealUpdatedTimestamp}
        />
      ) : (
        <TopDealsContractor
          dealsType={CONTRACTOR}
          topDealsSelectedName={topDealsSelectedName}
          topDealUpdatedID={topDealUpdatedID}
          setTopDealUpdatedID={setTopDealUpdatedID}
          setTopDealUpdatedTimestamp={setTopDealUpdatedTimestamp}
          topDealUpdatedTimestamp={topDealUpdatedTimestamp}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.user,
  select: state.select,
});
export default connect(mapStateToProps, {})(Products);
