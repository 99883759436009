import { Theme } from "../../store/theme/theme";
import { motion } from "framer-motion";
import styled from "styled-components";

export const MainSettingsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 50%;
  @media${Theme.device.mobileL} {
    justify-content: flex-start;
    width: 100%;
  }
`;
export const SingleItemInfo = styled(motion.div)`
  width: 70%;
  margin-top: 30px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  user-select: none;
  & > div {
    width: 70%;
    margin: 10px;
  }
  & > div:first-of-type {
    position: relative;
  }
  & > div > p:first-of-type {
    font-weight: bold;
    font-size: 12px;
    color: RGB(172 172 170);
  }
  & > div > form > div > input {
    display: block;
    width: 95%;
    font-size: 1em;
    padding: 5px;
    margin: 2px;
    border: none;
    box-shadow: 0px 7px 29px 0px
      ${({ isDark }) =>
        isDark ? Theme.dark.eightColor.dark : Theme.light.eightColor.ligthGray};
    border-radius: 12.5px;
    transition: background 1s;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
    background: ${({ isDark }) =>
      isDark ? Theme.dark.fiveColor.white : Theme.light.primaryColor.white};
    padding-left: 10px !important;
  }
  & > div > form > div > input::placeholder {
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  }
  & > div > form > button {
    width: 80px;
    height: 30px;
    margin: 5px;
    border-radius: 5px;
    text-transform: uppercase;
    background-color: ${Theme.light.secondaryColor.green};
    border: 1px solid black;
    color: ${Theme.dark.thirdColor.white};
    transition: 0.3s;
    transform: scale(1);
    user-select: none;
    cursor: pointer;
  }
  & > form > button:hover {
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
    transform: scale(1.1);
  }
`;
export const BrandImg = styled(motion.div)`
  width: 25%;
  margin-top: 30px;
  height: 180px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  & > img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
  }
  @media${Theme.device.mobileL} {
    height: 100px;
  }
`;
export const InputModalItems = styled(motion.div)`
  width: 100%;
`;

export const downPath =
  "M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z";

export const styleForActiveLigth = {
  position: "absolute",
  top: "-5px",
  right: "0",
  width: "15px",
  transition: "0.3s all",
  color: `${Theme.dark.sixColor.dark}`,
  transform: "rotate(180deg)",
  cursor: "pointer",
};
export const styleForActiveDark = {
  position: "absolute",
  top: "-5px",
  right: "0",
  width: "15px",
  transition: "0.3s all",
  color: `${Theme.light.fiveColor.white}`,
  transform: "rotate(180deg)",
  cursor: "pointer",
};
export const styleForDisactiveLight = {
  position: "absolute",
  top: "-5px",
  right: "0",
  width: "15px",
  transition: "0.3s all",
  color: `${Theme.dark.sixColor.dark}`,
  transform: "rotate(0)",
  cursor: "pointer",
};
export const styleForDisactiveDark = {
  position: "absolute",
  top: "-5px",
  right: "0",
  width: "15px",
  transition: "0.3s all",
  color: `${Theme.light.fiveColor.white}`,
  transform: "rotate(0)",
  cursor: "pointer",
};
export const pVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};
export const animationInputVariants = {
  hidden: {
    opacity: 0,
    // scale:0,
    y: -10,
  },
  visible: {
    opacity: 1,
    // scale:1,
    y: 0,
    // transformOrigin:"bottom",
    transition: { duration: 0.5 },
  },
  exit: {
    opacity: 0,
    // scale:0,
    y: -10,
  },
};
