import React from "react";
import styled from "styled-components";
import { css } from "@emotion/core";
import PulseLoader from "react-spinners/PulseLoader";
import SpinnerLogo from "../../assets/media/svg/cenniczek_svg_logo_tiny.svg";
import { Theme } from "../../store/theme/theme";
const override = css`
  display: block;
  margin: 0 auto;
`;
const SpinnerWrapper = styled.div`
  width: ${({ small }) => (small ? "50%" : "100%")};
  height: ${({ small }) => (small ? "50%" : "100%")};
  position: absolute;
  top: ${({ small }) => (small ? "25%" : "50%")};
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 101;
  @media${Theme.device.mobileL} {
    top: ${({ small }) => (small ? "20%" : "50%")};
  }
  & > p {
    position: absolute;
    top: ${({ small }) => (small ? "75%" : "50%")};
    color: ${Theme.dark.primaryColor.dark};
    background: ${({ isDark, cut }) =>
      isDark && cut
        ? Theme.dark.sixColor.dark
        : !isDark && cut
        ? Theme.light.fiveColor.white
        : ""};
    border: ${({ isDark, cut }) =>
      isDark && cut
        ? `1px solid ${Theme.dark.primaryColor.dark}`
        : !isDark && cut
        ? "1px solid white"
        : ""};
  }
`;
const SpinnerIcons = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 101;
  background: ${({ isDark, cut }) =>
    isDark && cut
      ? Theme.dark.sixColor.dark
      : !isDark && cut
      ? Theme.light.fiveColor.white
      : ""};
  border: ${({ isDark, cut }) =>
    isDark && cut
      ? `1px solid ${Theme.dark.primaryColor.dark}`
      : !isDark && cut
      ? "1px solid white"
      : ""};
`;

export const Spinner = ({ small, cut, isDark, txt }) => {
  return (
    <SpinnerWrapper small={small} isDark={isDark} cut={cut}>
      <SpinnerIcons cut={cut} isDark={isDark}>
        <img src={SpinnerLogo} style={{ width: "40px", margin: "5px" }} />
        <div className="sweet-loading">
          <PulseLoader
            css={override}
            size={10}
            color={"#be1e2d"}
            loading={true}
          />
        </div>
      </SpinnerIcons>
      <p>{txt}</p>
    </SpinnerWrapper>
  );
};
