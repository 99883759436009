import React, { useEffect } from "react";
import { connect } from "react-redux";
import controller from "../../helpers/usePermissionController";
import { WrapperForNotFound } from "./NotFound.style";

const NotFound = ({ user, theme, select, dashboard }) => {
  useEffect(() => {
    controller(user);
  }, []);

  return (
    <WrapperForNotFound isDark={theme?.isDark} dashboard={dashboard}>
      <h3>404</h3>
      <p style={{ marginBottom: "20px" }}>Strona nie istnieje</p>
    </WrapperForNotFound>
  );
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.user,
  select: state.select,
});
export default connect(mapStateToProps, {})(NotFound);
