import React from "react";
import User from "../../assets/media/svg/user-solid.svg";
import { AvatarWrapper, ImgWrapper } from "./Avatar.style";
import { connect } from "react-redux";

const Avatar = ({ user, theme }) => {
  return (
    <AvatarWrapper>
      <ImgWrapper avatar={user?.avatar}>
        <img src={user?.avatar || User} />
      </ImgWrapper>
      <p>{user?.name?.toUpperCase()}</p>
      <p>Brand: {user?.represents?.toUpperCase()}</p>
    </AvatarWrapper>
  );
};
const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.user,
});
export default connect(mapStateToProps, {})(Avatar);
