import { useEffect, useState } from "react";
import { API_URL } from "../constant/constant";
import {
  decryptFrontData,
  encryptFrontData,
} from "../utils/Crypto/cryptoFront.controller";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/default.css";
import history from "../utils/history";

const useFetchWithObservableHook = ({ endPoint, method, body, user }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorFromCrypto, setErrorFromCrypto] = useState("");
  useEffect(() => {
    if (user?.userLoged) {
      const frontDataToEncrypt = { body };

      const ciphertext = encryptFrontData(
        frontDataToEncrypt,
        setErrorFromCrypto,
        null,
        `useFetchHook-${endPoint}`
      );

      if (!loading) {
        setLoading(true);

        fetch(`${API_URL}/api-studio/${endPoint}`, {
          method,
          body: JSON.stringify({
            data: ciphertext,
          }),
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        })
          .then((response) => response.json())
          .then((result) => {
            return decryptFrontData(
              result.data,
              setErrorFromCrypto,
              null,
              `useFetchHook-${endPoint}`
            );
          })
          .then((data) => {
            setLoading(false);
            setData(data);
          })
          .catch((err) => {
            alertify.set("notifier", "position", "top-center");
            alertify.alert(err);
            setLoading(false);
            //   history.push("/");
          });
      }
    }
  }, [
    user?.userLoged,
    body?.topDealsSelectedName,
    body?.offset,
    body?.limit,
    body?.sortType,
    body?.deletedItems?._id,
    body?.topDealUpdatedID,
    body?.topDealUpdatedTimestamp,
    ,
  ]);

  return { data, loading, errorFromCrypto };
};
export default useFetchWithObservableHook;
