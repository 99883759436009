import React, { useEffect } from "react";
import { connect } from "react-redux";
import controller from "../../helpers/usePermissionController";
import NewspapersCenniczek from "../newspapers/Newspaper.Cenniczek";
import NewspapersContractor from "../newspapers/Newspaper.Contractor";
import { TopDealsCarusel } from "../topDeals/topDealsCarusel/TopDealsCarusel";

const AddProduct = ({ user, theme, select }) => {
  useEffect(() => {
    controller(user);
  }, []);

  return (
    <>
      <p style={{ marginBottom: "20px" }}>Wybierz gazetkę i dodaj produkt</p>
      {user?.represents === "cenniczek" ? (
        <NewspapersCenniczek
          user={user}
          theme={theme}
          select={select}
          addProduct={true}
        />
      ) : (
        <NewspapersContractor
          user={user}
          theme={theme}
          select={select}
          addProduct={true}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.user,
  select: state.select,
});
export default connect(mapStateToProps, {})(AddProduct);
