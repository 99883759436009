import {
  encryptFrontData,
  decryptFrontData,
} from "../../utils/Crypto/cryptoFront.controller";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/default.css";
import { API_URL } from "../../constant/constant";
import { modalHelper } from "./Modal.helper";

export const modalController = {
  fetchForChangeListItem: (
    topDealsItem,
    setTopDealUpdatedID,
    setTopDealUpdatedTimestamp,
    window
  ) => {
    // console.log(topDealsItem, "update");
    const frontDataToEncrypt = {
      topDealsItem,
    };
    // encrypt data for fetch
    const ciphertext = encryptFrontData(
      frontDataToEncrypt,
      null,
      null,
      "Modal.fetchForChangeListItem.js"
    );
    fetch(`${API_URL}/api-studio/topDeals/updateTopDealsItem`, {
      method: "POST",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: ciphertext,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        // decrypt data from response
        return decryptFrontData(result.data, null, null, "Modal.js");
      })
      .then((data) => {
        // console.log(data);
        if (!data.ok) {
          alertify.set("notifier", "position", "bottom-left");
          alertify.error(`Przepraszamy coś poszło nie po naszej
                              myśli,${data.error}`);
        } else {
          alertify.set("notifier", "position", "bottom-left");
          alertify.success(`Aktualizacja produktu przeszła pomyślnie`);
          setTopDealUpdatedID && setTopDealUpdatedID(data?.updateTopDeals?._id);
          setTopDealUpdatedTimestamp && setTopDealUpdatedTimestamp(+new Date());
          // console.log(data?.updateTopDeals, "ModalUpdate");
          window?.location?.reload();
        }
      })
      .catch((err) => console.log(err));
  },
  generateAIPromoTxt: async (
    topDealsItem,
    setAiTxtGenerate,
    setBrandPostByAI
  ) => {
    const frontDataToEncrypt = {
      topDealsItem,
    };
    // encrypt data for fetch
    const ciphertext = encryptFrontData(
      frontDataToEncrypt,
      null,
      null,
      "Modal.generateAIPromoTxt.js"
    );

    fetch(`${API_URL}/api-studio/topDeals/generateAIPromoTxt`, {
      method: "POST",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: ciphertext,
      }),
    })
      .then((response) => response.json())
      .then((result) => {
        return decryptFrontData(
          result.data,
          null,
          null,
          "Modal.generateAIPromoTxt.js"
        );
      })
      .then((data) => {
        if (!data.ok) {
          alertify.set("notifier", "position", "bottom-left");
          alertify.error(`Przepraszamy coś poszło nie po naszej
                                  myśli,${data.error}`);
          setAiTxtGenerate(false);
        } else {
          setAiTxtGenerate(false);
          alertify.set("notifier", "position", "bottom-left");
          alertify.success(`Generowanie promo postu zakończone pomyślnie`);
          setBrandPostByAI(data?.brandPostByAI);
        }
      })
      .catch((err) => console.log(err));
  },
  publishPromoPost: async (brandPostByAI, window) => {
    alertify.set("notifier", "position", "bottom-left");
    alertify.success(`Rozpoczynamy publikacje postu ...`);

    const frontDataToEncrypt = {
      brandPostByAI,
    };
    // encrypt data for fetch
    const ciphertext = encryptFrontData(
      frontDataToEncrypt,
      null,
      null,
      "Modal.publishPromoPost.js"
    );

    fetch(`${API_URL}/api-studio/topDeals/publishPromoPost`, {
      method: "POST",
      cache: "no-cache",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: ciphertext,
      }),
    })
      .then((response) => {
        if (!response?.ok) {
          alertify.set("notifier", "position", "bottom-left");
          alertify.error(
            `Przepraszamy podczas publikacji coś poszło nie po naszej myśli`
          );
          return;
        }
        const reader = response.body.getReader();

        const readChunk = () => {
          return reader.read().then(({ done, value }) => {
            if (done) {
              console.log("done");
              setTimeout(() => {
                window?.location?.reload();
              }, 2000);
            } else {
              const chunk = new TextDecoder().decode(value);
              const [source, status] = chunk?.split("-");
              console.log(chunk, "chunk");

              switch (source) {
                case "blog": {
                  if (status === "ok") {
                    modalHelper.successPublising("blogu");
                    break;
                  } else {
                    modalHelper.errorPublishing("blogu");
                    break;
                  }
                }
                case "fb": {
                  if (status === "ok") {
                    modalHelper.successPublising("Facebooku");
                    break;
                  } else {
                    modalHelper.errorPublishing("Facebooku");
                    break;
                  }
                }
                case "ig": {
                  if (status === "ok") {
                    modalHelper.successPublising("Instagramie");
                    break;
                  } else {
                    modalHelper.errorPublishing("Instagramie");
                    break;
                  }
                }
                case "x": {
                  if (status === "ok") {
                    modalHelper.successPublising("X");
                    break;
                  } else {
                    modalHelper.errorPublishing("X");
                    break;
                  }
                }
                case "error": {
                  modalHelper.errorPublishing("naszych socialach");
                  break;
                }
              }

              return readChunk();
            }
          });
        };
        return readChunk();
      })
      .catch((err) => {
        console.log(err);
        alertify.set("notifier", "position", "bottom-left");
        alertify.error(
          `Przepraszamy podczas publikacji coś poszło nie po naszej myśli`
        );
      });
  },
};
