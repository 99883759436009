import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { createPortal } from "react-dom";
import controller from "../../helpers/usePermissionController";
import GetCities from "../getCities/GetCities";
import { ArroundSection } from "../arroundSection/ArroundSection";
import { MapContainer } from "../mapContainer/MapContainer";
import { ModalForShops } from "../modalForShops/ModalForShop";
import PaginationShops from "../paginationShops/PaginationShops";

const Shops = ({ user, theme }) => {
  const [updatetShopId, setUpdatetShopId] = useState(null);
  const portalContainer =
    typeof window !== "undefined" && document.getElementById("root");
  useEffect(() => {
    controller(user);
  }, []);
  return (
    <>
      {theme.isShopUpdateOpen &&
        portalContainer?.children?.length > 0 &&
        createPortal(
          <ModalForShops add={true} setUpdatetShopId={setUpdatetShopId} />,
          portalContainer
        )}
      <GetCities />
      <ArroundSection />
      <MapContainer
        updatetShopId={updatetShopId}
        setUpdatetShopId={setUpdatetShopId}
      />
      <PaginationShops
        itemsPerPage={10}
        updatetShopId={updatetShopId}
        setUpdatetShopId={setUpdatetShopId}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.user,
});
export default connect(mapStateToProps, {})(Shops);
