import React from "react";
import { connect } from "react-redux";
import SignOutImg from "../../../../assets/media/svg/right-from-bracket-solid.svg";
import { SingleOptionWrapper } from "../LeftColumn.style";
import { API_URL } from "../../../../constant/constant";
import { Theme } from "../../../../store/theme/theme";
import { userSignOutOfService } from "../../../../store/user/duck/actions/actions";
import { menuClose } from "../../../../store/theme/duck/actions/actions";

const SignOut = ({ user, theme, userSignOutOfService, menuClose }) => {
  return (
    <a
      href={`${API_URL}/api-studio/logout`}
      onClick={() => {
        userSignOutOfService();
        menuClose();
      }}
      style={{ color: Theme.light.primaryColor.white }}
    >
      <SingleOptionWrapper isDark={theme.isDark}>
        <img src={SignOutImg} alt="wyloguj" />
        <p>Wyloguj</p>
      </SingleOptionWrapper>
    </a>
  );
};
const mapDispatchToProps = (dispatch) => ({
  userSignOutOfService: () => dispatch(userSignOutOfService()),
  menuClose: () => dispatch(menuClose()),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(SignOut);
