import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import AddNewspaper from "../../../../assets/media/svg/upload-solid.svg";
import { SingleOptionWrapper } from "../LeftColumn.style";
import { menuClose } from "../../../../store/theme/duck/actions/actions";

const AddNewsPaper = ({ user, theme, menuClose }) => {
  const [isActive, setActive] = useState(false);
  let location = useLocation();
  useEffect(() => {
    if (location?.pathname === "/studio/addNewspaper") {
      setActive(true);
    } else {
      setActive(false);
    }
  }, [location?.pathname]);
  return (
    <Link to="/studio/addNewspaper" onClick={() => menuClose()}>
      <SingleOptionWrapper isDark={theme.isDark} isActive={isActive}>
        <img src={AddNewspaper} alt="dodaj gazetke promocyjna" />
        <p>Dodaj gazetkę</p>
      </SingleOptionWrapper>
    </Link>
  );
};

const mapDispatchToProps = (dispatch) => ({
  menuClose: () => dispatch(menuClose()),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(AddNewsPaper);
