import React, { useEffect } from "react";
import { Theme } from "../../../store/theme/theme";
import styled from "styled-components";
const walletPath =
  "M461.2 128H80c-8.84 0-16-7.16-16-16s7.16-16 16-16h384c8.84 0 16-7.16 16-16 0-26.51-21.49-48-48-48H64C28.65 32 0 60.65 0 96v320c0 35.35 28.65 64 64 64h397.2c28.02 0 50.8-21.53 50.8-48V176c0-26.47-22.78-48-50.8-48zM416 336c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z";
const calendar =
  "M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6 6h340c3.3 0 6-2.7 6-6z";
const MainTitleWrapper = styled.div`
  width: 27%;
  height: 100%;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  min-height: 240px;
  padding-bottom: 10px;
  box-shadow: 0px 7px 29px 0px
    ${({ isDark }) =>
      isDark ? Theme.dark.eightColor.dark : Theme.light.eightColor.ligthGray};
  @media${Theme.device.mobileL} {
    width: 100%;
    margin-left: 60px;
  }
`;
const MainTitleSingleContentWrapper = styled.div`
  width: 100%;
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  box-shadow: inset -1px -1px 3px
    ${({ isDark }) =>
      isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  border-radius: 5px;
  margin: 10px 0;
  & > h1 {
    text-align: left;
    padding: 0 5px;
    font-size: 28px;
  }
  & > h2 {
    text-align: left;
    padding: 15px 5px;
  }
  & > h1 > span {
    margin: 0 10px;
  }
  @media${Theme.device.tablet} {
    margin: 4px 0;
    & > h1,
    & > h2 {
      font-size: 20px;
    }
    & > h2 > p {
      font-size: 20px !important;
    }
  }
  @media${Theme.device.mobileL} {
    margin: 10px 0;
    & > h1,
    & > h2 {
      font-size: 28px;
    }
    & > h2 > p {
      font-size: 38px !important;
    }
  }
`;

export const MainTitle = ({ days, isDark, data }) => {
  const totalFromDays = days?.time?.shoping.map((item) => {
    if (item?.result?.total) {
      const resPrice = item.result.list.map((item) =>
        parseFloat(
          (
            (parseFloat(item.price) || 0) * (parseFloat(item.howMany) || 0)
          ).toFixed(2)
        )
      );
      return resPrice.reduce((total, item) => total + item, 0);
    } else {
      return 0;
    }
  });

  const summary = totalFromDays
    .reduce((total, item) => total + parseFloat(item ? item : 0), 0)
    .toFixed(2);

  const pStyle = {
    fontSize: "38px",
    fontWeight: "bold",
    color: `${
      isDark
        ? Theme.light.secondaryColor.green
        : Theme.light.secondaryColor.green
    }`,
    background: `${isDark ? "" : Theme.light.primaryColor.white}`,
    padding: "5px",
    borderRadius: "5px",
    margin: "15px",
  };
  return (
    <MainTitleWrapper isDark={isDark}>
      {/* {data?.ok && data?.name && (
        <MainTitleSingleContentWrapper isDark={isDark}>
          <h1>
            <span>
              Witaj {data.name[0].toUpperCase()}
              {data.name.slice(1)}
            </span>
          </h1>
        </MainTitleSingleContentWrapper>
      )} */}
      <MainTitleSingleContentWrapper isDark={isDark}>
        <h2>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="thumbs-up"
            className="svg-inline--fa fa-thumbs-up fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            style={{ height: "40px", margin: "-5px 10px" }}
            heigth={40}
            width={40}
          >
            <path fill="currentColor" d={calendar}></path>
          </svg>
          <span>Zakupy użytkowników</span>
          <p style={pStyle}>
            z{" "}
            {Math.round((data.stop[0] - data.start[0]) / (1000 * 60 * 60 * 24))}{" "}
            dni
          </p>
        </h2>
      </MainTitleSingleContentWrapper>
      <MainTitleSingleContentWrapper isDark={isDark}>
        <h2>
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="thumbs-up"
            className="svg-inline--fa fa-thumbs-up fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            style={{ height: "40px", margin: "-10px 10px" }}
            heigth={40}
            width={40}
          >
            <path fill="currentColor" d={walletPath}></path>
          </svg>
          <span>Wydali</span>
          <p style={pStyle}>{summary} PLN</p>
        </h2>
      </MainTitleSingleContentWrapper>
    </MainTitleWrapper>
  );
};
