import {
  TOGGLE_THEME,
  MENU_OPEN,
  MENU_CLOSE,
  OVER_CLOSE,
  OVER_OPEN,
  CHECK_IS_MOBILE,
  TOP_DEALS_UPDATE_OPEN,
  TOP_DEALS_UPDATE_CLOSE,
  SHOP_UPDATE_OPEN,
  SHOP_UPDATE_CLOSE,
} from "../types/types";

const INITIAL_STATE = {
  // isDark: localStorage.getItem("isDark")
  //   ? JSON.parse(localStorage.getItem("isDark"))
  //   : true,
  isDark: true,
  isMenuOpen: false,
  canOverflow: false,
  isMobile: false,
  isTopDealsUpdateOpen: false,
  isShopUpdateOpen: false,
};
export const themeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TOGGLE_THEME:
      localStorage.setItem("isDark", action.item);
      return {
        ...state,
        isDark: action.item,
      };
    case MENU_OPEN:
      return {
        ...state,
        isMenuOpen: true,
      };
    case MENU_CLOSE:
      return {
        ...state,
        isMenuOpen: false,
      };
    case OVER_CLOSE:
      return {
        ...state,
        canOverflow: false,
      };
    case OVER_OPEN:
      return {
        ...state,
        canOverflow: true,
      };
    case CHECK_IS_MOBILE:
      return {
        ...state,
        isMobile: action.item,
      };
    case TOP_DEALS_UPDATE_OPEN:
      return { ...state, isTopDealsUpdateOpen: true };

    case TOP_DEALS_UPDATE_CLOSE:
      return { ...state, isTopDealsUpdateOpen: false };

    case SHOP_UPDATE_OPEN:
      return { ...state, isShopUpdateOpen: true };

    case SHOP_UPDATE_CLOSE:
      return { ...state, isShopUpdateOpen: false };
    default:
      return { ...state };
  }
};
