import { Theme } from "../../store/theme/theme";
import { motion } from "framer-motion";
import styled from "styled-components";

export const SingleItemInfo = styled(motion.div)`
  width: 70%;
  height: auto;
  max-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  user-select: none;
  margin: 5px;
  overflow-y: hidden;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.sixColor.dark : Theme.light.fiveColor.white};
  border: 1px solid
    ${({ isDark }) =>
      isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  box-shadow: 0px 7px 29px 0px
    ${({ isDark }) =>
      isDark ? Theme.dark.eightColor.dark : Theme.light.eightColor.ligthGray};
  border-radius: 5px;
  position: relative;
  transition: max-height 1s;
  & > div > div:first-of-type {
    // width: 90%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 20px;
  }

  & > div > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 20px;
    // width: 120px;
  }
  & > div > div > p {
    font-weight: bold;
    font-size: 12px;
    color: RGB(172 172 170);
  }
  & > div > div > div {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  & > div > div > div > p {
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-right: 10px;
  }
  & > div > div > div > span {
    font-weight: bold;
    padding-left: 5px;
    padding-right: 5px;
    text-transform: uppercase;
  }
  & > div > div > div > span > a {
    display: block;
    font-weight: bold;
    text-decoration: none;
    transform: scale(1);
    transition: 0.3s transform;
    color: ${Theme.light.fourthColor.red};
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
    transform-origin: left;
  }
  & > div > div > div > span > a:hover {
    transform: scale(1.1);
  }
  & > div > div > div > span > a > span {
    padding-right: 10px;
  }
  &.extended {
    max-height: 800px;
  }
  @media${Theme.device.mobileL} {
    width: 95%;
  }
  @media${Theme.device.mobileM} {
    & > div > div {
      font-size: ${Theme.size.fontSizeMobileM};
    }
  }
`;
export const ActionWraper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: ${Theme.light.fourthColor.red}!important;
  cursor: pointer;
  & > div {
    margin: 20px;
    width: 150px;
    display: flex !important;
    flex-direction: row;
    align-items: center !important;
    justify-content: center !important;
  }
  & > a,
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${Theme.light.fourthColor.red}!important;
    transition: transform 0.3s;
  }
  & > a:hover,
  & > div:hover {
    transform: scale(1.1);
  }
`;
