import { Theme } from "../../store/theme/theme";
import styled from "styled-components";

export const MainWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: ${Theme.dark.eightColor.dark};
  color: ${Theme.dark.thirdColor.white};
  transition: background 0.3s;
`;
export const RightWrapper = styled.div`
  display: flex;
  width: 100vw;
  margin-left: 400px;
  min-height: 100vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: ${({ isDark }) =>
    isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  color: ${({ isDark }) =>
    isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
  transition: background 0.3s;
  position: relative;
  @media${Theme.device.desktopM} {
    margin-left: 25vw;
  }
  @media${Theme.device.mobileL} {
    margin-left: 0;
    max-height: ${({ isMenuOpen }) => (isMenuOpen ? "100vh" : "auto")};
    overflow: ${({ isMenuOpen }) => (isMenuOpen ? "hidden" : "auto")};
  }
`;
