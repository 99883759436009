import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import useFetchHook from "../../helpers/useFetchHook";

import {
  WrapperForSingleOffer,
  ForCloseIcon,
  SingleItemContent,
  modalWraperVariants,
  singleItemModalVariants,
  ImageForSingle,
  BrandForSingle,
  TimeForSingle,
  CategoryForSingle,
  DataForSingle,
  WraperFor2Items,
  imgSuspenseStyleDark,
  imgSuspenseStyleForBrandDark,
  imgSuspenseStyleForBrandLight,
  imgSuspenseStyleLight,
  ChangeButton,
  styleForActiveLigth,
  styleForActiveDark,
  styleForDisactiveDark,
  styleForDisactiveLight,
  InputModalItems,
  animationInputVariants,
  styleForActionsSvg,
  SvgWraper,
} from "./style";

import Cenniczek from "../../assets/media/img/cenniczek-icon-192.svg";
import { AnimatePresence } from "framer-motion";
import { ErrorBoundary } from "../errorBoundary/ErrorBoundary";
import { connect } from "react-redux";
import {
  overflowHidden,
  topDealsUpdateClose,
} from "../../store/theme/duck/actions/actions";
import { setTopDealsItemToUpdate } from "../../store/select/duck/actions/actions";
import { Theme } from "../../store/theme/theme";
// import { SelectBrand } from "./selectBrand/SelectBrand";
import { SelectCategory } from "./selectCategory/SelectCategory";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/default.css";
import { motion } from "framer-motion";
import { API_URL } from "../../constant/constant";
import StarRatings from "react-star-ratings";
import {
  save,
  imageRegular,
  trash,
  post,
} from "../../assets/media/svgPath/svgPath";
import { SvgDisplay } from "../../tools/svgDisplay/SvgDisplay";

import deleteTopDealsItem from "../../helpers/deleteTopDealsItem";
import { Spinner } from "../../tools/spinner/Spinner";
import { modalController } from "./Modal.controller";
import AIBrandPost from "./AIBrandPost";

export const styleForMoreActionsSvg = {
  height: "30px",
};
const divVariants = {
  hidden: {
    opacity: 0,
    scale: 1.2,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
  },
};
const closePath =
  "M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z";
const startPath =
  "M436 160H12c-6.627 0-12-5.373-12-12v-36c0-26.51 21.49-48 48-48h48V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h128V12c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v52h48c26.51 0 48 21.49 48 48v36c0 6.627-5.373 12-12 12zM12 192h424c6.627 0 12 5.373 12 12v260c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48V204c0-6.627 5.373-12 12-12zm333.296 95.947l-28.169-28.398c-4.667-4.705-12.265-4.736-16.97-.068L194.12 364.665l-45.98-46.352c-4.667-4.705-12.266-4.736-16.971-.068l-28.397 28.17c-4.705 4.667-4.736 12.265-.068 16.97l82.601 83.269c4.667 4.705 12.265 4.736 16.97.068l142.953-141.805c4.705-4.667 4.736-12.265.068-16.97z";
const endPath =
  "M436 160H12c-6.6 0-12-5.4-12-12v-36c0-26.5 21.5-48 48-48h48V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48 21.5 48 48v36c0 6.6-5.4 12-12 12zM12 192h424c6.6 0 12 5.4 12 12v260c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V204c0-6.6 5.4-12 12-12zm257.3 160l48.1-48.1c4.7-4.7 4.7-12.3 0-17l-28.3-28.3c-4.7-4.7-12.3-4.7-17 0L224 306.7l-48.1-48.1c-4.7-4.7-12.3-4.7-17 0l-28.3 28.3c-4.7 4.7-4.7 12.3 0 17l48.1 48.1-48.1 48.1c-4.7 4.7-4.7 12.3 0 17l28.3 28.3c4.7 4.7 12.3 4.7 17 0l48.1-48.1 48.1 48.1c4.7 4.7 12.3 4.7 17 0l28.3-28.3c4.7-4.7 4.7-12.3 0-17L269.3 352z";
const imagePath =
  "M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm-6 336H54a6 6 0 0 1-6-6V118a6 6 0 0 1 6-6h404a6 6 0 0 1 6 6v276a6 6 0 0 1-6 6zM128 152c-22.091 0-40 17.909-40 40s17.909 40 40 40 40-17.909 40-40-17.909-40-40-40zM96 352h320v-80l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L192 304l-39.515-39.515c-4.686-4.686-12.284-4.686-16.971 0L96 304v48z";
const downPath =
  "M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z";
const trashPath =
  "M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z";
// const useGetProductKeysCategory = ({ nameToFind }) => {
//   return useQuery(getProductFromKeysCategoriesQuery, {
//     variables: {
//       name: nameToFind,
//     },
//     ssr: true,
//   });
// };
const errAlfanumeric = "dostępne znaki alfanumeryczne";
const errNumeric = "dostępne znaki numeryczne [0-9].[01-99]";
const errToManySigns = "dostępne 3-30 znaków";
const regNameMultiLng =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšśŚžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9\s:/?_,.!'-]+$/u;

const forDecimals = /^\d*(\.)?(\,)?(\d{0,2})?$/;

const _Modal = ({
  theme,
  overflowHidden,
  topDealsUpdateClose,
  setTopDealsItemToUpdate,
  select,
  user,
  setTopDealUpdatedID,
  setTopDealUpdatedTimestamp,
  add,
}) => {
  const [aiTxtGenerate, setAiTxtGenerate] = useState(false);
  const chosenItem = select?.topDealsItemToUpdate;
  const [topDealsItem, setTopDealsItem] = useState(null);
  const [linkHref, setLinkHref] = useState("");
  const [isMainImg, setIsMainImg] = useState(false);
  // const [isBrandImg, setIsBarandImg] = useState(false);
  const [isCategoryImg, setIsCategoryImg] = useState(false);
  const [optionsActive, setOptionsActive] = useState(true);
  const [optionsBrandActive, setBrandOptionsActive] = useState(false);
  const [optionsCategoryActive, setCategoryOptionsActive] = useState(false);
  const [wasChanged, setWasChanged] = useState(false);
  const [brandPostByAI, setBrandPostByAI] = useState(null);
  // const [errorFromCrypto, setErrorFromCrypto] = useState("");
  const pathDefinition = "M25,50a25,25 0 1,0 50,0a25,25 0 1,0 -50,0";

  // console.log({ chosenItem, topDealsItem }, "chosenItem");
  const [err, setErr] = useState("");

  const portalContainer = document.getElementById("root");

  const { data, loading, errorFromCrypto } = useFetchHook({
    user,
    endPoint: "topDeals/getCategoryKeyProductNames",
    method: "POST",
    body: {},
  });

  let refs = useRef();
  let refsHref = useRef();
  const isDark = theme?.isDark;

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.target.blur();
    }
  };
  const handleOnchange = (e) => {
    const name = e.target.name;

    if (name === "typeSelect") {
      if (
        e.target.options[0].value === "kg" ||
        e.target.options[1].value === "szt"
      ) {
        const { value } = e.target;
        setTopDealsItem({ ...topDealsItem, typeSelect: value });
        setErr({
          err: "",
        });
      }
    } else {
      if (name === "name") {
        const { value } = e.target;
        if (value?.length > 2 && !regNameMultiLng.test(value)) {
          setErr({
            err: errAlfanumeric,
          });
          return;
        }

        if (value?.length <= 30) {
          setTopDealsItem({ ...topDealsItem, name: value });
          setErr({
            err: "",
          });
        } else {
          setErr({
            err: errToManySigns,
          });
        }
      }
      if (name === "link") {
        const { value } = e.target;

        if (value?.length > 2 && !regNameMultiLng.test(value)) {
          setErr({
            err: errAlfanumeric,
          });
          return;
        }
        if (value?.length <= 100) {
          setTopDealsItem({ ...topDealsItem, linkHref: value });
          setLinkHref(value);
          setErr({
            err: "",
          });
        } else {
          setErr({
            err: errToManySigns,
          });
        }
      }
      if (name === "howMany") {
        const { value } = e.target;

        if (forDecimals.test(value)) {
          setTopDealsItem({ ...topDealsItem, howMany: value });
          setErr({
            err: "",
          });
        } else {
          setErr({
            err: errNumeric,
          });
        }
      }
      if (name === "price") {
        const { value } = e.target;

        if (forDecimals.test(value)) {
          setTopDealsItem({ ...topDealsItem, price: value });
          setErr({
            err: "",
          });
        } else {
          setErr({
            err: errNumeric,
          });
        }
      }
    }
  };

  const handleClickOutside = (e) => {
    if (
      refs.current &&
      refs.current.contains(e.target) &&
      !refsHref.current.contains(e.target)
    ) {
      overflowHidden();
      topDealsUpdateClose();
      setTopDealsItemToUpdate(null);
      setTopDealUpdatedID && setTopDealUpdatedID("");

      return document.removeEventListener("click", handleClickOutside);
    }
  };
  useEffect(() => {
    document && document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  useEffect(() => {
    setTopDealsItem(chosenItem);
  }, [chosenItem]);
  useEffect(() => {
    setLinkHref(chosenItem?.linkHref);
  }, []);
  useEffect(() => {
    if (data?.ok && data?.productKeysCategory?.length) {
      const categoryKey = data.productKeysCategory.filter(
        (key) => chosenItem?.categoryKeyProductName === key?.name
      );

      setIsCategoryImg(categoryKey[0]?.imgUrl);
    }
    return () => {
      setIsCategoryImg(false);
    };
  }, [data, loading, chosenItem?.categoryKeyProductName]);
  useEffect(() => {
    return () => {
      brandPostByAI && setBrandPostByAI(null);
    };
  }, []);
  return (
    <>
      <AnimatePresence exitBeforeEnter>
        <ErrorBoundary>
          <WrapperForSingleOffer
            isDark={isDark}
            variants={modalWraperVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            ref={refs}
            add={add}
          >
            <ForCloseIcon
              isDark={isDark}
              onClick={() => {
                overflowHidden();
                topDealsUpdateClose();
                setTopDealUpdatedID && setTopDealUpdatedID("");
              }}
            >
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="times"
                className="svg-inline--fa fa-times fa-w-11"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 352 512"
                style={styleForMoreActionsSvg}
              >
                <path fill="currentColor" d={closePath}></path>
              </svg>
            </ForCloseIcon>
            <SingleItemContent
              isDark={isDark}
              variants={singleItemModalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
              ref={refsHref}
            >
              <h2>{`${topDealsItem?.name ? topDealsItem.name : ""}`}</h2>
              <ImageForSingle>
                {isMainImg ? null : (
                  <img
                    src={Cenniczek}
                    style={
                      isDark ? imgSuspenseStyleDark : imgSuspenseStyleLight
                    }
                    alt={`${topDealsItem?.name ? topDealsItem.name : ""}`}
                  />
                )}
                {chosenItem?.imgUrl && (
                  <img
                    onLoad={() => setIsMainImg(true)}
                    src={`${API_URL}${topDealsItem?.imgUrl}` || ""}
                    alt={`${topDealsItem?.name ? topDealsItem.name : ""}`}
                  />
                )}
              </ImageForSingle>
              <DataForSingle
                isDark={isDark}
                optionsActive={optionsActive}
                link={topDealsItem?.linkHref}
              >
                <div>
                  <section>
                    <div>
                      <p>Ile:</p>
                      <p>
                        {topDealsItem?.howMany || "brak danych"}{" "}
                        {topDealsItem?.typeSelect}
                      </p>
                    </div>
                    <div>
                      <p>Cena:</p>
                      <p>
                        {topDealsItem?.price || "brak danych"}{" "}
                        {topDealsItem?.price > 0 && "PLN"}
                      </p>
                    </div>
                    <div
                      onClick={() => {
                        topDealsItem?.linkHref &&
                          window.open(topDealsItem?.linkHref);
                      }}
                    >
                      <p>Link:</p>
                      <p>
                        {(topDealsItem?.linkHref &&
                          topDealsItem?.linkHref?.slice(0, 25) + "...") ||
                          "brak linku"}
                      </p>
                    </div>
                  </section>
                  <section>
                    <div>
                      <SvgWraper>
                        <motion.svg
                          aria-hidden="true"
                          className="svgItemForModal"
                          focusable="false"
                          xmlns="http://www.w3.org/2000/svg"
                          // viewBox="0 0 100 100"
                          // preserveAspectRatio="xMidYMid meet"
                          viewBox="19 19 62 62"
                        >
                          {" "}
                          <motion.path
                            fill="none"
                            d={pathDefinition}
                            initial={{ pathLength: 0, pathOffset: 1 }}
                            animate={{ pathLength: 1, pathOffset: 0 }}
                            transition={{ duration: 1 }}
                          ></motion.path>
                          <motion.path
                            isDark={isDark}
                            fill={Theme.light.secondaryColor.green}
                            d={pathDefinition}
                            stroke={Theme.dark.primaryColor.dark}
                            stroke-width="20"
                            // initial={{
                            //   pathLength: 0,
                            //   pathOffset: 1,
                            // }}
                            // animate={{
                            //   pathLength: 1,
                            //   pathOffset: 0,
                            // }}
                            // transition={{ duration: 5 }}
                          ></motion.path>
                        </motion.svg>
                        <div
                          style={{
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%,-50%)",
                          }}
                        >
                          <AnimatePresence>
                            <motion.p
                              variants={divVariants}
                              initial="hidden"
                              animate="visible"
                              exit="exit"
                            >
                              <p>
                                {chosenItem?.statusBook === "active" &&
                                  "aktualna"}
                                {chosenItem?.statusBook === "future" &&
                                  "nastepna"}
                              </p>
                            </motion.p>
                          </AnimatePresence>
                        </div>
                      </SvgWraper>
                    </div>
                  </section>
                </div>
                <p
                  onClick={() => {
                    // console.log("zmiana", optionsActive);
                    setOptionsActive(!optionsActive);
                  }}
                >
                  {/* <Down
                      isDark={isDark}
                      style={
                        optionsActive
                          ? isDark
                            ? styleForActiveDark
                            : styleForActiveLigth
                          : !isDark
                          ? styleForDisactiveLight
                          : styleForDisactiveDark
                      }
                    /> */}
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="angle-down"
                    className="svg-inline--fa fa-angle-down fa-w-10"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                    style={
                      optionsActive
                        ? isDark
                          ? styleForActiveDark
                          : styleForActiveLigth
                        : !isDark
                        ? styleForDisactiveLight
                        : styleForDisactiveDark
                    }
                  >
                    <path fill="currentColor" d={downPath}></path>
                  </svg>
                </p>

                {optionsActive && (
                  <div>
                    <InputModalItems
                      isDark={isDark}
                      variants={animationInputVariants}
                      initial="hidden"
                      animate="visible"
                      exit="exit"
                    >
                      <div>
                        <p>Ile</p>
                        <input
                          type="number"
                          name="howMany"
                          placeholder="ilość"
                          inputMode="decimal"
                          value={topDealsItem?.howMany || ""}
                          step={`${
                            topDealsItem?.typeSelect === ""
                              ? 0.25
                              : topDealsItem?.typeSelect === "kg"
                              ? 0.25
                              : 1
                          }`}
                          onChange={handleOnchange}
                          onKeyDown={(e) => handleKeyDown(e)}
                          min="0"
                          max="1000"
                        />
                      </div>
                      <div>
                        <p>Cena</p>
                        <input
                          type="number"
                          name="price"
                          placeholder="cena"
                          inputMode="decimal"
                          value={topDealsItem?.price || ""}
                          step="0.01"
                          onChange={handleOnchange}
                          onKeyDown={(e) => handleKeyDown(e)}
                          min="0"
                          max="10000"
                        />
                      </div>
                      <div>
                        <p>kg/szt</p>
                        <select
                          name="typeSelect"
                          onChange={handleOnchange}
                          value={topDealsItem?.typeSelect}
                        >
                          <option value="kg">kg</option>
                          <option value="szt">szt</option>
                        </select>
                      </div>
                      <div>
                        <p>nazwa</p>
                        <input
                          type="text"
                          name="name"
                          placeholder="nazwa"
                          value={topDealsItem?.name}
                          onChange={handleOnchange}
                          onKeyDown={(e) => handleKeyDown(e)}
                        />
                      </div>
                      <div>
                        <p>Link</p>
                        <input
                          type="text"
                          name="link"
                          placeholder="link"
                          value={linkHref}
                          onChange={handleOnchange}
                          onKeyDown={(e) => handleKeyDown(e)}
                        />
                      </div>
                      {err?.err && <p>{err?.err}</p>}
                    </InputModalItems>
                  </div>
                )}
              </DataForSingle>
              <WraperFor2Items isDark={isDark}>
                <BrandForSingle
                  isDark={isDark}
                  optionsBrandActive={optionsBrandActive}
                >
                  <section>
                    <img
                      src={`/static/assets/media/brands/${topDealsItem?.brand
                        .split(" ")
                        .join("-")}.png`}
                      style={
                        isDark
                          ? imgSuspenseStyleForBrandDark
                          : imgSuspenseStyleForBrandLight
                      }
                    />

                    <p>{topDealsItem?.brand || "brak danych"}</p>
                  </section>
                </BrandForSingle>
                <TimeForSingle isDark={isDark}>
                  <div>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="calendar-check"
                      className="svg-inline--fa fa-calendar-check fa-w-14"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      style={styleForMoreActionsSvg}
                    >
                      <path fill="currentColor" d={startPath}></path>
                    </svg>
                    <p>
                      {topDealsItem?.startDuringTime
                        ? topDealsItem?.startDuringTime.slice(
                            0,
                            topDealsItem.startDuringTime.slice.length - 7
                          )
                        : "brak danych"}
                    </p>
                  </div>
                  <div>
                    {/* <EndTime style={styleForMoreActionsSvg} /> */}
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="calendar-times"
                      className="svg-inline--fa fa-calendar-times fa-w-14"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      style={styleForMoreActionsSvg}
                    >
                      <path fill="currentColor" d={endPath}></path>
                    </svg>
                    <p>
                      {topDealsItem?.endDuringTime
                        ? topDealsItem?.endDuringTime.slice(
                            0,
                            topDealsItem.endDuringTime.slice.length - 7
                          )
                        : "brak danych"}
                    </p>
                  </div>
                </TimeForSingle>
              </WraperFor2Items>
              <CategoryForSingle
                isDark={isDark}
                optionsCategoryActive={optionsCategoryActive}
              >
                <section>
                  {isCategoryImg ? (
                    <img
                      src={isCategoryImg}
                      style={
                        isDark
                          ? imgSuspenseStyleForBrandDark
                          : imgSuspenseStyleForBrandLight
                      }
                    />
                  ) : (
                    <SvgDisplay
                      viewBox={imageRegular.viewBox}
                      d={imageRegular.d}
                      width={70}
                      style={{ marginRight: "5px" }}
                      fill={
                        isDark
                          ? Theme.dark.thirdColor.white
                          : Theme.light.thirdColor.dark
                      }
                    />
                  )}

                  <div>
                    <p style={{ marginBottom: "10px" }}>
                      {topDealsItem?.categoryKeyProductName}
                    </p>
                    <p style={{ fontSize: "10px" }}>rozpoznawalność kategori</p>
                    <StarRatings
                      rating={topDealsItem?.similarityRating * 10 || 0}
                      starRatedColor={`${Theme.light.secondaryColor.green}`}
                      starEmptyColor={`${
                        isDark
                          ? Theme.dark.thirdColor.white
                          : Theme.light.thirdColor.dark
                      }`}
                      numberOfStars={5}
                      starDimension="20px"
                      starSpacing="3px"
                      name="rating"
                    />
                  </div>
                  <p
                    onClick={() => {
                      // console.log("zmiana", optionsBrandActive);
                      setCategoryOptionsActive(!optionsCategoryActive);
                    }}
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="angle-down"
                      className="svg-inline--fa fa-angle-down fa-w-10"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      style={
                        optionsCategoryActive
                          ? isDark
                            ? styleForActiveDark
                            : styleForActiveLigth
                          : !isDark
                          ? styleForDisactiveLight
                          : styleForDisactiveDark
                      }
                    >
                      <path fill="currentColor" d={downPath}></path>
                    </svg>
                  </p>
                </section>
                {optionsCategoryActive && (
                  <section>
                    <SelectCategory
                      setCategoryOptionsActive={setCategoryOptionsActive}
                      isDark={isDark}
                      data={data?.productKeysCategory}
                      setIsCategoryImg={setIsCategoryImg}
                      chosenItem={topDealsItem}
                      setTopDealsItemToUpdate={setTopDealsItemToUpdate}
                      setTopDealsItem={setTopDealsItem}
                    />
                  </section>
                )}
              </CategoryForSingle>
              {aiTxtGenerate && (
                <>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "120px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Spinner />
                    AI generuje promo post
                  </div>
                </>
              )}
              {!brandPostByAI &&
                !aiTxtGenerate &&
                chosenItem?._id &&
                !chosenItem?.usedForAI && (
                  <ChangeButton
                    whileTap={{ scale: 0.95 }}
                    whileHover={{ scale: 1.05 }}
                    onClick={async () => {
                      if (topDealsItem?.name == "undefined") {
                        alertify.error("Nazwa produktu nie została ustawiona");
                        return;
                      }
                      if (
                        topDealsItem?.name &&
                        chosenItem?.name === "undefined"
                      ) {
                        alertify.error("Musisz najpierw zapisać zmiany");
                        return;
                      }
                      if (!topDealsItem?.categoryKeyProductName) {
                        alertify.error(
                          "Kategoria produktu nie została ustawiona"
                        );
                        return;
                      }
                      if (
                        topDealsItem?.categoryKeyProductName &&
                        chosenItem?.categoryKeyProductName === "undefined"
                      ) {
                        alertify.error("Musisz najpierw zapisać zmiany");
                        return;
                      }
                      alertify.set("notifier", "position", "bottom-left");
                      alertify.success(`AI pracuje - cierpliwości`);
                      setAiTxtGenerate(true);
                      await modalController.generateAIPromoTxt(
                        topDealsItem,
                        setAiTxtGenerate,
                        setBrandPostByAI
                      );
                    }}
                  >
                    <p>
                      {/* <Trash style={styleForActionsSvg} /> */}
                      <SvgDisplay
                        viewBox={post.viewBox}
                        d={post.d}
                        width={20}
                        style={{ marginRight: "5px" }}
                        fill={
                          isDark
                            ? Theme.dark.thirdColor.white
                            : Theme.light.thirdColor.dark
                        }
                      />
                      promoPost
                    </p>
                  </ChangeButton>
                )}
              {brandPostByAI && (
                <AnimatePresence exitBeforeEnter>
                  <AIBrandPost
                    AIPost={brandPostByAI}
                    brandPostByAI={brandPostByAI}
                    setBrandPostByAI={setBrandPostByAI}
                    isDark={isDark}
                    variants={modalWraperVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                  />
                </AnimatePresence>
              )}
              {!aiTxtGenerate && !brandPostByAI && (
                <ChangeButton
                  whileTap={{ scale: 0.95 }}
                  whileHover={{ scale: 1.05 }}
                  onClick={() => {
                    if (topDealsItem?.name == "undefined") {
                      alertify.error("Nazwa produktu nie została ustawiona");
                      return;
                    }
                    if (topDealsItem?.categoryKeyProductName === "undefined") {
                      alertify.error(
                        "Kategoria produktu nie została ustawiona"
                      );
                      return;
                    }
                    overflowHidden();
                    topDealsUpdateClose();
                    setTopDealsItemToUpdate(null);
                    modalController.fetchForChangeListItem(
                      topDealsItem,
                      setTopDealUpdatedID,
                      setTopDealUpdatedTimestamp,
                      window
                    );
                  }}
                >
                  <p>
                    {/* <Trash style={styleForActionsSvg} /> */}
                    <SvgDisplay
                      viewBox={save.viewBox}
                      d={save.d}
                      width={20}
                      style={{ marginRight: "5px" }}
                      fill={
                        isDark
                          ? Theme.dark.thirdColor.white
                          : Theme.light.thirdColor.dark
                      }
                    />
                    zapisz
                  </p>
                </ChangeButton>
              )}
              {brandPostByAI && chosenItem?._id && (
                <ChangeButton
                  whileTap={{ scale: 0.95 }}
                  whileHover={{ scale: 1.05 }}
                  onClick={() => {
                    if (topDealsItem?.name == "undefined") {
                      alertify.error("Nazwa produktu nie została ustawiona");
                      return;
                    }
                    if (topDealsItem?.categoryKeyProductName === "undefined") {
                      alertify.error(
                        "Kategoria produktu nie została ustawiona"
                      );
                      return;
                    }
                    if (!brandPostByAI?.title) {
                      alertify.error("Tytuł postu nie został ustawiony");
                      return;
                    }
                    if (!regNameMultiLng.test(brandPostByAI?.title)) {
                      alertify.error("Tytuł " + errAlfanumeric);
                      return;
                    }
                    if (!brandPostByAI?.intro) {
                      alertify.error("Intro postu nie zostało ustawione");
                      return;
                    }
                    if (!regNameMultiLng.test(brandPostByAI?.intro)) {
                      alertify.error("Intro " + errAlfanumeric);
                      return;
                    }
                    if (!brandPostByAI?.content[0]?.text) {
                      alertify.error("Tekst postu nie istnieje");
                      return;
                    }
                    if (
                      !regNameMultiLng.test(brandPostByAI?.content[0]?.text)
                    ) {
                      alertify.error("Tekst " + errAlfanumeric);
                      return;
                    }
                    overflowHidden();
                    topDealsUpdateClose();
                    setTopDealsItemToUpdate(null);
                    modalController.publishPromoPost(brandPostByAI, window);
                  }}
                >
                  <p>
                    {/* <Trash style={styleForActionsSvg} /> */}
                    <SvgDisplay
                      viewBox={save.viewBox}
                      d={save.d}
                      width={20}
                      style={{ marginRight: "5px" }}
                      fill={
                        isDark
                          ? Theme.dark.thirdColor.white
                          : Theme.light.thirdColor.dark
                      }
                    />
                    publikuj
                  </p>
                </ChangeButton>
              )}

              {!aiTxtGenerate && !brandPostByAI && chosenItem?._id && (
                <ChangeButton
                  type="delete"
                  whileTap={{ scale: 0.95 }}
                  whileHover={{ scale: 1.05 }}
                  onClick={() => {
                    overflowHidden();
                    topDealsUpdateClose();
                    setTopDealsItemToUpdate(null);
                    alertify.confirm(
                      "Potwierdź usunięcie",
                      `Usuwasz ${topDealsItem?.name} ${topDealsItem?.aditionalName}`,
                      async function () {
                        deleteTopDealsItem(
                          topDealsItem,
                          "topDeals/delete"
                        ).then((confirm) => {
                          if (confirm) {
                            // setDeletedItems(item);
                            alertify.success(
                              `Usunięto ${topDealsItem?.name} ${topDealsItem?.aditionalName}`
                            );
                            window?.location?.reload();
                          } else {
                            alertify.set("notifier", "position", "bottom-left");
                            alertify.error("Coś poszło nie tak");
                          }
                        });
                      },
                      function () {
                        alertify.error("Anulowano");
                      }
                    );
                  }}
                >
                  <p>
                    {/* <Trash style={styleForActionsSvg} /> */}
                    <SvgDisplay
                      viewBox={trash.viewBox}
                      d={trash.d}
                      width={20}
                      style={{ marginRight: "5px" }}
                      fill={
                        isDark
                          ? Theme.dark.thirdColor.white
                          : Theme.light.thirdColor.dark
                      }
                    />
                    usuń
                  </p>
                </ChangeButton>
              )}
            </SingleItemContent>
          </WrapperForSingleOffer>
        </ErrorBoundary>
      </AnimatePresence>
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  overflowHidden: () => dispatch(overflowHidden()),
  topDealsUpdateClose: () => dispatch(topDealsUpdateClose()),
  setTopDealsItemToUpdate: () => dispatch(setTopDealsItemToUpdate()),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  select: state.select,
  user: state.user,
});
export const Modal = connect(mapStateToProps, mapDispatchToProps)(_Modal);
