import styled from "styled-components";
import { Theme } from "../../store/theme/theme";

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & > img {
    width: 300px;
  }
  @media${Theme.device.desktopM} {
    & > img {
      width: 80%;
    }
  }
  @media${Theme.device.mobileL} {
    & > img {
      width: 60%;
    }
  }
`;
