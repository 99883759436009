import React from "react";
import { SingleSlide } from "../singleSlide/SingleSlide";
export const ShowDeals = ({
  select,
  topDealsSelect,
  slideStyleActive,
  slideStyle,
  caruselWraperVariants,
  topDealsSelectedName,
  dataforCenniczek,
  isDark,
}) => {
  if (dataforCenniczek?.productKeysCategory?.length) {
    return dataforCenniczek?.productKeysCategory?.map((item, index) => (
      <SingleSlide
        key={index}
        item={item}
        index={index}
        select={select}
        slideStyleActive={slideStyleActive}
        slideStyle={slideStyle}
        caruselWraperVariants={caruselWraperVariants}
        topDealsSelect={topDealsSelect}
        topDealsSelectedName={topDealsSelectedName}
        isDark={isDark}
      />
    ));
  } else return null;
};
