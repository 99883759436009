import React from "react";
import { connect } from "react-redux";
import {
  ColumnWrapper,
  OptionsWrapper,
  SecondaryOptionsWrapper,
  XMarkWrap,
} from "./LeftColumn.style";
import Logo from "../../logo/LogoStudio";
import Avatar from "../../avatar/Avatar";
import Overview from "./options/Overview";
import NewsPapers from "./options/NewsPapers";
import Products from "./options/Products";
import AddProduct from "./options/AddProduct";
import Shops from "./options/Shops";
import Settings from "./options/Settings";
import ContactUs from "./secondaryOptions/ContactUs";
import SignOut from "./secondaryOptions/SignOut";
import { SvgDisplay } from "../../../tools/svgDisplay/SvgDisplay";
import { xMark } from "../../../assets/media/svgPath/svgPath";
import { Theme } from "../../../store/theme/theme";
import { menuClose } from "../../../store/theme/duck/actions/actions";
import AddNewsPaper from "./options/AddNewspaper";

const LeftColumn = ({ user, theme, menuClose }) => {
  return (
    <>
      <ColumnWrapper isDark={theme.isDark} isMenuOpen={theme?.isMenuOpen}>
        <XMarkWrap isMenuOpen={theme?.isMenuOpen} onClick={() => menuClose()}>
          <SvgDisplay
            viewBox={xMark.viewBox}
            d={xMark.d}
            // style={xMarkStyle}
            width={25}
            fill={
              theme?.isDark
                ? Theme.dark.thirdColor.white
                : Theme.dark.thirdColor.white
            }
          />
        </XMarkWrap>
        <Logo />
        <Avatar user={user} />
        <OptionsWrapper>
          <Overview />
          <NewsPapers />
          <AddNewsPaper />
          <Products />
          <AddProduct />
          <Shops />
          <Settings />
        </OptionsWrapper>
        <SecondaryOptionsWrapper>
          <ContactUs />
          <SignOut />
        </SecondaryOptionsWrapper>
      </ColumnWrapper>
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  menuClose: () => dispatch(menuClose()),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.user,
});
export default connect(mapStateToProps, mapDispatchToProps)(LeftColumn);
