import { combineReducers } from "redux";
import { themeReducer } from "./theme/duck/reducers/themeReducer";
import { selectReducer } from "./select/duck/reducers/reducer";
import { localizationReducer } from "./localization/duck/reducers/reducer";
import { shopingListReducer } from "./shopingList/duck/reducers/reducer";
import { userReducer } from "./user/duck/reducers/reducer";
export const rootReducer = combineReducers({
  theme: themeReducer,
  select: selectReducer,
  localization: localizationReducer,
  shopingList: shopingListReducer,
  user: userReducer,
});
