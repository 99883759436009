import { WrapperForInputs } from "./AddNewspaper.style";
import UploadNewspaper from "./UploadNewspaper";
import { connect } from "react-redux";

const AddNewsPaper = ({ theme, user }) => {
  return (
    <WrapperForInputs>
      <p>Dodaj gazetkę</p>
      <UploadNewspaper isDark={theme.isDark} user={user} />
    </WrapperForInputs>
  );
};
const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.user,
  select: state.select,
});
export default connect(mapStateToProps, {})(AddNewsPaper);
