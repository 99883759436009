import styled from "styled-components";
import { Theme } from "../store/theme/theme";
import { motion } from "framer-motion";

export const SignUpConfirmWrapper = styled(motion.div)`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${Theme.dark.eightColor.dark};
  color: ${Theme.light.primaryColor.white};
  text-align: center;
  & > img {
    width: 50vw;
    margin: 30px;
    cursor: pointer;
  }
  & > p {
    margin: 30px;
    font-size: 20px;
    width: 50vw;
  }
  @media${Theme.device.mobileL} {
    & > img {
      width: 80vw;
    }
    & > p {
      width: 80vw;
    }
  }
`;

export const divVariants = {
  hidden: {
    opacity: 0,
    // y: "-5%",
  },
  visible: (index) => ({
    opacity: 1,
    // y: 0,
    transition: {
      duration: 0.3,
      // delay:index*0.1+0.5,
      delay: 0.3,
    },
  }),
  exit: {
    opacity: 0,
    // y: "-5%",
  },
};

export const imgVariants = {
  hidden: {
    opacity: 0,
    y: "-5%",
  },
  visible: (index) => ({
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      // delay:index*0.1+0.5,
      delay: 0.6,
    },
  }),
  exit: {
    opacity: 0,
    y: "-5%",
  },
};
