import { Theme } from "../../store/theme/theme";
import { SvgDisplay } from "../../tools/svgDisplay/SvgDisplay";
import { BtnWrapper } from "./Button.style";

const Button = ({ style, text, handleClick, isDark, icon }) => {
  return (
    <BtnWrapper style={style} isDark={isDark}>
      <SvgDisplay
        viewBox={icon.viewBox}
        d={icon.d}
        width={25}
        fill={
          isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
        }
      />
      <button onClick={() => handleClick()}>{text}</button>
    </BtnWrapper>
  );
};
export default Button;
