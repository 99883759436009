import styled from "styled-components";
import { Theme } from "../../../../../../store/theme/theme";

export const StyledWraperForNumber = styled.div.attrs((props) => ({
  style: {
    background: `${
      props.isDark
        ? Theme.dark.primaryColor.dark
        : Theme.light.primaryColor.white
    }`,
    color: `${
      props.isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark
    }`,
    border: `2px solid ${props.isDark ? "black" : Theme.dark.sixColor.dark}`,
  },
}))`
  width: 30px;
  height: 30px;
  border-radius: 15px;
  position: absolute;
  top: 0px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;
