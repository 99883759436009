import {
  TRIGER_VISIBLE_LIST,
  PUSH_ITEM_TO_LIST,
  PUSH_USER_ITEM_TO_LIST,
  DELETE_ITEM_FROM_LIST,
  DELETE_USER_ITEM_TO_LIST,
  UPDATE_ITEM_ON_LIST,
  UPDATE_USER_ITEM_TO_LIST,
  USER_LOGGED,
  USER_LOGOUT,
  CLEAR_ALL_SHOPING_LIST,
  SET_ERR,
  USER_RODO_ACCEPT,
  SEND_TOKEN_TO_USER,
  USER_CAN_CROP_FROM_NEWSPAPER,
  USER_CAN_NOT_CROP_FROM_NEWSPAPER,
  ClOSE_VISIBLE_LIST,
  USER_ACCEPT_SHOPINGLIST_FUTURE,
  USER_ACCEPT_METRICS_FUTURE,
  UPDATE_ITEMS_FROM_LOCALSTORAGE,
  UPDATE_USER_ITEMS_FROM_LOCALSTORAGE,
} from "../types/type";

const INITIAL_SHOPING_LIST_STATE = {
  isListVisible: false,
  userLoged: false,
  email: "",
  listItems: localStorage.getItem("items")
    ? JSON.parse(localStorage.getItem("items"))
    : [],
  userItems: localStorage.getItem("userItems")
    ? JSON.parse(localStorage.getItem("userItems"))
    : [],
  userAcceptRodo: {
    accepted: localStorage.getItem("userRodo")
      ? JSON.parse(localStorage.getItem("userRodo"))
      : false,
    token: localStorage.getItem("userToken")
      ? JSON.parse(localStorage.getItem("userToken"))
      : "",
  },
  userAcceptMetricsFuture: localStorage.getItem("userAcceptMetricsFuture")
    ? JSON.parse(localStorage.getItem("userAcceptMetricsFuture"))
    : false,
  userAcceptShopingListFuture: localStorage.getItem(
    "userAcceptShopingListFuture"
  )
    ? JSON.parse(localStorage.getItem("userAcceptShopingListFuture"))
    : false,
  cropedFromNewspaper: {
    active: false,
    index: null,
  },
  err: "",
};

export const shopingListReducer = (
  state = INITIAL_SHOPING_LIST_STATE,
  actions
) => {
  switch (actions.type) {
    case TRIGER_VISIBLE_LIST:
      return { ...state, isListVisible: !state.isListVisible };

    case PUSH_ITEM_TO_LIST:
      const indexItem = state.listItems.findIndex(
        (listItem) => listItem.id === actions.item.id
      );
      if (indexItem === -1) {
        localStorage.setItem(
          "items",
          JSON.stringify([...state.listItems, actions.item])
        );
        return { ...state, listItems: [...state.listItems, actions.item] };
      }
      return { ...state };

    case DELETE_ITEM_FROM_LIST:
      localStorage.setItem(
        "items",
        JSON.stringify([
          ...state.listItems.filter(
            (listItem) => listItem.id !== actions.item.id
          ),
        ])
      );
      state.listItems = state.listItems.filter(
        (listItem) => listItem.id !== actions.item.id
      );
      if (state.listItems.length === 0 && state.userItems.length === 0) {
        state.err = "";
      }
      // localStorage.setItem('items',[...state.listItems.filter(listItem=>listItem.id!==actions.item.id)])
      //  return {...state,listItems :[...state.listItems.filter(listItem=>listItem.id!==actions.item.id)]}
      return { ...state };
    case UPDATE_ITEMS_FROM_LOCALSTORAGE:
      state.listItems.push(...actions.item);
      return { ...state };
    case UPDATE_ITEM_ON_LIST:
      const indexUpdateItem = state.listItems.findIndex(
        (listItem) => listItem.id === actions.item.id
      );
      if (indexUpdateItem !== -1) {
        // const name=actions.updateName
        state.listItems[indexUpdateItem] = actions.item;
        localStorage.setItem("items", JSON.stringify([...state.listItems]));
        return { ...state };
      }
      return { ...state };
    case UPDATE_USER_ITEMS_FROM_LOCALSTORAGE:
      state.userItems.push(...actions.item);
      return { ...state };
    case PUSH_USER_ITEM_TO_LIST:
      localStorage.setItem(
        "userItems",
        JSON.stringify([...state.userItems, actions.item])
      );
      return { ...state, userItems: [...state.userItems, actions.item] };

    case DELETE_USER_ITEM_TO_LIST:
      localStorage.setItem(
        "userItems",
        JSON.stringify([
          ...state.userItems.filter(
            (userItem, index) => index !== actions.item
          ),
        ])
      );
      state.userItems = state.userItems.filter(
        (userItem, index) => index !== actions.item
      );
      if (state.userItems.length === 0 && state.listItems.length === 0) {
        state.err = "";
      }
      // return {...state,userItems :[...state.userItems.filter((userItem,index)=>index!==actions.item)]}
      return { ...state };

    case UPDATE_USER_ITEM_TO_LIST:
      // const indexUpdateItem=state.userItems.findIndex(userItem=>listItem.id===actions.item.id)

      // const name=actions.updateName
      state.userItems[actions.item.index] = actions.item.value;
      localStorage.setItem("userItems", JSON.stringify([...state.userItems]));
      // return {...state,userItems:[state.userItems[actions.item.index]=actions.item]}
      return { ...state };
    case USER_LOGGED:
      return {
        ...state,
        userLoged: actions.item.isLoged,
        email: actions.item.email,
        err: "",
      };
    case USER_LOGOUT:
      return { ...state, userLoged: false, email: "" };
    case CLEAR_ALL_SHOPING_LIST:
      localStorage.removeItem("items");
      localStorage.removeItem("userItems");
      return { ...state, listItems: [], userItems: [], isListVisible: false };
    case SET_ERR:
      return { ...state, err: actions.item };
    case USER_RODO_ACCEPT:
      localStorage.setItem("userRodo", JSON.stringify(true));
      return {
        ...state,
        userAcceptRodo: { accepted: true, token: state.userAcceptRodo.token },
      };
    case SEND_TOKEN_TO_USER:
      localStorage.setItem("userToken", JSON.stringify(actions.item));
      return {
        ...state,
        userAcceptRodo: {
          accepted: state.userAcceptRodo.accepted,
          token: actions.item,
        },
      };
    case USER_CAN_CROP_FROM_NEWSPAPER:
      return {
        ...state,
        cropedFromNewspaper: { active: true, index: actions.item },
      };
    case USER_CAN_NOT_CROP_FROM_NEWSPAPER:
      return { ...state, cropedFromNewspaper: { active: false, index: null } };

    case ClOSE_VISIBLE_LIST:
      return { ...state, isListVisible: false };

    case USER_ACCEPT_SHOPINGLIST_FUTURE:
      localStorage.setItem("userAcceptShopingListFuture", JSON.stringify(true));
      return { ...state, userAcceptShopingListFuture: true };

    case USER_ACCEPT_METRICS_FUTURE:
      localStorage.setItem("userAcceptMetricsFuture", JSON.stringify(true));
      return { ...state, userAcceptMetricsFuture: true };

    default:
      return { ...state };
  }
};
// export default shopingListReducer;
