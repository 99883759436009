import useFetchHook from "../../helpers/useFetchHook";

const useNewspaperControllerForCenniczek = (user) => {
  const { data, loading, errorFromCrypto } = useFetchHook({
    user,
    endPoint: "getAllNewspaper",
    method: "POST",
    body: {},
  });
  if (!user?.userLoged || user.represents !== "cenniczek") return {};

  return { data, loading, errorFromCrypto };
};
export default useNewspaperControllerForCenniczek;
