import React from "react";
import { CENNICZEK, CONTRACTOR } from "./loadDeals.type";
import { LoadDealsCenniczek } from "./LoadDealsCenniczek";

export const LoadDeals = ({
  optionsConfirmed,
  activeSortType,
  topDealsSelected,
  theme,
  dealsType,
  dataforCenniczek,
  loading,
  setDeletedItems,
  deletedItems,
  topDealUpdatedID,
  topDealUpdatedTimestamp,
  setTopDealUpdatedTimestamp,
}) => {
  switch (dealsType) {
    case CENNICZEK: {
      return (
        <LoadDealsCenniczek
          key={dealsType}
          dataforCenniczek={dataforCenniczek}
          loading={loading}
          topDealsSelected={topDealsSelected}
          activeSortType={activeSortType}
          optionsConfirmed={optionsConfirmed}
          theme={theme}
          setDeletedItems={setDeletedItems}
          deletedItems={deletedItems}
          topDealUpdatedID={topDealUpdatedID}
          topDealUpdatedTimestamp={topDealUpdatedTimestamp}
          setTopDealUpdatedTimestamp={setTopDealUpdatedTimestamp}
        />
      );
    }
    case CONTRACTOR: {
      return (
        <LoadDealsCenniczek
          key={dealsType}
          dataforCenniczek={dataforCenniczek}
          loading={loading}
          topDealsSelected={topDealsSelected}
          activeSortType={activeSortType}
          optionsConfirmed={optionsConfirmed}
          theme={theme}
          setDeletedItems={setDeletedItems}
          deletedItems={deletedItems}
          topDealUpdatedID={topDealUpdatedID}
          topDealUpdatedTimestamp={topDealUpdatedTimestamp}
          setTopDealUpdatedTimestamp={setTopDealUpdatedTimestamp}
        />
      );
    }

    default:
      return null;
  }
};
