import { Theme } from "../../store/theme/theme";
import { motion } from "framer-motion";
import styled from "styled-components";

export const MainMsgWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
  height: 80vh;
  & > img {
    width: 180px;
    margin-bottom: 30px;
  }
  @media${Theme.device.mobileL} {
    width: 100%;
    & > img {
      width: 100px;
    }
  }
`;
