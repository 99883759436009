import styled from "styled-components";
import { Theme } from "../../store/theme/theme";
import { motion } from "framer-motion";
export const MainWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background: ${Theme.dark.eightColor.dark};
  color: ${Theme.light.primaryColor.white};
`;
export const MainWrapperLayer = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const MainContent = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  color: ${Theme.light.primaryColor.white};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-transform: uppercase;

  @media${Theme.device.mobileL} {
    width: 100%;
    & > h1 {
      font-size: 30px;
    }
    & > form > input {
      width: 80%;
    }
  }
`;
export const HeadLine = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  & > img {
    height: 40vh;
    cursor: pointer;
  }
  & > p {
    font-size: 30px;
  }
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    user-select: none;
  }
  & > div > p {
    margin: 15px;
    font-size: 1.5rem;
    user-select: none;
  }
  & > h4 {
    text-transform: uppercase;
    font-size: 40px;
    font-weight: 500;
    user-select: none;
  }
  & > h5 {
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 500;
    user-select: none;
  }
  @media${Theme.device.mobileL} {
    width: 100%;
    & > img {
      width: 35vh;
    }
    & > p {
      font-size: 20px;
    }
    & > h4 {
      font-size: 30px;
      margin: 15px;
    }
  }
`;
export const FormWrapper = styled.div`
  width: 50%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-transform: uppercase;
  background-image: linear-gradient(
    315deg,
    ${"rgb(62, 56, 71)"} 0%,
    ${"rgb(85, 81, 86)"} 74%
  );
  & > h5 {
    width: 70%;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 500;
    user-select: none;
  }
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
  }
  & > div > p {
    margin: 15px;
    font-size: 1.5rem;
    user-select: none;
  }
  & > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  & > form > div {
    width: 60%;
    height: 60px;
    margin: 20px;
  }
  & > form > input {
    width: 60%;
    height: 60px;
    margin: 20px;
    border: none;
    border-radius: 5px;
    text-align: center;
    transition: 0.1s;
    transform: scale(1);
    background: ${Theme.dark.primaryColor.dark} !important;
    color: ${Theme.dark.fiveColor.white};
  }
  & > form > div > input {
    width: 100%;
    height: 60px;
    border: none;
    border-radius: 5px;
    text-align: center;
    transition: 0.1s;
    transform: scale(1);
    background: ${Theme.dark.primaryColor.dark};
    color: ${Theme.dark.fiveColor.white};
  }

  & > form > input:hover,
  & > form > div > input:hover {
    transform: scale(1.01);
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
  }
  & > form > input::placeholder,
  & > form > div > input::placeholder {
    text-align: center;
    text-transform: uppercase;
    opacity: 1;
    transition: 0.3s;
  }
  & > form > input:autofill,
  & > form > div > input:autofill {
    background: ${Theme.dark.primaryColor.dark};
  }
  & > form > input:focus::placeholder,
  & > form > div > input:focus::placeholder {
    opacity: 0;
  }
  & > form > button {
    width: 200px;
    height: 60px;
    margin: 10px;
    border-radius: 5px;
    text-transform: uppercase;
    background-color: ${Theme.light.secondaryColor.green};
    border: 1px solid black;
    color: ${Theme.dark.thirdColor.white};
    transition: 0.3s;
    transform: scale(1);
    user-select: none;
    cursor: pointer;
  }
  & > form > button:hover {
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
    transform: scale(1.1);
  }
  @media${Theme.device.mobileL} {
    width: 95%;
    margin-bottom: 15px;

    & > h5 {
      font-size: 22px;
      margin-top: 20px;
    }
    & > form > div {
      width: 80%;
    }
    & > form > input {
      width: 80%;
    }
    & > form > button {
      margin-bottom: 20px;
    }
  }
`;
export const ThumbsUpIconStyle = {
  width: "150px",
  margin: "40px",
};
export const MailIconStyle = {
  width: "130px",
  margin: "5px",
};
export const eyeStyle = {
  position: "absolute",
  top: "22px",
  right: "15px",
  cursor: "pointer",
};
