import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  SingleItemInfo,
  BrandImg,
  MainSettingsWrapper,
  styleForActiveDark,
  styleForActiveLigth,
  styleForDisactiveLight,
  styleForDisactiveDark,
  downPath,
  pVariants,
  animationInputVariants,
  InputModalItems,
} from "./UserSettings.style";
import { Theme } from "../../store/theme/theme";
import { motion, AnimatePresence } from "framer-motion";
import imageRegular from "../../assets/media/svg/image-regular.svg";
import {
  fetchForChangeUserName,
  fetchForChangeUserAvatar,
} from "./UserSettings.controller";
import { userSignInService } from "../../store/user/duck/actions/actions";
import { changeDataAnalysPeriod } from "../../store/select/duck/actions/actions";
const regNameMultiLng =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
const regAvatarMultiLng =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð 0123456789,.=/?%&:'-]+$/u;

const UserSettings = ({
  user,
  theme,
  select,
  userSignInService,
  changeDataAnalysPeriod,
}) => {
  const [optionsActive, setOptionsActive] = useState(false);
  const [optionAvatarActive, setOptionsAvatarActive] = useState(false);
  const [optionPeriodActive, setOptionsPeriodActive] = useState(false);
  const [nameError, setNameError] = useState("");
  const [avatarError, setAvatarError] = useState("");
  const [nameValue, setNameValue] = useState("");
  const [avatarValue, setAvatarValue] = useState("");
  const [validName, setValidName] = useState(false);
  const [validAvatar, setValidAvatar] = useState(false);
  const [saveBtnVisible, setSaveBtnVisible] = useState(false);
  const [saveBtnAvatarVisible, setSaveBtnAvatarVisible] = useState(false);
  const nameValidationHandle = (value) => {
    setNameValue(value);
    if (value.length >= 3 && value.length < 33) {
      setNameError("");
      setSaveBtnVisible(true);
    }
    if (!value) {
      setNameError("Wpisz proszę swoje Imię");
    }
    if (value && value.length < 3) {
      setNameError("Wpisane imie jest zbyt krótkie");
    }
    if (value.length > 33) {
      setNameError("Wpisane imie jest zbyt długie");
    }
    if (!regNameMultiLng.test(value)) {
      setNameError("Wpisz poprawnie swoje imie");
    }
    if (
      regNameMultiLng.test(value) &&
      value.length >= 3 &&
      value.length <= 33
    ) {
      setNameError("");
      setValidName(true);
    }
  };
  const avatarValidationHandle = (value) => {
    setAvatarValue(value);
    if (value.length >= 3 && value.length < 233) {
      setAvatarError("");
      setSaveBtnAvatarVisible(true);
    }
    if (!value) {
      setAvatarError("Wpisz link avatara");
    }
    if (value && value.length < 3) {
      setAvatarError("Wpisany link jest zbyt krótki");
    }
    if (value.length > 233) {
      setAvatarError("Wpisane link jest zbyt długi");
    }
    if (!regNameMultiLng.test(value)) {
      setAvatarError("Wpisz poprawnie link avatara");
    }
    if (
      regAvatarMultiLng.test(value) &&
      value.length >= 3 &&
      value.length <= 233
    ) {
      setAvatarError("");
      setValidAvatar(true);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!nameValue) {
      setNameError("Wpisz proszę swoje Imię");
    }

    if (validName) {
      fetchForChangeUserName(
        user,
        nameValue,
        setSaveBtnVisible,
        setOptionsActive,
        userSignInService
      );
      // fetch
    }
  };
  const handleSubmitAvatar = async (e) => {
    e.preventDefault();

    if (!avatarValue) {
      setAvatarError("Ustaw link do swojego avatara");
    }

    if (validAvatar) {
      fetchForChangeUserAvatar(
        user,
        nameValue,
        avatarValue,
        setSaveBtnAvatarVisible,
        setOptionsAvatarActive,
        userSignInService
      );
      // fetch
    }
  };
  useEffect(() => {
    user?.name && setNameValue(user?.name);
    user?.avatar && setAvatarValue(user?.avatar);
  }, [user?.name, user?.avatar]);
  return (
    <MainSettingsWrapper>
      <SingleItemInfo isDark={theme?.isDark}>
        <div>
          <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
            style={
              optionsActive
                ? theme?.isDark
                  ? styleForActiveDark
                  : styleForActiveLigth
                : !theme?.isDark
                ? styleForDisactiveLight
                : styleForDisactiveDark
            }
            onClick={() => setOptionsActive(!optionsActive)}
          >
            <path fill="currentColor" d={downPath}></path>
          </svg>
          <p>Imię:</p>
          <p>{nameValue || "Brak danych"}</p>
          {optionsActive && (
            <>
              <form onSubmit={handleSubmit}>
                <InputModalItems
                  isDark={theme?.isDark}
                  variants={animationInputVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                >
                  <input
                    type="text"
                    placeholder="twoje imie"
                    name="name"
                    value={nameValue}
                    onChange={(e) => nameValidationHandle(e.target.value)}
                  />
                </InputModalItems>
                {!nameError && nameValue && saveBtnVisible && (
                  <motion.button whileTap={{ scale: 0.95 }} type="submit">
                    Zapisz
                  </motion.button>
                )}
              </form>
              <AnimatePresence>
                {nameError && (
                  <motion.p
                    variants={pVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    style={{
                      backgroundColor: `${Theme.light.fourthColor.red}`,
                      padding: "5px",
                    }}
                  >
                    {nameError}
                  </motion.p>
                )}
              </AnimatePresence>
            </>
          )}
        </div>
        <div style={{ position: "relative" }}>
          <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
            style={
              optionAvatarActive
                ? theme?.isDark
                  ? styleForActiveDark
                  : styleForActiveLigth
                : !theme?.isDark
                ? styleForDisactiveLight
                : styleForDisactiveDark
            }
            onClick={() => setOptionsAvatarActive(!optionAvatarActive)}
          >
            <path fill="currentColor" d={downPath}></path>
          </svg>
          <p>Avatar:</p>
          <p>
            {avatarValue ? `${avatarValue.slice(0, 35)}...` : "Brak danych"}
          </p>
          {optionAvatarActive && (
            <>
              <form onSubmit={handleSubmitAvatar}>
                <InputModalItems
                  isDark={theme?.isDark}
                  variants={animationInputVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                >
                  <input
                    type="text"
                    placeholder="twój avatar"
                    name="avatar"
                    value={avatarValue}
                    onChange={(e) => avatarValidationHandle(e.target.value)}
                  />
                </InputModalItems>
                {!nameError && avatarValue && saveBtnAvatarVisible && (
                  <motion.button whileTap={{ scale: 0.95 }} type="submit">
                    Zapisz
                  </motion.button>
                )}
              </form>
              <AnimatePresence>
                {avatarError && (
                  <motion.p
                    variants={pVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    style={{
                      backgroundColor: `${Theme.light.fourthColor.red}`,
                      padding: "5px",
                    }}
                  >
                    {avatarError}
                  </motion.p>
                )}
              </AnimatePresence>
            </>
          )}
        </div>
        <div>
          <p>Email:</p>
          <p>{user?.email}</p>
        </div>
        <div>
          <p>Reprezentujesz:</p>
          <p style={{ textTransform: "uppercase" }}>{user?.represents}</p>
        </div>
        <div style={{ position: "relative" }}>
          <svg
            aria-hidden="true"
            focusable="false"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
            style={
              optionPeriodActive
                ? theme?.isDark
                  ? styleForActiveDark
                  : styleForActiveLigth
                : !theme?.isDark
                ? styleForDisactiveLight
                : styleForDisactiveDark
            }
            onClick={() => setOptionsPeriodActive(!optionPeriodActive)}
          >
            <path fill="currentColor" d={downPath}></path>
          </svg>
          <p>Domyślny okres analizy:</p>
          <p>{`${select?.dataAnalysisPeriod} dni` || "Brak danych"}</p>
          {optionPeriodActive && (
            <>
              <form>
                <InputModalItems
                  isDark={theme?.isDark}
                  variants={animationInputVariants}
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                >
                  <input
                    type="text"
                    placeholder="twoje imie"
                    name="name"
                    value={select?.dataAnalysisPeriod}
                    onChange={(e) => changeDataAnalysPeriod(e.target.value)}
                  />
                </InputModalItems>
              </form>
            </>
          )}
        </div>
      </SingleItemInfo>
      <BrandImg>
        <img
          src={
            user?.represents !== "cenniczek"
              ? `/static/assets/media/brands/${user?.represents
                  ?.split(" ")
                  ?.join("-")}.png`
              : imageRegular
          }
        />
      </BrandImg>
    </MainSettingsWrapper>
  );
};
const mapDispatchToProps = (dispatch) => ({
  userSignInService: (item) => dispatch(userSignInService(item)),
  changeDataAnalysPeriod: (item) => dispatch(changeDataAnalysPeriod(item)),
});
const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.user,
  select: state.select,
});
export default connect(mapStateToProps, mapDispatchToProps)(UserSettings);
