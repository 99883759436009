import { motion, AnimatePresence } from "framer-motion";
import { Theme } from "../../store/theme/theme";
import styled from "styled-components";
export const LoginWrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${Theme.dark.eightColor.dark};
  color: ${Theme.light.primaryColor.white};
  & > img {
    width: 40vh;
  }
  & > p {
    font-size: 30px;
  }
`;
export const FormWrapper = styled.div`
  width: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  user-select: none;
  & > img {
    width: 40vh;
  }
  & > p {
    font-size: 30px;
  }

  & > h5 {
    width: 70%;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 500;
    user-select: none;
  }
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
  }

  & > div > p {
    margin: 15px;
    font-size: 1.5rem;
    user-select: none;
  }
  & > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  & > form > div {
    width: 60%;
    height: 60px;
    margin: 20px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > form > div > a {
    display: block;
    font-size: 12px;
    color: ${Theme.light.primaryColor.white};
    text-decoration: none;
    margin: 30px;
    transform: scale(1);
    transition: 0.3s;
  }
  & > form > input {
    width: 60%;
    height: 60px;
    margin: 20px;
    border: none;
    border-radius: 5px;
    text-align: center;
    transition: 0.1s;
    transform: scale(1);
    background: ${Theme.dark.sixColor.dark} !important;
    color: ${Theme.light.fiveColor.white};
  }
  & > form > div > input {
    width: 100%;
    height: 60px;
    border: none;
    border-radius: 5px;
    text-align: center;
    transition: 0.1s;
    transform: scale(1);
    background: ${Theme.dark.sixColor.dark} !important;
    color: ${Theme.light.fiveColor.white};
  }
  & > form > div > a:hover {
    transform: scale(1.1);
    transition: 0.3s;
  }
  & > form > input:hover,
  & > form > div > input:hover {
    transform: scale(1.01);
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
  }
  & > form > input::placeholder,
  & > form > div > input::placeholder {
    text-align: center;
    text-transform: uppercase;
    opacity: 1;
    transition: 0.3s;
  }
  & > form > input:autofill,
  & > form > div > input:autofill {
    background: ${({ isDark }) =>
      isDark ? Theme.dark.primaryColor.dark : Theme.light.primaryColor.white};
  }
  & > form > input:focus::placeholder,
  & > form > div > input:focus::placeholder {
    opacity: 0;
  }
  & > form > button {
    width: 200px;
    height: 60px;
    margin: 10px;
    border-radius: 5px;
    text-transform: uppercase;
    background-color: ${Theme.light.secondaryColor.green};
    border: 1px solid black;
    color: ${({ isDark }) =>
      isDark ? Theme.dark.thirdColor.white : Theme.dark.thirdColor.white};
    transition: 0.3s;
    transform: scale(1);
    user-select: none;
    cursor: pointer;
  }
  & > form > button:hover {
    box-shadow: 1px 1px 15px ${Theme.light.thirdColor.dark};
    transform: scale(1.1);
  }
  @media${Theme.device.mobileL} {
    width: 95%;
    margin-bottom: 15px;
    // min-height: 60vh;
    & > img {
      width: 35vh;
    }
    & > p {
      font-size: 20px;
    }
    & > h5 {
      font-size: 22px;
      margin-top: 20px;
    }
    & > form > div {
      width: 80%;
    }
    & > form > input {
      width: 80%;
    }
    & > form > div > a {
      margin: 10px;
    }
    & > form > button {
      margin-bottom: 20px;
    }
  }
`;
export const eyeStyle = {
  position: "absolute",
  top: "22px",
  right: "15px",
  cursor: "pointer",
};
export const divVariants = {
  hidden: {
    opacity: 0,
    scale: 1.2,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
  },
};
export const pVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};
export const ThumbsUpIconStyle = {
  width: "150px",
  margin: "40px",
};
