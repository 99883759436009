import React from "react";
import { Provider } from "react-redux";
import { storeForClient } from "./store/storeForClient";
import { Switch, Route, Router } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import StudioPage from "./pages/StudioPage";
import SignUpPage from "./pages/SignUpPage";
import SignUpConfirmPage from "./pages/SingUpConfirmPage";
import SignUpOverLimitPage from "./pages/SingnUpOverLimitPage";
import ForgottenPasswordPage from "./pages/ForgottenPasswordPage";
import history from "./utils/history";
import SingUpConfirmPage from "./pages/SingUpConfirmPage";
import NotFound from "./components/notFound/NotFound";
// import { createBrowserHistory } from "history";
// const history = createBrowserHistory({ forceRefresh: true });
const App = () => {
  return (
    <>
      <Provider store={storeForClient}>
        <Router history={history}>
          <Switch>
            <Route path="/" exact>
              <LoginPage />
            </Route>
            <Route path="/login" exact>
              <LoginPage />
            </Route>
            <Route path="/sign-in" exact>
              <LoginPage />
            </Route>
            <Route path="/sign-up" exact>
              <SignUpPage />
            </Route>
            <Route path="/sign-up/confirm" exact>
              <SignUpConfirmPage />
            </Route>
            <Route path="/sign-up/over-time-limit" exact>
              <SignUpOverLimitPage />
            </Route>
            <Route path="/sing-up/account-confirm-earlier" exact>
              <SingUpConfirmPage earlierConfirm={true} />
            </Route>
            <Route path="/password-reset" exact>
              <ForgottenPasswordPage />
            </Route>
            <Route path="/studio">
              <StudioPage />
            </Route>
            <Route path="/">
              <NotFound />
            </Route>
          </Switch>
        </Router>
      </Provider>
    </>
  );
};

export default App;
