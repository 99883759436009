import { USER_SIGN_IN, USER_SIGN_OUT } from "../types/type";

const initialState = {
  userLoged: false,
  email: "",
  name: "",
  represents: "",
  interests: "",
  avatar: "",
};

export const userReducer = (state = initialState, actions) => {
  switch (actions.type) {
    case USER_SIGN_IN: {
      return {
        ...state,
        userLoged: true,
        email: actions.item.email,
        name: actions.item.name,
        represents: actions.item.represents,
        interests: actions.item.interests,
        avatar: actions.item.avatar,
      };
    }
    case USER_SIGN_OUT: {
      return {
        ...state,
        userLoged: false,
        email: "",
        name: "",
        represents: "",
        interests: "",
        avatar: "",
      };
    }
    default:
      return { ...state };
  }
};
