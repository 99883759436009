import React, { useState, useEffect, useRef } from "react";
// import { useQuery } from "@apollo/client";
// import { getProductKeysCategoriesQuery } from "../../../../../../queries/queries";
import { motion } from "framer-motion";
import { Spinner } from "../../../tools/spinner/Spinner";
import { animationInputVariants } from "../style";

// const useCategoryForModal = () => {
//   return useQuery(getProductKeysCategoriesQuery, { ssr: true });
// };

const SelectContentForCategory = ({
  item,
  setCategoryOptionsActive,
  setIsCategoryImg,
  setTopDealsItemToUpdate,
  chosenItem,
  setTopDealsItem,
}) => {
  const optionName = item;
  const [refToLi, setRefToLi] = useState(null);
  const litItem = useRef(null);

  if (refToLi) {
    refToLi.addEventListener("click", () => {
      setCategoryOptionsActive(false);
      setIsCategoryImg(item?.imgUrl);
      const updated = {
        ...chosenItem,
        categoryProductId: item?.categoryProductId,
        categoryKeyProductName: item?.name,
        similarityRating: 0.99,
      };
      setTopDealsItem(updated);
    });
  }

  return (
    <motion.li
      key={item?.id}
      variants={animationInputVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      ref={(current) => {
        litItem.current = current;
        setRefToLi(current);
      }}
    >
      {item.name}
    </motion.li>
  );
};

export const SelectCategory = ({
  setCategoryOptionsActive,
  data,
  setIsCategoryImg,
  chosenItem,
  setTopDealsItemToUpdate,
  setTopDealsItem,
}) => {
  // let pureData = useCategoryForModal();

  if (data) {
    const sortedData = data?.sort((a, b) =>
      a.name < b.name ? -1 : a.name > b.name ? 1 : 0
    );

    const createSelectOptions = sortedData.map((item) => (
      <SelectContentForCategory
        key={item?._id}
        item={item}
        setCategoryOptionsActive={setCategoryOptionsActive}
        setIsCategoryImg={setIsCategoryImg}
        setTopDealsItemToUpdate={setTopDealsItemToUpdate}
        chosenItem={chosenItem}
        setTopDealsItem={setTopDealsItem}
      />
    ));
    return <ul>{createSelectOptions}</ul>;
  } else return <Spinner />;
};
