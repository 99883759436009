import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement,
  defaults,
} from "chart.js";
import { PolarArea } from "react-chartjs-2";
import { Theme } from "../../../store/theme/theme";
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
ChartJS.register(
  CategoryScale,
  LinearScale,
  RadialLinearScale,
  ArcElement,
  PointElement,
  LineElement,
  Filler,
  Title,
  Tooltip,
  Legend
);
const squareVariants = {
  visible: { opacity: 1, scale: 1, transition: { duration: 1.6 } },
  hidden: { opacity: 0, scale: 0.7 },
};
const WraperForMainCategory = styled(motion.div)`
  width: 49%;
  // min-height: 660px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  border-radius: 5px;
  position: relative;
  box-shadow: 0px 7px 29px 0px
    ${({ isDark }) =>
      isDark ? Theme.dark.eightColor.dark : Theme.light.eightColor.ligthGray};
  @media${Theme.device.tablet} {
    min-height: 200px;
    margin: 0;
  }
  @media${Theme.device.mobileL} {
    width: 100%;
    min-height: 185px;
    margin: 5px 0;
  }
`;
export const TimeChart = ({ days, isDark, isMobileL }) => {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const [showBars, setShowBars] = useState(false);

  const staticLabels = [
    "00:00-06:00",
    "06:00-10:00",
    "10:00-14:00",
    "14:00-18:00",
    "18:00-22:00",
    "22:00-24:00",
  ];

  useEffect(() => {
    if (inView) {
      controls.start("visible");
      isMobileL
        ? setTimeout(() => {
            setShowBars(true);
          }, 700)
        : setShowBars(true);
    }
  }, [controls, inView]);
  const options = {
    responsive: true,
    radius: 5,
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Godziny planowania zakupów",
        font: "normal",
      },
      fill: {
        propagate: true,
      },
      tooltip: {
        callbacks: {
          title: function (tooltipItem, data) {
            return tooltipItem[0].label;
          },
          label: function (tooltipItem, data) {
            const index = tooltipItem.dataIndex;
            const dataFromAll = tooltipItem.dataset.data.map((item) =>
              item ? parseFloat(item) : 0
            );
            const total = dataFromAll.reduce((all, item) => all + item, 0);

            const percentage = parseFloat(
              ((tooltipItem.dataset.data[index] / total) * 100).toFixed(1)
            );

            return `${parseFloat(tooltipItem.dataset.data[index]).toFixed(
              2
            )} PLN - ${percentage}% `;
          },
        },
      },
    },
    datasetFill: false,
    scales: {
      // y: {
      //   ticks: {
      //     callback: function (value) {
      //       return value + " PLN";
      //     },
      //   },
      // },
    },
  };

  const shopingDays = days.time.shoping.filter((item) => item.result);

  const helperForTime = [];

  shopingDays.map((list) => {
    const [day, time] = list?.result?.created.split(", ");
    const [hour, minute, second] = time.split(":");
    staticLabels.forEach((rangeHour) => {
      const [startHour, stopHour] = rangeHour.split("-");
      const [firstNrOfStartHour] = startHour.split(":");
      const [firstNrOfStopHour] = stopHour.split(":");
      if (hour >= firstNrOfStartHour && hour < firstNrOfStopHour) {
        helperForTime.push({
          rangeHour,
          value: list.result.list
            .map((item) => {
              return parseFloat(
                (
                  (parseFloat(item.price) || 0) *
                  (parseFloat(item.howMany) || 0)
                ).toFixed(2)
              );
            })
            .reduce((total, item) => total + item, 0)
            .toFixed(2),
        });
      }
    });
  });

  const data = staticLabels
    .map((rangeH) => {
      let rangeValue = 0;
      helperForTime.forEach((item) => {
        if (item.rangeHour === rangeH) {
          rangeValue = rangeValue + Number(item.value);
        }
      });
      return rangeValue;
    })
    .map((item) => String(item));

  const dataChart = {
    labels: staticLabels,
    datasets: [
      {
        label: `Godziny tworzenia list z ${days.number} dni`,
        fill: true,
        data: data,
        // backgroundColor: "rgba(244, 144, 128, 0.8)",
        backgroundColor: [
          "rgba(255,0,0, 1)",
          "rgba(0,0,255, 1)",
          "rgba(0,255,255, 1)",
          "rgba(255,0,255, 1)",
          "rgba(128,0,128, 1)",
          "rgba(255,255,0, 1)",
          "rgba(0,128,0, 1)",
          "rgba(0,255,0, 1)",
          "rgba(255, 99, 132, 0.8)",
          "rgba(54, 162, 235, 0.8)",
          "rgba(255, 206, 86, 0.8)",
          "rgba(75, 192, 192, 0.8)",
          "rgba(153, 102, 255, 0.8)",
          "rgba(255, 159, 64, 0.8)",
          "rgba(75, 192, 192,0.8)",
          "rgba(255, 99, 132,0.8)",
          "rgba(153, 102, 255,0.8)",
          "rgba(54, 162, 235,0.8)",
          "rgba(255, 206, 86,0.8)",
          "rgba(255, 12, 214,0.8)",
          "rgba(54, 62, 135,0.8)",
          "rgba(25, 16, 86,0.8)",
          "rgba(215, 59, 124,0.8)",
        ].reverse(),
        borderColor: [
          "rgba(255,0,0, 1)",
          "rgba(0,0,255, 1)",
          "rgba(0,255,255, 1)",
          "rgba(255,0,255, 1)",
          "rgba(128,0,128, 1)",
          "rgba(255,255,0, 1)",
          "rgba(0,128,0, 1)",
          "rgba(0,255,0, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(255, 12, 214, 1)",
          "rgba(54, 62, 135, 1)",
          "rgba(25, 16, 86, 1)",
          "rgba(215, 59, 124, 1)",
        ].reverse(),
        borderWidth: 1,
      },
    ],
  };

  defaults.fontFamily = ["Montserrat", "Helvetica Neue"];
  defaults.color = isDark
    ? Theme.dark.thirdColor.white
    : Theme.light.thirdColor.dark;
  defaults.borderColor = isDark
    ? Theme.light.fiveColor.white
    : Theme.dark.sixColor.dark;

  return (
    <WraperForMainCategory
      ref={ref}
      animate={isMobileL && controls}
      initial={isMobileL ? "hidden" : ""}
      variants={isMobileL && squareVariants}
      isDark={isDark}
    >
      {showBars && isMobileL ? (
        <PolarArea
          id={"categories_bar"}
          data={dataChart}
          options={options}
          style={{
            background: `${
              isDark
                ? Theme.dark.primaryColor.dark
                : Theme.light.primaryColor.white
            }`,
            padding: "15px",
            borderRadius: "5px",
            // marginTop: "25px",
          }}
        />
      ) : isMobileL === false ? (
        <PolarArea
          id={"categories_bar"}
          data={dataChart}
          options={options}
          style={{
            background: `${
              isDark
                ? Theme.dark.primaryColor.dark
                : Theme.light.primaryColor.white
            }`,
            padding: "15px",
            borderRadius: "5px",
            // marginTop: "25px",
          }}
        />
      ) : null}
    </WraperForMainCategory>
  );
};
