import { Theme } from "../../store/theme/theme";
import styled from "styled-components";
export const HeaderWrapper = styled.div`
  width: 100%;
  height: 80px;
  padding-left: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  & > p {
    font-size: 24px;
  }
  & > img {
    width: 30px;
    height: 30px;
  }
  @media${Theme.device.mobileL} {
    padding-left: 30px;
    & > p {
      font-size: 18px;
    }
  }
`;

export const MenuBarWrapper = styled.div`
  display: none;
  @media${Theme.device.mobileL} {
    display: ${({ isMenuOpen }) => (isMenuOpen ? "none" : "block")};
    position: absolute;
    top: 25px;
    right: ${({ isMenuOpen }) => (isMenuOpen ? "" : "10px")};
    cursor: pointer;
  }
`;
export const LogoWraper = styled.div`
  display: none;
  @media${Theme.device.mobileL} {
    display: block;
    width: 30px;
    height: 30px;
    // padding-left: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 10px;
    & > img {
      width: 35px;
      height: 35px;
    }
  }
`;
export const bellStyle = {
  position: "absolute",
  right: "52px",
  top: "50%",
  transform: "translateY(-50%)",
  cursor: "pointer",
};
