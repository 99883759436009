import { useEffect, useRef, useState } from "react";
import { ActionWrapper, FormWrapper, pVariants } from "./Contact.style";
import { motion, AnimatePresence } from "framer-motion";
import { Theme } from "../../store/theme/theme";
import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/default.css";
import { API_URL } from "../../constant/constant";
import {
  encryptFrontData,
  decryptFrontData,
} from "../../utils/Crypto/cryptoFront.controller";
import { Spinner } from "../../tools/spinner/Spinner";

const regNameMultiLng =
  /^[a-zA-ZąĄśŚćĆżźŹ1234567890àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
const regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const Contact = ({ isDark, user }) => {
  const formRef = useRef();

  const [errorFromCrypto, setErrorFromCrypto] = useState("");
  // errors
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [msgError, setMsgError] = useState("");

  // values
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");

  //
  const [procesing, setProcesing] = useState(false);
  const clear = () => {
    setName("");
    setEmail("");
    setMsg("");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name) setNameError("wpisz Imię");
    if (!email) setEmailError("wpisz swój email");
    if (!msg) setMsgError("wpisz wiadomość");

    if (!nameError && !emailError && !msgError && name && email && msg) {
      setProcesing(true);

      try {
        const frontDataToEncrypt = {
          name: name,
          email: email,
          msg: msg,
        };
        // encrypt data for fetch
        const ciphertext = encryptFrontData(
          frontDataToEncrypt,
          setErrorFromCrypto,
          null,
          "Contact.js-studio"
        );
        await fetch(`${API_URL}/api-studio/contact`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({
            data: ciphertext,
          }),
        })
          .then((response) => response.json())
          .then((result) => {
            return decryptFrontData(
              result.data,
              setErrorFromCrypto,
              null,
              "Contact.js-studio"
            );
          })
          .then((data) => {
            setProcesing(false);
            if (data?.ok) {
              alertify.set("notifier", "position", "bottom-left");
              alertify.success(
                `Wiadomość przesłana pomyślnie-odpowiemy tak szybko jak to tylko możliwe`
              );
              clear();
            } else {
              alertify.set("notifier", "position", "top-center");
              alertify.alert(data.error);
              clear();
            }
          });

        // Handle response from the server as needed
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    } else {
      console.log("SOMETHING is SHEET");
      console.log({
        nameError,
        emailError,
        msgError,
        name,
        email,
        msg,
      });
    }
  };

  const handleName = (value) => {
    setName(value);
    if (!value) {
      setNameError("Wpisz proszę swoję Imie");
    }
    if (value && value.length < 3) {
      setNameError("Wpisane Imie jest zbyt krótkie");
    }
    if (value.length > 33) {
      setNameError("Wpisane Imie jest zbyt długie");
    }
    if (!regNameMultiLng.test(value)) {
      setNameError("Wpisz poprawnie Imie [a-z]");
    }
    if (
      regNameMultiLng.test(value) &&
      value.length >= 3 &&
      value.length <= 33
    ) {
      setNameError("");
    }
  };
  const handleEmail = (value) => {
    setEmail(value);
    if (value.length >= 3 && value.length < 33) {
      setEmailError("");
    }
    if (!value) {
      setEmailError("Wpisz proszę swój email");
    }
    if (value && value.length < 3) {
      setEmailError("Wpisana emial jest zbyt krótki");
    }
    if (value.length > 33) {
      setEmailError("Wpisana emial jest zbyt długi");
    }
    if (value?.length > 6 && !regEmail.test(value)) {
      setEmailError("Wpisz poprawnie email");
    }
    if (regEmail.test(value) && value.length >= 3 && value.length <= 33) {
      setEmailError("");
    }
  };
  const handleMsg = (value) => {
    setMsg(value);
    if (!value) {
      setMsgError("Wpisz proszę swoję Imie");
    }
    if (value && value.length < 3) {
      setMsgError("Wpisane wiadomość jest zbyt krótkia");
    }
    if (value.length > 500) {
      setMsgError("Wpisana wiadomość jest zbyt długa");
    }
    if (!regNameMultiLng.test(value)) {
      setMsgError("Wpisz poprawnie wiadomość [a-z]");
    }
    if (
      regNameMultiLng.test(value) &&
      value.length >= 3 &&
      value.length <= 33
    ) {
      setMsgError("");
    }
  };

  useEffect(() => {
    return () => {
      clear();
    };
  }, []);
  return (
    <FormWrapper isDark={isDark}>
      <form onSubmit={(e) => handleSubmit(e)} ref={formRef}>
        <div>
          <p>Wpisz Imię</p>

          <input
            type="text"
            name="name"
            placeholder="imię"
            value={name}
            onChange={(e) => handleName(e.target.value)}
          />
          <AnimatePresence>
            {nameError && (
              <motion.p
                variants={pVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{
                  backgroundColor: `${Theme.light.fourthColor.red}`,
                  padding: "5px",
                }}
              >
                {nameError}
              </motion.p>
            )}
          </AnimatePresence>
        </div>
        <div>
          <p>Wpisz swój email</p>
          <input
            type="text"
            name="eamil"
            placeholder="email"
            value={email}
            onChange={(e) => handleEmail(e.target.value)}
          />
          <AnimatePresence>
            {emailError && (
              <motion.p
                variants={pVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{
                  backgroundColor: `${Theme.light.fourthColor.red}`,
                  padding: "5px",
                }}
              >
                {emailError}
              </motion.p>
            )}
          </AnimatePresence>
        </div>
        <div>
          <p>Wiadomość</p>
          <textarea
            placeholder="treść wiadomości"
            value={msg}
            onChange={(e) => handleMsg(e.target.value)}
          ></textarea>

          <AnimatePresence>
            {msgError && (
              <motion.p
                variants={pVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
                style={{
                  backgroundColor: `${Theme.light.fourthColor.red}`,
                  padding: "5px",
                }}
              >
                {msgError}
              </motion.p>
            )}
          </AnimatePresence>
        </div>

        {procesing ? (
          <ActionWrapper>
            <Spinner />
          </ActionWrapper>
        ) : (
          <button type="submit">Wyślij</button>
        )}
      </form>
    </FormWrapper>
  );
};
export default Contact;
