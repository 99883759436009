import { Theme } from "../../../store/theme/theme";
import styled from "styled-components";

export const ColumnWrapper = styled.div`
  width: 400px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: ${Theme.dark.eightColor.dark};
  color: ${Theme.dark.thirdColor.white};
  transition: 0.3s all;
  &::-webkit-scrollbar {
    width: 12px;
    border-radius: 0 5px 5px 0;
    background-color: ${({ isDark }) =>
      !isDark ? Theme.dark.eightColor.dark : Theme.light.thirdColor.dark};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border-left: 3px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.eightColor.dark : Theme.light.thirdColor.dark};
    border-right: 3px solid
      ${({ isDark }) =>
        !isDark ? Theme.dark.eightColor.dark : Theme.light.thirdColor.dark};
    background-color: ${({ isDark }) =>
      isDark ? Theme.dark.fiveColor.white : Theme.light.thirdColor.dark};
  }
  @media${Theme.device.desktopM} {
    width: 25vw;
    overflow: scroll;
  }
  @media${Theme.device.mobileL} {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: ${({ isMenuOpen }) => (isMenuOpen ? 0 : "-100vw")};
    z-index: ${({ isMenuOpen }) => (isMenuOpen ? "1002" : "1002")};
    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 0 2px 2px 0;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      box-shadow: inset 0 0 2px
        ${({ isDark }) =>
          !isDark ? Theme.dark.thirdColor.white : Theme.light.thirdColor.dark};
      border-right: none;
      border-left: none;
    }
  }
`;
export const OptionsWrapper = styled.div`
  width: 400px;
  height: 100vh;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transition: background 0.3s;
  border-top: 1px solid ${Theme.dark.fiveColor.white};
  & > a {
    display: block;
    width: 90%;
    text-decoration: none;
  }
  @media${Theme.device.desktopM} {
    width: 25vw;
  }
  @media${Theme.device.mobileL} {
    width: 100vw;
    height: auto;
    // min-height: 60vh;
    overflow-y: auto;
    padding-bottom: 30px;
    flex-grow: 1;
  }
`;
export const SingleOptionWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 60px;
  margin: 1px;
  padding-left: 40px;
  color: ${Theme.dark.thirdColor.white};
  background: ${({ isActive }) =>
    isActive ? Theme.dark.sixColor.dark : Theme.dark.eightColor.dark};
  cursor: pointer;
  transition: 0.3s;
  & > img {
    width: 30px;
    height: 30px;
  }
  & > p {
    margin-left: 30px;
  }
  &:hover {
    background: ${Theme.dark.sixColor.dark};
  }
  @media${Theme.device.desktopM} {
    padding-left: 20px;
  }
`;
export const SecondaryOptionsWrapper = styled.div`
  width: 400px;
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  bottom: 40px;
  transition: background 0.3s;
  border-top: 1px solid ${Theme.dark.fiveColor.white};
  background: ${Theme.dark.eightColor.dark};
  & > a {
    display: block;
    width: 90%;
    text-decoration: none;
  }
  @media${Theme.device.desktopM} {
    width: 25vw;
    bottom: 0;
  }
  @media${Theme.device.mobileL} {
    width: 100vw;
    padding-top: 5px;
    bottom: 45px;
    z-index: 1;
  }
`;

export const XMarkWrap = styled.div`
  display: none;
  @media${Theme.device.mobileL} {
    display: block;
    position: absolute;
    top: 25px;
    right: ${({ isMenuOpen }) => (isMenuOpen ? "10px" : "0px")};
  }
`;
