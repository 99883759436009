import { Theme } from "../../store/theme/theme";
import styled from "styled-components";

export const HeaderSearchWrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & > p {
    margin-bottom: 15px;
  }
`;
