import React, { useEffect, useState } from "react";
import { MainWrapper, MainWrapperLayer } from "./styleForUserRegisterSection";
import { BeforeValidatione } from "./BeforeValidatione";
import { AfterValidatione } from "./AfterValidatione";

const UserPasswordReset = () => {
  const [mainValidation, setMainValidation] = useState(false);
  const [body, setBody] = useState({ first_name: "", email: "", password: "" });
  useEffect(() => {
    return () => {
      setBody({ first_name: "", email: "", password: "" });
    };
  }, []);
  return (
    <>
      <MainWrapper>
        <MainWrapperLayer>
          {!mainValidation ? (
            <BeforeValidatione
              validate={setMainValidation}
              setBodyEmail={setBody}
            />
          ) : (
            <AfterValidatione
              mainValidation={mainValidation}
              body={body}
              changeAfterBadResponse={setMainValidation}
            />
          )}
        </MainWrapperLayer>
      </MainWrapper>
    </>
  );
};

export default UserPasswordReset;
