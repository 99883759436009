import React from "react";
import { LoginWrapper } from "./LoginPage.style";
import Login from "../components/login/Login";

export const LoginPage = () => {
  return (
    <LoginWrapper>
      <Login />
    </LoginWrapper>
  );
};

export default LoginPage;
