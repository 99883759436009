import styled from "styled-components";
import { Theme } from "../store/theme/theme";

export const LoginWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${Theme.dark.eightColor.dark};
  color: ${Theme.light.primaryColor.white};
`;
