import React, { useEffect } from "react";
import { connect } from "react-redux";
import controller from "../../helpers/usePermissionController";
import NewspapersCenniczek from "./Newspaper.Cenniczek";
import NewspapersContractor from "./Newspaper.Contractor";

const Newspapers = ({ user, theme, select }) => {
  useEffect(() => {
    controller(user);
  }, []);
  return (
    <>
      <p style={{ marginBottom: "20px" }}>Gazetki promocyjne</p>
      {user?.represents === "cenniczek" ? (
        <NewspapersCenniczek user={user} theme={theme} select={select} />
      ) : (
        <NewspapersContractor user={user} theme={theme} select={select} />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  theme: state.theme,
  user: state.user,
  select: state.select,
});
export default connect(mapStateToProps, {})(Newspapers);
