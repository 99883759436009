import alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";
import "alertifyjs/build/css/themes/default.css";

export const modalHelper = {
  successPublising: (socialName) => {
    alertify.set("notifier", "position", "bottom-left");
    alertify.success(`Publikacja postu na ${socialName} zakończona pomyślnie`);
  },
  errorPublishing: (socialName) => {
    alertify.set("notifier", "position", "bottom-left");
    alertify.error(
      `Przepraszamy podczas publikacji na ${socialName} coś poszło nie po naszej myśli`
    );
  },
};
